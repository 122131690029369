import {FETCH_ACTIVITIES_FINISH,CREATE_ACTIVITY_FINISH,REMOVE_ACTIVITY,SWITCH_ACCOUNT,MARK_CURRENT_LIST_AS_READ_FINISH} from '../actions/names';
// import {Activity} from '../../entities/types_reducer';
import {ACTIVITY_NAMES} from '../../common/enums';

// interface Activity{
//     id:number;
//     accountId:string;
//     payload:string;
//     activityName:string;
//     time:string;
// }

const initialState ={
    read:[],
    bookmarks:[],
    favs:[]
}

export default function reducer(state = initialState,action){
    if(action.type === SWITCH_ACCOUNT){
        return initialState;
    }

    //Handle action when activities are fetched from Database
    if(action.type === FETCH_ACTIVITIES_FINISH){
        let newState ={
            read:[],
            bookmarks:[],
            favs:[]
        };
        let activities = action.payload;
        for(let i=0;i<activities.length;i++){
            let act = activities[i];
            if(act.activityName ===ACTIVITY_NAMES.READ){
                newState.read.push(act);
            }
            if(act.activityName ===ACTIVITY_NAMES.BOOKMARK){
                newState.bookmarks.push(act);
            }
            if(act.activityName ===ACTIVITY_NAMES.FAV){
                newState.favs.push(act);
            }
        }
        return newState;
    }

    if(action.type === REMOVE_ACTIVITY){
        let newState ={
            read:state.read,
            bookmarks:state.bookmarks,
            favs:state.favs
        };
        let act = action.payload;
        if(act.activityName ===ACTIVITY_NAMES.READ){
            newState.read = state.read.filter((a)=>{
                return a.id !== act.id
            })
        }
        if(act.activityName ===ACTIVITY_NAMES.BOOKMARK){
            newState.bookmarks = state.bookmarks.filter((a)=>{
                return a.id !== act.id
            })
        }
        if(act.activityName ===ACTIVITY_NAMES.FAV){
            newState.favs = state.favs.filter((a)=>{
                return a.id !== act.id
            })
        } 
        return newState;
    }

    if(action.type === CREATE_ACTIVITY_FINISH){
        let act = action.payload;
        let newState ={
            read:state.read,
            bookmarks:state.bookmarks,
            favs:state.favs
        };

        if(act.activityName ===ACTIVITY_NAMES.READ){
            newState.read = newState.read.concat(act);
        }
        if(act.activityName ===ACTIVITY_NAMES.BOOKMARK){
            newState.bookmarks = newState.bookmarks.concat(act);
        }
        if(act.activityName ===ACTIVITY_NAMES.FAV){
            newState.favs = newState.favs.concat(act);
        } 
        return newState;
    }

    if(action.type === MARK_CURRENT_LIST_AS_READ_FINISH){
        let newState ={
            read:state.read,
            bookmarks:state.bookmarks,
            favs:state.favs
        };
        newState.read = newState.read.concat(action.payload);
        return newState;
    }

    return state;
}