import React from 'react'
import {css,StyleSheet} from 'aphrodite'
import COLORS from '../COLORS'

export const ProductView = ({product,purchaseProduct}) => {
    let price = product.price;
    return(
        <div className={css(styles.container)}>
            <div className={css(styles.title)}>
                {product.label}                 
            </div>
            <div className={css(styles.price)}>
                {price}/Life Time
            </div>
            <div>
                <div>
                    {product.features.map((f,i)=>{
                        return <div key={i} className={css(styles.row)}>
                            {i+1}. {f}
                        </div>
                    })}
                </div>                
            </div>
            <div>
                <button 
                    className={css(styles.button)}
                    onClick={()=>{purchaseProduct(product)}}>
                    {`Purchase for ${price}`}
                </button>
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    container:{
        width:300,
        backgroundColor:'#eee',
        padding:15,
        borderRadius:3,
        '@media (max-width: 650px)':{
            marginBottom:15,
            width:'80%'
        }
    },
    
    title:{
        fontSize:25,
        color:'#000',
        marginBottom:5,
        textAlign:'center'
    },
    price:{
        fontSize:15,
        fontWeight:300,
        textAlign:'center',
        marginBottom:20
    },
    row:{
        padding:7
    },
    button:{
        backgroundColor:COLORS.SUCCESS,
        padding: 10,
        color: '#fff',
        width: '100%',
        border: 'none',
        fontSize: '1em',
        marginTop: 20,
        borderRadius:3,
        ':hover':{
            cursour:'pointer'
        }
    }
});

export default ProductView