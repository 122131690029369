import React from 'react';
import {getFormattedTime,tCss} from '../utils'
import {getStoryThumbnail,isEnclosureAnImage} from './utils'
import {getGoogleIcon} from '../utils'
import {THEMES,LAYOUT} from '../../common/enums'
import { StyleSheet} from 'aphrodite'


const StoryTile = ({renderImage,story, selectStory, isSelected, subscription, isPopular,theme,isRead,layout}) => {
    let css2 = tCss(theme)(styles);
    if(!subscription){
        return null;
    }
    return <div
            onClick ={()=>selectStory(story)}
            className={css2(['rowContainer'],[{b:isSelected,t:'rowContainerSelected',f:''}])}>
                <div
                    style={{background: `#f6f7f9 url('${renderImage?getStoryThumbnail(story):""}') no-repeat center center`}}
                    className={css2(['left',`left_${layout}`],[{b:isRead,t:'image_seen',f:''}])}>
                </div>

                <div className={css2('right')}>
                    <div className={css2('title',[{b:isRead,t:'seen_text',f:''}])}>{story.title}</div>
                    <div className={css2('infoContainer')}>
                        <div className={css2('infoLeft')}>
                            <img src={subscription.iconUrl || getGoogleIcon(subscription.website) } className={css2('icon')} alt=''/>
                        </div>
                        <div className={css2('infoRight')}>
                            <div className={css2('infoTitle',[{b:isRead,t:'seen_text',f:''}])}>
                                <span>{subscription.title} | </span>
                                <span>{getFormattedTime(story.published)}</span>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
}

export default StoryTile;


const styles = StyleSheet.create({
    rowContainer: {
        display: 'flex',
        position: 'relative',
        cursor:'pointer',
        // margin:'5px 0px',
        marginBottom:5,
        border:'1px solid transparent',
    },
    [`rowContainerSelected_${THEMES.LIGHT}`]: {
        backgroundColor: `rgba(0,0,0,0.1)`,
        border:'1px solid rgba(0,0,0,0.2)'
    },
    [`rowContainerSelected_${THEMES.DARK}`]: {
        backgroundColor: `rgba(0,0,0,0.3)`,
        border:'1px solid rgba(0,0,0,0.2)'
    },
    left: {
        minHeight: 100,
        backgroundPosition: '50% 25%',
        'backgroundSize': 'cover',
    },
    [`left_${THEMES.DARK}`]:{
        backgroundColor:'#666'
    },
    [`left_${LAYOUT.ONE_PANE}`]:{
        width: 100,
    },
    [`left_${LAYOUT.TWO_PANE}`]:{
        width: 50,
    },
    [`left_${LAYOUT.THREE_PANE}`]:{
        width: 100,        
    },
    right: {
        flex: 1,
        paddingLeft: 10,
        display: 'flex',
        flexDirection: 'column',
        overflow:'hidden'
    },
    title: {
        flex: 1,
        fontWeight: 300,
        paddingTop:5,
    },
    image_seen:{
        filter: 'grayscale(100%)'
    },
    infoContainer: {
        display: 'flex'
    },
    infoLeft: {},
    infoRight: {
        paddingLeft: 10
    },
    icon: {
        width: 20,
        height: 20,
        filter: 'grayscale(100%)',
        borderRadius: 10
    },
    infoTitle: {
        fontWeight: 300,
        fontSize: '0.85em',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // width: 200,
        paddingBottom: 7,
    },
    [`infoTitle_${THEMES.LIGHT}`]:{color: '#555'},
    [`infoTitle_${THEMES.DARK}`]:{color: '#999'},
    [`seen_text_${THEMES.LIGHT}`]:{
        color:'#999'
    },
    [`seen_text_${THEMES.DARK}`]:{
        color:'#555'
    }
});