import React from 'react';
import Ionicon from 'react-ionicons';
import Popover from '../Popover';
import {STORIES_LIST_FILTER} from '../../common/enums';

const color = '#777';

const Row = ({css2,text,icon,onFilterClick,clickValue,value})=>{
    if(value === clickValue){
        return null;
    }
    return <div 
        className={css2('popoverRow')}
        onClick={()=>onFilterClick(clickValue)}>
            <Ionicon icon={icon} fontSize={'15px'} color={color}/>
            &nbsp;&nbsp;{text}
    </div>
}   

const FilterMenuPopOver = ({css2,sort,isOpen,onHide,position,textStrings,onFilterClick,theme,value} )=>{
    return <Popover
            isOpen={isOpen}
            onHide= {onHide}
            position={position}
            theme={theme}
            style={{height:''}}>
            
            <div className={css2('popoverContainer')}>
                    <Row css2={css2} onFilterClick={onFilterClick} value={value}
                        text={textStrings.ALL}
                        icon="ion-grid"
                        clickValue={STORIES_LIST_FILTER.ALL}
                    />
                    <Row css2={css2} onFilterClick={onFilterClick} value={value}
                        text={textStrings.UNREAD}
                        icon="ion-record"
                        clickValue={STORIES_LIST_FILTER.UNREAD}
                    />
                    <Row css2={css2} onFilterClick={onFilterClick} value={value}
                        text={textStrings.FAVOURITE}
                        icon="ion-android-favorite"
                        clickValue={STORIES_LIST_FILTER.FAVS}
                    />
                    <Row css2={css2} onFilterClick={onFilterClick} value={value}
                        text={textStrings.BOOKMARKS}
                        icon="ion-bookmark"
                        clickValue={STORIES_LIST_FILTER.BOOKMARKED}
                    />
            </div>
        </Popover>
}


export default FilterMenuPopOver;