import React from 'react'
import StoryList from '../StoryList';
import StoryLisToolbar from '../StoryListToolbar';

export const MiddlePanel = ({hidden,css2,width,containerCss,layout,style={}}) => {
    if(hidden){
        return null;
    }
    return(
        <div className={css2([containerCss,'panel'])} style={style}>
            <div className={css2(['content'])}>
                <StoryList width={width} layout={layout}/>
            </div>
            <div className={css2(['bar'])}>
                <StoryLisToolbar />
            </div>
        </div>
    )
}

export default MiddlePanel