import { createSelector } from 'reselect'

const getStories = (state:any) => state.stories
const getSelectedStoryId = (state:any) => state.account.selectedStoryId


export const getStoryIndex = createSelector(
    [ getStories, getSelectedStoryId ],
    (stories, selectedStoryId) => {
        if(stories && stories.length>0 && selectedStoryId){
            for(let i=0;i<stories.length;i++){
                if(stories[i].id === selectedStoryId){
                    return i;
                }
            }
        }
        return 0;
    }
  )