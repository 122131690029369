//This object is set during init.
let common ={
    CLIENT_ID : null,
    CLIENT_SECRET : null,
    REDIRECT_URL : null,
    BASE_URL : 'https://cloud.feedly.com/',
    AUTH_PATH : 'v3/auth/auth?',
    SCOPES : 'https://cloud.feedly.com/subscriptions',
    getUrlForPath: (path)=>{
        return common.BASE_URL + 'v3/' + path
    }
}

//this is used by feedlyLogin
export function getFormattedUrl(getState) {   
    let state = encodeURIComponent(getState());
    let {BASE_URL,AUTH_PATH,CLIENT_ID,REDIRECT_URL,SCOPES} = common;
    let formattedUrl =  BASE_URL + AUTH_PATH + "client_id=" + CLIENT_ID + "&redirect_uri=" + encodeURIComponent(REDIRECT_URL) + "&response_type=code&scope=" + SCOPES + "&state=" + state;
    return formattedUrl;
}



//Common interface for All Services START
export function getKey(){
    return "feedly";
}
export function init(config){
    common.CLIENT_ID = config.clientId;
    common.CLIENT_SECRET =  config.clientSecret;
    common.REDIRECT_URL  = config.redirectUrl;
}
//Common interface for All Services END

export function getStoriesForAccount(token, userId, count = 20,newerThan=111000) {
    let path = "streams/user" + encodeURIComponent("/" + userId + "/category/global.all") + "/contents?count=" + count + "&ranked=newest&newerThan="+ newerThan;
    let options = {
        url: common.getUrlForPath(path),   
        headers: {
            'Authorization': token.access_token,
            'Content-Type': 'application/json'
        },
        corsAllowed:false
    }
    return options
}

export function getSubscriptions(token) {
    let options = {
        url: common.getUrlForPath('subscriptions'), 
        headers: {
            'Authorization': token.access_token,
            'Content-Type': 'application/json'
        },
        corsAllowed:false
    }
    return options
}

/**
 * Accepts a text and return subscriptions
 * @param {*} q Text to be searched
 * @param {*} n 
 * @param {*} type 
 */
export function searchTextForSubscriptions(q, n = 20, type = "feeds") {
    // let url = getUrlForPath('search/' + type + '?') + "n=" + n + "&q=" + q;
    let path = `search/${type}?n=${n}&q=${q}`;
    let options ={
        url: common.getUrlForPath(path),
        corsAllowed:false
    }
    return options
}


export function updateSubscription(token,subscription){
    let options = {
        url:  common.getUrlForPath('subscriptions'),
        method:'post',
        data: JSON.stringify(subscription),
        headers: {
            'Authorization': token.access_token,
            'Content-Type': 'application/json'
        },
        corsAllowed:false
    }
    return options
}

export function getStories(subscriptionUrl, count=25,newerThan = 111000,type = "feed",) {
    let path = 'streams/' + type + encodeURIComponent('/' + subscriptionUrl) + '/contents?count=' + count + '&ranked=newest&newerThan=' + newerThan;
    let options =  {
        url: common.getUrlForPath(path),
        corsAllowed:false
    }
    return options
}

export function isTokenExpired(token) {
    return (((token.created_at || 0) + token.expires_in * 1000) < Date.now())
}

export function refreshToken(token){
    let options = {
        url: common.getUrlForPath('auth/token'), 
        method: 'post',
        data:{
            refresh_token:token.refresh_token,
            client_id:common.CLIENT_ID,
            client_secret:common.CLIENT_SECRET,
            grant_type:'refresh_token'
        },
        corsAllowed:false
    }
    return options
}

function _getMarkereOptions(token,actionName,ids,type='entries'){
    let data = {
        action:actionName,
        type:type,
        entryIds:ids
    }
    let options = {
        url:  common.getUrlForPath('markers'),
        method:'post',
        data: JSON.stringify(data),
        headers: {
            'Authorization': token.access_token,
            'Content-Type': 'application/json'
        },
        corsAllowed:false
    }
    return options;
}

export function markAsRead(token,ids){
    return _getMarkereOptions(token,'markAsRead',ids)
}

export function markAsUnread(token,ids){
    return _getMarkereOptions(token,'keepUnread',ids)
}

export function markAsSaved(token,ids){   
    return _getMarkereOptions(token,'markAsSaved',ids)
}

export function markAsUnSaved(token,ids){  
    return _getMarkereOptions(token,'markAsUnsaved',ids)
}

/**Used by Feedly Login */
export function __getProfile(token){
    let options = {
        url: common.getUrlForPath("profile"), 
        method: 'get',
        headers: {
            'Authorization': token.access_token,
            'Content-Type': 'application/json'
        },
        corsAllowed:false
    };
    return options
}

export function __getToken(authCode){
    let {CLIENT_ID,CLIENT_SECRET,REDIRECT_URL} = common;
    let options = {
        url: common.getUrlForPath('auth/token'), 
        method: 'post',
        corsAllowed:false,
        data: {
            code: authCode,
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            redirect_uri: REDIRECT_URL,
            grant_type: 'authorization_code'
        }
    };
    return options;
}

export function getRedirectUrl(){
    return common.REDIRECT_URL;
}

export {startLoginFlow} from './feedlyLogin'