import {FETCH_STORIES_FINISH,SWITCH_ACCOUNT} from '../actions/names';
const initialState =[];

export default function reducer(state = initialState,action){
    if(action.type === SWITCH_ACCOUNT){
        return initialState;
    }
    //Its storing StoriesDb, its not storiesDOC
    if(action.type === FETCH_STORIES_FINISH){
        return action.payload;
    }
    return state;
}