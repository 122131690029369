import React, { Component } from 'react';
import {StyleSheet,css} from 'aphrodite';
import Ionicon from 'react-ionicons';
import Video1 from './Video1'

const CloseButton = ({toggleVideo})=>{
    const color= "#fff";
    const fontSize = "30px";
    return <div onClick={toggleVideo} className={css(styles.closeButton)}>
        <Ionicon 
                color="#ffffff" 
                icon="ion-android-close" 
                fontSize={fontSize} />
    </div>
}


class Help extends Component {
    state={
        showVideo:false
    }
    toggleVideo = ()=>{
        this.setState({
            showVideo: !this.state.showVideo
        });
    }
    render() {
        let {textStrings,windowDimensions} = this.props;
        if(!this.state.showVideo){
            return (
                <div onClick={this.toggleVideo} style={{
                    color:'#eee',
                    position:'absolute',
                    bottom:30,
                    right:30,
                    cursor:'pointer'
                }}>
                    {textStrings.HELP}
                </div>
            );
        }
        return (<div className={css(styles.container)}>
                    <CloseButton toggleVideo={this.toggleVideo} />
                    <Video1 className={css(styles.videoContainer)} />
            </div>)
    }
}

const styles= StyleSheet.create({
    container:{
        position:'absolute',
        top:0,
        left:0,
        backgroundColor:'#171718',
        width:'100vw',
        'min-height':'100%',
        display:'flex',
        flexDirection:'column',
        zIndex:1000
    },
    closeButton:{
        padding:10,
        position:'absolute',
        top:0,
        right:0,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer',
    },
    videoContainer:{
        flex:1,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',        
    }
});


export default Help;