import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers/root';
import createSagaMiddleWare from 'redux-saga';
import rootSaga from './sagas/saga';

const sagaMiddleWare = createSagaMiddleWare();
const middlewares = [sagaMiddleWare];

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);


//Todo: Take care of Redux extension
//process.env.NODE_ENV === 'development'
//process.env.NODE_ENV === 'production'
const store = createStoreWithMiddleware(reducers,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

sagaMiddleWare.run(rootSaga);
export default store;