export const ACTIVITY_NAMES = {
    READ:'READ',
    FAV:'FAV',
    BOOKMARK:'BOOKMARK'
}

export const STORY_VIEW ={
    DEFAULT:'default',
    WEB:'web',
    CLEAN:'clean'
}

export const STORIES_LIST_FILTER = {
    ALL:'ALL',
    BOOKMARKED:'BOOKMARKED',
    FAVS:'FAVS',
    UNREAD:'UNREAD'
}

export const STORY_LIST_SORT_DIRECTION ={
    NEWEST_FIRST:'NEWEST_FIRST',
    OLDEST_FIRST:'OLDEST_FIRST',
    POPULAR_FIRST:'POPULAR_FIRST'
}

export const THEMES = {
    LIGHT:'LIGHT',
    DARK:'DARK'
}

export const TEMPLATES ={
    CARD:'CARD',
    CLASSIC:'CLASSIC',
    TILE:'TILE'
}
export const STATUS_TYPES ={
    PRIMARY:'PRIMARY',
    WARNING:'WARNING',
    SUCCESS:'SUCCESS',
    DANGER:'DANGER'
}

export const LAYOUT ={
    ONE_PANE:1,
    TWO_PANE:2,
    THREE_PANE:3
}

export const PRODUCTS_ENUM = {
    UNLIMITED:'Unlimited', //Value is from store
    ADDFREE:'Add Free'
}

export const NAMES = {
    UNCATEGORISED_FOLDER_ID:'__uncat__'
}

export const PROCESS_ENV= {
    PRODUCTION:'production',
    DEVELOPMENT:'development'
}

export const ARR_STORIES_LIST_FILTER =[STORIES_LIST_FILTER.ALL,STORIES_LIST_FILTER.BOOKMARKED,STORIES_LIST_FILTER.FAVS,STORIES_LIST_FILTER.UNREAD];