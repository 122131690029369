/**
 * This file exposes DB to application
 */

import init from './indexeddb/init';
import * as queries from './indexeddb/query';

const  DB = {
    init: init,
    ...queries
}
export default DB;