import React from 'react';
import {getStoryThumbnail} from './utils'
import {tCss,getFormattedTime} from '../utils'
import {StyleSheet} from 'aphrodite'
import {THEMES} from '../../common/enums'
import {getGoogleIcon} from '../utils'


const Card = ({renderImage,story, selectStory, isSelected, subscription, isPopular,theme,isRead})=>{
    let css2= tCss(theme)(styles);
    return <div className={css2('container',[{b:isSelected,t:'containerSelected',f:''}])} 
            onClick ={()=>selectStory(story)}
            style={{
                background: `#f6f7f9 url('${renderImage?getStoryThumbnail(story):""}') no-repeat center center`
            }}>
            <div className={css2('contentContainer')}>
                <div className={css2('title')}>{story.title}</div>
                {/*
                    <div dangerouslySetInnerHTML={{
                        __html:striptags(story.payload.summaryText)
                    }}></div>
                */}
                <div className={css2('bottom')}>
                    <img src={subscription.iconUrl || getGoogleIcon(subscription.website)} className={css2('icon')}/>
                    <span>&nbsp;</span>
                    <span>{subscription.title}</span>
                    <span>&nbsp;</span>
                    <span>{getFormattedTime(story.published)}</span>
                </div>
            </div>
    </div>
}

const styles= StyleSheet.create({
    container:{
        height:200,
        backgroundPosition: '50% 25%',
        backgroundSize: 'cover',
        display:'flex',
        justifyContent:'center',
        position:'relative',
        color:'#fff',
        marginBottom:5,
        '::after':{
            // content:'""',
            // position:'absolute',
            // left:0,
            // top:0,
            // height:'100%',
            // width:'100%',
            // backgroundColor:'rgba(0,0,0,0.5)',
            // zIndex:0
        },
        '::before':{
            content:'""',
            position:'absolute',
            left:0,
            top:0,
            height:'100%',
            width:'100%',
            backgroundColor:'rgba(0,0,0,0.5)',
            zIndex:0
        },
        ':hover':{
            cursor:'pointer'
        }
    },
    [`containerSelected_${THEMES.LIGHT}`]: {
        '::before':{
            backgroundColor:'rgba(0,0,0,0.8)',
        },
    },
    [`containerSelected_${THEMES.DARK}`]: {
        '::before':{
            backgroundColor:'rgba(0,0,0,0.8)',
        },
    },
    contentContainer:{
        zIndex:0,
        overflow:'hidden',
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-start',
        alignItems:'flex-start',
        width:'100%'
        // height:200,
    },
    title:{
        fontWeight:400,
        padding:12,
        fontSize:'1.5em'
    },
    bottom:{
        fontSize:'0.9em',
        alignSelf:'flex-start',
        marginTop:'auto',
        padding:10,
        display:'flex',
        alignItems:'center',
        color:'rgba(255,255,255,0.7)',
        overflow:'hidden'
    },
    image_seen:{
        filter: 'grayscale(100%)'
    },
    icon: {
        width: 20,
        height: 20,
        borderRadius: 10,
        filter:'opacity(0.8)',
        // mask:'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))'
    },
});

export default Card;