import {StyleSheet} from 'aphrodite'
import COLORS from '../COLORS';

export const MIDDLE_PANEL_2_WIDTH =300;
export const MIDDLE_PANEL_3_WIDTH =430;

const styles = StyleSheet.create({
    container: {
        width: '100vw',
        height: '100vh',        
        display: 'flex',
        flex: 1,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflow: 'hidden',
        zIndex: 1,
    },
    container_LIGHT:{
        backgroundColor: COLORS.WHITE1,
        color:COLORS.BLACK_TEXT1
    },
    container_DARK:{
        backgroundColor: COLORS.BLACK4,
        color:COLORS.WHITE_TEXT2
    },
    panel:{
        display: 'flex',
        flexDirection:'column',        
    },
    left: {
        // position:'relative',
        zIndex:1,
        // '::before':{
        //     position:'absolute',
        //     height:'100%',
        //     width:'calc(100% - 10px)',
        //     backgroundColor:'#e9ebee',
        //     zIndex:-1,
        //     content:'""',
        //     filter:'blur(10px)',            
        // },
        // '::after':{
        //     position:'absolute',
        //     height:'100%',
        //     width:'100%',
        //     backgroundColor:'#e9ebee',
        //     zIndex:-1,
        //     content:'""',
        //     borderRight:'1px solid rgba(100,100,100,0.5)'
        // }
    },
    left_LIGHT:{
        backgroundColor:COLORS.BLUE_GRAY1,
    },
    left_DARK:{
        backgroundColor:COLORS.DARK_BLUE_GRAY1,
    },
    // [`left_layout_${LAYOUT.TWO_PANE}`]:{
    //     position:'absolute',
    //     top:0,
    //     left:0,
    // },
    middle: {
        width: MIDDLE_PANEL_3_WIDTH,
    },
    middle_2: {
        width: MIDDLE_PANEL_2_WIDTH,
    },
    // [`middle_layout_${LAYOUT.TWO_PANE}`]:{
    //     marginLeft:LEFT_COLUMN_SMALL_WIDTH
    // },
    right: {
        flex: 1,
        width: '100%',
    },
    right_LIGHT:{
        backgroundColor:COLORS.WHITE2
    },
    right_DARK:{
        backgroundColor:COLORS.BLACK3,
        color:COLORS.WHITE_TEXT2
    },
    bar:{
        height:40,
        width:'100%',
        display:'flex',
    },
    content:{
        flex:1,
        width:'100%',
        overflowY:'auto',
    }
})

export default styles;