import {find} from 'lodash'
import config from './conf'


/**
 * This functions creates a new object from 
 * source object by keeping passed properties
 * @param {*} sourceObject Object 
 * @param {*} props to keep
 * @param {*} targetProperty if passed this object will be modified
 */
export function pluck(sourceObject,props,targetProperty){
    let o = targetProperty || {};
    for (let i =0; i<props.length;i++){
        let prop = props[i];
        o[prop] = sourceObject[prop]
    }
    return o;
}

// https://github.com/lodash/lodash/issues/3304
/**
 * Usage: let result = UpdateOrInsert(array,{id:1, name: 'Juli'}, 'id');
 * @param {*} context Array of items
 * @param {*} element object to compare
 * @param {*} compareKey 
 */
export  function updateOrInsert (context, element, compareKey) {
    let updated = false;
    let newContext;
    newContext = context.map($el => {
        if ($el[compareKey] === element[compareKey]) {
            updated = true;
            return element;
        }
        return $el;
    });
    if (!updated) {
        newContext = context.concat(element);
    }
    return newContext;
}

//this methods filters outs stories which exists in activities
export function filterStoriesFromActivities(stories,activities){
    return stories.filter((s)=>{
        return !find(activities, {payload: s.id})
    });
}

export function convertRGBAToCssString(rgba){
    //Accepts rbga object and returns css compatible string
    var cssColorString = "rgba(" + rgba.r + "," + rgba.g + "," + rgba.b + ", " + rgba.a + ")";
    return cssColorString;
}

//Color should lie between 0-255
function normalizeColorValue(num){
    if(num<1){
        return 0
    }
    if(num>255){
        return num;
    }
    return num;
}

export function changeColorShade(rgba,value){
    return {
        r:normalizeColorValue(rgba.r+value),
        g:normalizeColorValue(rgba.g+value),
        b:normalizeColorValue(rgba.b+value),
        a:normalizeColorValue(rgba.a)
    }
}


////////ABOVE Needs to be reviewed

// internal references
let globals = {
    platform: null, 
    db: null,
    services: null
}

export const setPlatform = (platform)=> globals.platform = platform
export const getPlatform = ()=> globals.platform
export const setDatabase = (db)=> globals.db= db
export const getDatabase = ()=>  globals.db 
export const setServices = (services)=> globals.services = services
export const getServices = ()=> globals.services 
export const getGlobals = ()=> globals 


export const initializeApp = (callback)=>{
    return new Promise(function(resolve, reject){
        // Initialize platform
        getPlatform().init({
            proxyUrl:config.PROXY_URL
        })

        // Initialize services
        getServices().init({
            feedlyConfig:{
                clientId:config.VEEN_FEEDLY_CLIENT_ID,
                clientSecret:config.VEEN_FEEDLY_CLIENT_SECRET,
                redirectUrl:config.VEEN_FEEDLY_OAUTH_REDIRECT,
            }
        })

        // Initialize database
        getDatabase().init().then(function(){
            callback();
            resolve();
        });

    });
}