import React,{Component} from 'react'
import LeftPanel from './LeftPanel';
import MiddlePanel from './MiddlePanel';
import RightPanel from './RightPanel';
import SubscriptionSystemUI from '../SubscriptionSystem'

class Layout1 extends Component {
    componentWillUnmount(){
        this.props.resetLeftPanel();
    }
    render() {
        let {css2,toggleCatalogue,isSubscriptionSystemOpen,windowDimensions,story,layout,smallLeftPanel,toggleSettings,toggleSubscriptions,toggleAccounts,toggleLeftPanel} = this.props;

        {/*Display List no story to show*/}
        if(!story){
            return <div className={css2(['container'])}>
                        <LeftPanel
                            css2={css2}
                            smallLeftPanel ={smallLeftPanel}
                            toggleSettings={toggleSettings} 
                            toggleSubscriptions={toggleSubscriptions} 
                            toggleAccounts = {toggleAccounts}
                            toggleLeftPanel ={toggleLeftPanel}
                            floating={true}
                            toggleCatalogue={toggleCatalogue}
                        />
                        <SubscriptionSystemUI hidden={!isSubscriptionSystemOpen} onClose={toggleSubscriptions} />
                        <MiddlePanel 
                                width={windowDimensions.width - 40}
                                css2={css2}
                                containerCss =''
                                layout={layout}
                                style={{
                                    width:windowDimensions.width - 40,
                                    marginLeft:!smallLeftPanel?40:0
                                }}
                                hidden={isSubscriptionSystemOpen}
                        />
                </div>
        }
        return (
            <div className={css2(['container'])}>
                <RightPanel 
                    css2={css2}
                    backButton={true}
                    story={story}
                />
            </div>
        );
    }
}

export default Layout1;
