import {FETCH_APP_STATE_FINISH ,SWITCH_ACCOUNT} from '../actions/names';
import {pluck} from '../../common/utils';
import {createDefaultAppState} from '../../entities/reducerTypes';

const initialState = createDefaultAppState();

export default function reducer(state = initialState,action){    
    if(action.type === FETCH_APP_STATE_FINISH){
        let appState= action.payload;
        //Account not Found
        if(appState === null){
            return {...state, accountFound:false,ready:true}
        }
        //Account found, mark essential properties
        //pluck data your are interested in storing.
        return {
            ...state,
            accountFound:true,
            ready:true,
            ...pluck(appState,["activeAccountId","id"])
        }
    }
    //OLD_CODES
    if(action.type === SWITCH_ACCOUNT){
        return initialState;
    }
    return state;
}