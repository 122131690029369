import React, { Component } from 'react';


/**
 * USAGE
 * <div>
 *      <Overlay  style={} onHide={} />
 * </div>
 */

//This is a low budget overlay. Overlay of poors
class Overlay extends Component {

    onOverlayClick =()=>{
        const noop = ()=>{};
        (this.props.onHide || noop)();
    }

    render() {
        return (
            <div>
                <div style={{...styles.overlay,...this.props.style}} onClick={this.onOverlayClick}></div>
                {this.props.children}
            </div>
        );
    }
}

Overlay.zIndex = 1801;

const styles= {
    overlay:{
        height: '100vh',
        width: '100vw',
        zIndex: 1800,
        backgroundColor: 'transparent',
        position: 'absolute',
        top: 0,
        left: 0,
    }
}



export default Overlay;