import React, {Component} from 'react';
import {StyleSheet, css} from 'aphrodite';
import classnames from 'classnames';
import Ionicon from 'react-ionicons'
import {STORY_VIEW} from '../common/enums';
import {connect} from 'react-redux'
import { removeActivity,createBookmarkActivity,createFavActivity,createReadActivity,toggleWebView,toggleCleanView,updateStatus,clearStory} from '../redux/actions/actionCreators';
import {find} from 'lodash';
import Popover from './Popover';
import { getGlobals }  from '../common/utils'

const globals = getGlobals()
const fontSize = "20px";
const color = '#999';


const MenuRow =({iconName,iconStyle,onClick,text})=>{
    return <div onClick={onClick} className={classnames(css(styles.popoverRow))}>
        <div className={classnames(css(styles.popoverRowLeft))}>
            <Ionicon
                style={iconStyle}
                icon={iconName}
                fontSize={fontSize}
                color={color}/>
        </div>
        <div className={classnames(css(styles.popoverRowRight))}>
            {text}
        </div>
    </div> 
}


const StoryToolbarView = ({
    isRead,
    isBookmarked,
    isFav,
    story,
    isWeb,
    isClean,
    createBookmarkActivity,
    createFavActivity,
    createReadActivity,
    handleClick,
    toggleWebView,
    toggleCleanView,
    toggleMenu,
    isMenuOpen,
    menuPosition,
    textStrings,
    handleCopyToClipBoard,
    theme,
    clearStory,
    backButton
}) => {
    let target;
    return (
        <div className={classnames(css(styles.container))}> 
            {backButton? <div
                onClick={()=>clearStory()} 
                className={classnames(css(styles.col), css(styles.backButtonContainer))}>
                <Ionicon
                    icon="ion-android-arrow-back"
                    fontSize={fontSize}
                    color={color}/>
            </div> :null}     
            <div
                className={classnames(css(styles.col))}
                onClick={() => handleClick(isFav, createFavActivity)}>
                <Ionicon
                    icon={isFav
                    ? "ion-android-favorite"
                    : "ion-android-favorite-outline"}
                    fontSize={fontSize}
                    color={color}/>
            </div>

            <div
                className={classnames(css(styles.col))}
                onClick={() => handleClick(isBookmarked, createBookmarkActivity)}>
                <Ionicon
                    style={isBookmarked
                    ? {}
                    : {
                        fill: 'none',
                        stroke: color,
                        strokeWidth: 40
                    }}
                    icon="ion-bookmark"
                    fontSize={fontSize}
                    color={color}/>
            </div>

            <div className={classnames(css(styles.col))} onClick={toggleCleanView}>
                <Ionicon
                    icon={isClean
                    ? "ion-ios-bolt"
                    : "ion-ios-bolt-outline"}
                    fontSize={'25px'}
                    color={color}/>
            </div>


            {/**
                <div
                    className={classnames(css(styles.col))}
                    onClick={toggleWebView}>
                    <Ionicon
                        style={isWeb? {filter: 'invert(100%)'}:{}}
                        icon="ion-earth"
                        fontSize={fontSize}
                        color={color}/>
                </div>
             */}


            <div className={classnames(css(styles.col))} onClick={toggleMenu}>
                <Ionicon icon="ion-android-more-vertical" fontSize={fontSize} color={color}/>                
            </div>
           
            <Popover 
                isOpen={isMenuOpen} 
                onHide={toggleMenu} 
                position={menuPosition}
                theme={theme}
                style={{
                    height:'',
                }}
            >
                <div className={classnames(css(styles.popoverContainer))}>
                    <MenuRow 
                        iconName= "ion-earth"
                        iconStyle= {isWeb? {filter: 'invert(100%)'}:{}}
                        onClick= {()=>{toggleWebView(); toggleMenu();}}
                        text= {textStrings.WEB_VIEW}
                    />

                    <MenuRow 
                        iconStyle= {isRead? {stroke: '#666',strokeWidth:30}:{}}
                        iconName= {isRead?"ion-ios-circle-outline":"ion-record"}
                        onClick={() => {handleClick(isRead, createReadActivity);toggleMenu();}}
                        text= {isRead? textStrings.MARK_AS_UNREAD: textStrings.MARK_AS_READ}
                    />

                    <MenuRow 
                        iconName= "ion-clipboard"
                        onClick={() => {handleCopyToClipBoard(story.url);toggleMenu();}}
                        text= {textStrings.COPY_LINK}
                    />

                </div>
            </Popover>
            

        </div>
    );
}

class StoryToolbar extends Component {
    state ={
        isMenuOpen:false,
        menuPosition:null
    }

    toggleMenu =(e)=>{
        this.setState({
            isMenuOpen:!this.state.isMenuOpen,
            menuPosition: Popover.calculatePosition(e)
        })
    }

    handleCopyToClipBoard =(url)=>{
        let {textStrings,updateStatus} = this.props;
        globals.platform.copyToClipBoard(url);
        updateStatus(null,'',textStrings.STATUS_COPIED_TO_CLIPBOARD,25)
        //Todo: Update status
    }

    //Handles activity click
    handleClick = (activity, activityCreator) => {
        let {removeActivity} = this.props;
        let {story}  = this.props.story;
        if (activity) {
            removeActivity(activity);
        } else {
            activityCreator(story.id);
        }
    }

    clearStory =()=>{
        this.props.clearStory();
    }

    render() {
        let {story} = this.props.story;
        if (!story) {
            return null;
        }
        let {view} = this.props.story;
        let {
            activities,
            removeActivity,
            createBookmarkActivity,
            createFavActivity,
            createReadActivity,
            toggleWebView,
            toggleCleanView,
            textStrings,
            theme,
            backButton
        } = this.props;
        
        let read = find(activities.read, {payload: story.id}),
            bookmarked = find(activities.bookmarks, {payload: story.id}),
            fav = find(activities.favs, {payload: story.id}),
            web = STORY_VIEW.WEB === view,
            clean = STORY_VIEW.CLEAN === view;

        return <StoryToolbarView
                    isRead={read}
                    isBookmarked={bookmarked}
                    isFav={fav}
                    isWeb={web}
                    isClean={clean}
                    story={story}
                    toggleMenu={this.toggleMenu}
                    isMenuOpen ={this.state.isMenuOpen}
                    menuPosition= {this.state.menuPosition}
                    createBookmarkActivity={createBookmarkActivity}
                    createFavActivity={createFavActivity}
                    createReadActivity={createReadActivity}
                    handleClick={this.handleClick}
                    toggleWebView={toggleWebView}
                    toggleCleanView={toggleCleanView}
                    textStrings={textStrings}
                    handleCopyToClipBoard={this.handleCopyToClipBoard}
                    theme={theme}
                    backButton={backButton}
                    clearStory={this.clearStory}
            />
    }
}

StoryToolbar.defaultProps ={
    backButton:false
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flex: 1,
        padding: '0px 10px',
        justifyContent: 'flex-end'
    },
    col: {
        width: 40,
        marginLeft:20,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
    backButtonContainer:{
        marginRight:'auto',
        marginLeft:0
    },
    popoverContainer:{
    },
    popoverRow:{
        display:'flex',
        padding:10,
        cursor:'pointer',
        alignItems:'center',
        ':hover':{
            backgroundColor:'rgba(0,0,0,0.1)'
        }
    },
    popoverRowLeft:{
        width:40
    },
    popoverRowRight:{
        flex:1
    }
});

const mapStateToProps = (state, ownProps) => {
    return {
        story: state.story, 
        activities: state.activities,
        textStrings:state.textStrings,
        theme:state.settings.settings.theme
    }
}

export default connect(mapStateToProps, {
    removeActivity,
    createBookmarkActivity,
    createFavActivity,
    createReadActivity,
    toggleWebView,
    toggleCleanView,
    updateStatus,
    clearStory
})(StoryToolbar);