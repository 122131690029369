import React, { Component } from 'react';
import {LAYOUT} from '../../common/enums';
import Layout1 from './Layout1'
import Layout2 from './Layout2'
import Layout3 from './Layout3'
import Popover from '../Popover';
import {connect} from 'react-redux'
import {tCss} from '../utils'
import styles from './styles'
import SettingsUI from '../Settings';
import Status from '../Status';
import AccounstPopover from '../Accounts/AccountsPopOver';
import Catalogue from '../Monetisation/Catalogue'
import COLORS from '../COLORS'
import {changeSettings} from '../../redux/actions/actionCreators'
import { getGlobals }  from '../../common/utils'

const globals = getGlobals()


//Default layouts for panes
function getLayouts(settings){
    let layouts ={
        [`${LAYOUT.ONE_PANE}`]:{
            Layout:Layout1,
            smallLeftPanel:true
        },
        [`${LAYOUT.TWO_PANE}`]:{
            Layout:Layout2,
            smallLeftPanel:true
        },
        [`${LAYOUT.THREE_PANE}`]:{
            Layout:Layout3,
            smallLeftPanel:settings.defaultSmallLeftPanelIn3Pane
        },
    }
    return layouts;    
}


//If value is defined then user must have set it.
//If value is undefined, we just pick the default for the layout
function calculateSmallPane(val,enumLayout,settings){
    if(typeof(val) === 'undefined'){
        return getLayouts(settings)[enumLayout].smallLeftPanel;
    }
    return val;
}

//Calculate which layout to pic based on windowDimension
function calculateLayout(windowDimensions){
    let layout= LAYOUT.THREE_PANE;

    if(windowDimensions.width<1000){
        layout= LAYOUT.TWO_PANE;
    }
    if(windowDimensions.width<800){ //this value was 600 previously
        layout= LAYOUT.ONE_PANE;
    }
    return layout;
}

function getDefaultState(){
   return {
    isSettingsOpen:false,
    isAccountsPopoverOpen:false,
    isSubscriptionSystemOpen:false,
   }
}

class LayoutManager extends Component {
    state ={
        smallLeftPanel:undefined, //undefined means its not set by user
        position:null,
        catalogueVisible:false,
        ...getDefaultState()
    }
    
    _handleToggleState =(propName,e)=>{
        this.setState({
            [propName]: !this.state[propName],
            position: Popover.calculatePosition(e)
        });
    }
    handleToggleSettings = (e)=>{this._handleToggleState('isSettingsOpen',e)}
    handleToggleAccountsPopover = (e)=>{this._handleToggleState('isAccountsPopoverOpen',e)}
    handleToggleSubscriptionSystem = (e)=>{this._handleToggleState('isSubscriptionSystemOpen',e)}
    handleToggleCatalogue = (e)=>{this._handleToggleState('catalogueVisible',e)}

    toggleLeftPanel =(currentValue)=>{
        this.setState({
            smallLeftPanel: !currentValue
        });
        let {windowDimensions,changeSettings} = this.props;
        let enumLayout = calculateLayout(windowDimensions);
        if(enumLayout === LAYOUT.THREE_PANE){
            //update settings
            changeSettings('defaultSmallLeftPanelIn3Pane',!currentValue);
        }
    }
    resetLeftPanel =(val)=>{   
        this.setState({
            smallLeftPanel: undefined
        });
    }

    componentWillReceiveProps(nextProps){
        let accountOld = this.props.account;
        let accountNew = nextProps.account;
        //This will clear subscription system UI, if its visible and newFolder or Subscription is Clicked
        if(this.state.isSubscriptionSystemOpen && (accountOld.selectedFolderId !== accountNew.selectedFolderId || accountOld.selectedSubscriptionId !== accountNew.selectedSubscriptionId)){
            this.handleToggleSubscriptionSystem();
        }
    }

    componentDidMount(){
        globals.platform.updateAppTitlebarColor(COLORS.PRIMARY_RGBA);
    }

    render() {
        let {windowDimensions,settings} = this.props;
        let {isSettingsOpen,smallLeftPanel,position,isAccountsPopoverOpen,isSubscriptionSystemOpen} = this.state;
        settings= settings.settings;
        let {story} = this.props.story; 
        let css2 = tCss(settings.theme)(styles);
        let enumLayout = calculateLayout(windowDimensions);
        let Layout = getLayouts(settings)[enumLayout].Layout;
        return (<div>
                    <Layout 
                        css2={css2}
                        toggleSettings={this.handleToggleSettings} 
                        toggleSubscriptions={this.handleToggleSubscriptionSystem} 
                        toggleAccounts = {this.handleToggleAccountsPopover}
                        layout={enumLayout}
                        story={story}
                        windowDimensions= {windowDimensions}
                        smallLeftPanel ={calculateSmallPane(smallLeftPanel,enumLayout,settings)}
                        toggleLeftPanel={this.toggleLeftPanel}
                        resetLeftPanel = {this.resetLeftPanel}
                        isSubscriptionSystemOpen={isSubscriptionSystemOpen}
                        toggleCatalogue= {this.handleToggleCatalogue}
                    />

                    {/**
                    * Hidden Elements
                    */}

                    <SettingsUI 
                        isOpen={isSettingsOpen} 
                        toggle={this.handleToggleSettings} 
                    />

                    <AccounstPopover 
                        position={position} 
                        isOpen={isAccountsPopoverOpen} 
                        toggle={this.handleToggleAccountsPopover} 
                    />

                    <Status />

                    {/**/}
                    {this.state.catalogueVisible?<Catalogue 
                            toggleCatalogue={this.handleToggleCatalogue} 
                        />:null}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        story: state.story,
        settings:state.settings,
        account:state.account,
    }
}
export default connect(mapStateToProps,{changeSettings})(LayoutManager)
