import React, { Component } from 'react';
import {StyleSheet,css} from 'aphrodite'
import lang from '../../lang';
import classnames from 'classnames'

class Languages extends Component {
    render() {
        let {changeLanguage,textStrings} = this.props;
        return (
            <div className={css(styles.container)}>
                {lang.getActiveNames().map((lang,i)=>{
                    return <div onClick={()=>{changeLanguage(lang.key)}}                    
                            className={classnames(css(styles.languageContainer),{
                                [css(styles.selected)]:textStrings.key === lang.key
                            })} 
                            key={i}>
                        {lang.displayName}
                    </div>
                })}
            </div>
        );
    }
}

const styles = StyleSheet.create({
    container:{
        // backgroundColor:'red',
        // padding:30,
        display:'flex',
        position:'absolute',
        bottom:30,
        left:30
    },
    languageContainer:{
        padding:5,
        cursor:'pointer',
        color:'rgba(255,255,255,0.8)',
        fontSize:'0.9em'
    },
    selected:{
        cursor:'initial',
        color:'rgba(255,255,255,1.0)',
        fontSize:'1.0em'
    }
});

export default Languages;