import React, { Component } from 'react';
import {isPremium} from '../utils'
import {connect} from 'react-redux'
import Ad from './RenderAd'

class RightPanelAd extends Component {
    state ={
        visible:true,
        zoom:'50%'
    }
    timer = null;
    lastAdvTime = Date.now();
    advWith =1;

    setWidth =(addWidth)=>{
        this.advWith = addWidth;
    }

    updateZoom =()=>{
        let addWidth = this.advWith;
        if(this.refs.addContainer){
            let width = this.refs.addContainer.clientWidth;
            let zoom = Math.floor((width/ addWidth) * 100);
            if(zoom>99){
                zoom=  100;
            }
            this.setState({
                zoom:`${zoom}%`
            });
        }
    }

    componentDidMount(){
        this.updateZoom();
    }

    componentWillReceiveProps(nextProps){
        this.updateZoom();
        let waitInMinutes = 3;
        let waitInMilSec = waitInMinutes * 60*1000
        if( (Date.now() - this.lastAdvTime)> waitInMilSec){
            this.lastAdvTime = Date.now();
            clearTimeout(this.timer)
            this.setState({
                visible:false
            })
            this.timer =  setTimeout(()=>{
                this.setState({
                    visible:true
                })
            },300)
        }
        // else{
        //     console.log("wait");
        // }
    }

    render() {
        if(isPremium(this.props.license.products)){
            return null;
        }
        // console.log(this.state.zoom)

        return (
            <div ref="addContainer" style={{
                height:90,
                width:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                backgroundColor:'#ddd',
            }}>
                {this.state.visible? <Ad zoom={this.state.zoom} setWidth={this.setWidth} /> :null}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        license: state.license,
        ui:state.ui
    }
}

export default connect(mapStateToProps)(RightPanelAd)
