//Common interface for All Services START
export function getKey(){
    return "local";
}
export function init(){
    
}

var counter=0;
export function startLoginFlow(callbacks,fullName){
    let {onCompleted} = callbacks;
    counter++;
    let profile = {
        fullName:fullName,
        id:`${Date.now()}_${counter}`
    }
    onCompleted(profile,getKey(),null);
}

export function refreshToken(){
    return false;
}


//No web request is made if false is returned
export function markAsRead(){
    return false;
}

export function markAsUnread(){
    return false;
}

export function markAsSaved(){
    return false;
}

export function markAsUnSaved(){  
    return false;
}

export {searchTextForSubscriptions,getStories} from './feedly';
//Common interface for All Services END