import * as feedly from './feedly';
import * as facebook from './facebook';
import * as local from './local';


const services = {
    feedly,
    facebook,
    local,
    getAll:function(){
        return [feedly,facebook,local]
    },
    init:function(confs){
        // Initialize feedly
        feedly.init(confs['feedlyConfig'])
        // Initialize others
    },
    getService:function(serviceKey){
        let _services= services.getAll();
        for(let i=0;i<i<_services.length;i++){
            if(_services[i].getKey() === serviceKey){
                return _services[i];
            }
        }
    }
}

export default services;