import {STORY_LIST_SORT_DIRECTION,STORIES_LIST_FILTER} from '../common/enums';



///Creator
export function createAccountDoc(profile,token,type){
    let acc ={
        id:profile.id,
        created_at:Date.now(),
        openFolderIds:[],
        profile:profile,
        selectedFolderId:STORIES_LIST_FILTER.ALL,
        selectedStoryId:null,
        selectedSubscriptionId:null,
        sort:STORY_LIST_SORT_DIRECTION.NEWEST_FIRST,
        storyFilter:null,
        subscriptions:[],
        syncTime:{},
        token:token,
        type:type
    };
    return acc;
}

export function createAppStateDoc(account,settings={}){
    let appState ={
        activeAccountId:account.id,
        id:null,
        settings:settings
    }
    return appState;
}