import React from 'react';

export default  ({story})=>{
    let url = story.url;
    if(story.payload.cdnAmpUrl){
        url = story.payload.cdnAmpUrl
    }    
    return (<iframe style={{
                width:'100%',
                height:'100vh'
            }} src={url} />
        )
}