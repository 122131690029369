const COLORS = {
    WHITE1:'#fff',
    WHITE2:'#fcfcfc',
    WHITE3:'#f4f4f4',
    BLUE_GRAY1:'#e9ebee',
    DARK_BLUE_GRAY1:'#484848',
    BLACK1:'#000',
    BLACK2:'#111',
    BLACK3:'#222',
    BLACK4:'#333',
    BLACK5:'#444',
    BLACK6:'#555',

    BLACK_TEXT1:'#000',
    BLACK_TEXT2:'#101010',
    WHITE_TEXT1:'#fff',
    WHITE_TEXT2:'#c0c0c0',

    BLUE1:'#2196f3',

    POPOVER_BG_LIGHT:'#fff',
    POPOVER_BG_DARK:'#333',

    SUCCESS:'#0f9960',
    PRIMARY:'#0063B1',
    PRIMARY_RGBA:{
        r: 0,
        g: 99,
        b: 177,
        a:1
    }
}

export default COLORS;