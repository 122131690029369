//Sinlgetons are defined and initialised inside schema function.
//All queries should be run after DB initialised from

//Safari need web sql

import  {singletons} from './schema';
import lf from 'lovefield';
import {uniq,map,reduce} from 'lodash'
import {ACTIVITY_NAMES} from '../../common/enums'

/**
 * Close Database connection. 
 * Note: In memory database loose everything when closed
 * */
export function closeConnection(){
    singletons.DB.close();
}


////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////
//////////Query Helpers STARTS//////////////
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////



/**
 * Builds an insert query. Doesn't run it
 * @param {Array} items array of items to be inserted
 * @param {object} table reference, where to insert?
 */

function buildInsertQuery(items,table){
    let frows  = items.map( (a)=>table.createRow(a));
    return singletons.DB.insertOrReplace().into(table).values(frows);
}
  
  
/**
 * This builds and runs an insert query
 * @param {array} items rows to insert
 * @param {object} table table reference, where to insert?
 */
  function insertRowsIntoTable(items,table){
    return buildInsertQuery(items,table)
    .exec()
  }
  
  
  /**
   * This runs a transaction
   * @param {Array} queries array of queries to run in transaction. 
   * You can build queries with buildQuery
   */
  function runATransaction(queries){
    var tx = singletons.DB.createTransaction();
    return tx.exec(queries);
  }
  
  
  
  /**
   * Get All rows of a Table
   * @param {object} table reference. Table whose rows needs to fetched
   */
  function fetchTableAllRows(table){
      return singletons.DB.select().from(table).exec();
  }
  
  
  
  /**
   * Updates a Database table
   * @param {object} table Table you want to update
   * @param {Array<Array>} propValuePair array or arrays => [[prop,val],[prop,val]]
   * @param {object} predicate thing that goes inside where clause of sql query
   */
  function updateTable(table,propValuePair,predicate=null){
      let query = singletons.DB.update(table);
  
      //Loop through each prop, val
      for(let i=0;i<propValuePair.length;i++){
          let prop =propValuePair[i][0]
          let value =propValuePair[i][1]
          query.set(table[prop],value)
      }
      if(!!predicate){
          return query.where(predicate).exec()
      }else{
        return query.exec()
      }
  }


////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////
///////App Specfic Queries STARTS///////////
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////


////////////////////////////////////////////
/////////////////FETCH//////////////////////
////////////////////////////////////////////

/**
 * Write Fetch Queries Here
 */

export function fetchAppStartData(){
    return new Promise(function(resolve,reject){
        const  {ACCOUNTS_TABLE,APP_STATE_TABLE,DB} = singletons;
        let q1 =  DB.select().from(ACCOUNTS_TABLE);
        let q2 =  DB.select().from(APP_STATE_TABLE);

        //we are using this, not inner join or transactions 
        //as they are causing errors on iPad 
        // return q1.exec().then(function(accounts){
        //     return q2.exec().then(function(appState){
        //         alert(accounts[0]);
        //         alert(appState[0]);
        //         console.log(accounts,'accounts');
        //         console.log(appState,'appState');
        //         accounts.length <1 ?  alert('No ACC'):  alert(accounts[0].id);
        //         resolve({
        //             accounts,
        //             appState:appState[0]
        //         });
        //     });
        // });

        runATransaction([q1,q2])
        .then(function(res){
            let accounts = res[0];
            let account =null,appState=null;
            if(!!accounts[0]){
                appState = res[1][0];
                account = accounts.filter((a)=>a.id === appState.activeAccountId)[0]              
            }
            resolve({account,appState});
        }).catch(reject);

    });
}

/**
 * Returns all rows off table stories
 * Should not be used.
 */
export function fetchTableStories(){
    return fetchTableAllRows(singletons.STORIES_TABLE);
}

export function fetchStories(subscriptionIds,includeIds=null,excludeIds=null,sort={prop:'published',dir:'desc'}){
    //includeIds == null, ignored
    //includeIds ==[], if passed only these Ids' will be returned (if found)

    //excludeIds==null, ignored
    //excludeIds ==[], if passed these ids' will be ignored

    let st = singletons.STORIES_TABLE;
    let predicate =[st.subscriptionId.in(subscriptionIds)];

    //include Id's
    if(includeIds !=null){
        predicate.push(st.id.in(includeIds));
    }

    //exclude Id's
    if(excludeIds !=null){
        //When ignoring stories. if Array is empty. it returns no result
        //so we are adding random value.
        if(excludeIds.length===0){
            excludeIds = excludeIds.concat('-=-=-=-');
        }
        predicate.push(lf.op.not(st.id.in(excludeIds)));
    }

    let dir = {
        asc:lf.Order.ASC,
        desc:lf.Order.DESC
    } 

    return singletons.DB
        .select().from(st)
        .where(lf.op.and( ...predicate))
        .orderBy(st[sort.prop], dir[sort.dir])
        .exec();
}

export function fetchActivities(account){
    let a = singletons.ACTIVITY_TABLE;
    return singletons.DB
        .select()
        .from(a)
        .where(a.accountId.eq(account.id))
        .exec();
}

export function fetchAccounts(){
    return fetchTableAllRows(singletons.ACCOUNTS_TABLE);
}

export function fetchAccount(accountId){
    let ac = singletons.ACCOUNTS_TABLE;
    return singletons.DB.select().from(ac)
    .where(ac.id.eq(accountId)).exec(); 
}


export function fetchMercuryArticle(story){
    let mt = singletons.MERCURY_ARTICLES_TABLE;

    //check for url to be url,mercuryUrl, or storyId.
    return singletons.DB.select().from(mt)
    .where(lf.op.or(mt.url.eq(story.url),mt.mercuryUrl.eq(story.url),mt.storyId.eq(story.id))).exec();
}

export function fetchUnreadCounts(subscriptionIds,excludeIds=null){
    //includeIds == null, ignored
    //includeIds ==[], if passed only these Ids' will be returned (if found)

    //excludeIds==null, ignored
    //excludeIds ==[], if passed these ids' will be ignored

    let st = singletons.STORIES_TABLE;
    let predicate =[st.subscriptionId.in(subscriptionIds)];

    //exclude Id's
    if(excludeIds !=null){
        //When ignoring stories. if Array is empty. it returns no result
        //so we are adding random value.
        if(excludeIds.length===0){
            excludeIds = excludeIds.concat('-=-=-=-');
        }
        predicate.push(lf.op.not(st.id.in(excludeIds)));
    }


    return singletons.DB
        .select(lf.fn.count(st.id).as('count'),st.subscriptionId).from(st)
        .where(lf.op.and( ...predicate))
        .groupBy(st.subscriptionId)
        .exec();
}


////////////////////////////////////////////
/////////////////INSERT/////////////////////
////////////////////////////////////////////


/**
 * This creates an account.
 * If you pass appState along, appState will
 * be created and and active account will point to sent account
 * @param  account required: Account to be created. Must have following properties
 * created_at, profile, token, type. Rest defaults will be added automatically
 * @param  appState optional: AppState to be inserted along
 */
export function createAccount(account,appState=null){
    //Create Transaction object
    let tx = singletons.DB.createTransaction();
    const {ACCOUNTS_TABLE, APP_STATE_TABLE} = singletons;

    //Begin Transaction
    return tx.begin([ACCOUNTS_TABLE,APP_STATE_TABLE]).then(function(){
        //Insert account and attach it to transaction object
        let q= buildInsertQuery([account],ACCOUNTS_TABLE);
        return tx.attach(q);
    }).then(function(rows){
        //If App_State passed, attach it  to transaction.
        if(appState!==null){
            appState.id = null; //Remove any id if passed. Id is auto property
            //it will be added by DB itself
            let q= buildInsertQuery([appState],APP_STATE_TABLE);
            return tx.attach(q);        
        }else{
            return tx;
        }
    })
    .then(function(){
        return tx.commit();
    });
}


export function insertStories(stories){
    return insertRowsIntoTable(stories,singletons.STORIES_TABLE);
}

export function insertActivity(activity){
    return insertRowsIntoTable([activity],singletons.ACTIVITY_TABLE);
}
export function insertBulkActivities(activities){
    return insertRowsIntoTable(activities,singletons.ACTIVITY_TABLE);
}

export function insertMercuryArticle(article){
    return insertRowsIntoTable([article],singletons.MERCURY_ARTICLES_TABLE);
}

////////////////////////////////////////////
/////////////////UPDATE/////////////////////
////////////////////////////////////////////

/**
 * Updates an account. Dont use this function diectly. Use proxy
 * methods written over it.
 * @param {AccountType} account account to be updated
 * @param {string} prop property to be updated
 * @param {any} value new value of property
 */
function _updateAccount(account,prop,value){
    let a = singletons.ACCOUNTS_TABLE;
    return updateTable(a,[[prop,value]],a.id.eq(account.id));
}

/**
 * Updates AppState object. Dont use this function diectly. Use proxy
 * methods written over it.
 * @param {string} prop 
 * @param {any} value 
 */
function _updateAppState(prop,value){
    let table= singletons.APP_STATE_TABLE
    let query = singletons.DB.update(table);
    query.set(table[prop],value)
    return query.exec()
}


/**
 * Updates all scalar properties of an Account's state
 * @param {*} account 
 */
export function updateAccountState(account){
    let a = singletons.ACCOUNTS_TABLE;
    return updateTable(a,[
        ['selectedFolderId',account.selectedFolderId],
        ['selectedSubscriptionId',account.selectedSubscriptionId],
        ['selectedStoryId',account.selectedStoryId],
        ['openFolderIds',account.openFolderIds],
        ['storyFilter',account.storyFilter],
        ['sort',account.sort],
        ['syncTime',account.syncTime]
    ],a.id.eq(account.id));
}

/**
 * Updates token and profile of an account
 * @param {*} account 
 * @param {*} token 
 * @param {*} profile 
 */
export function updateAccountTokenProfile(account,token,profile){
    let a = singletons.ACCOUNTS_TABLE;
    return updateTable(a,[
        ['token',token],
        ['profile',profile]
    ],a.id.eq(account.id));
}

/**
 * This functions replaces account's subscriptions property with passed value
 * @param {object} account 
 * @param {array} subscriptions 
 */
export function updateSubscriptions(account, subscriptions){
   return _updateAccount(account,'subscriptions',subscriptions);
}

//Updates token of an account
export function updateToken(account, newToken){
    return _updateAccount(account,'token',newToken);
}


/**
 * Updates scalar properties of AppState
 * @param {object} appState 
 */
// export function updateAppStateScalars(appState){
//     let a = singletons.APP_STATE_TABLE;
//     return updateTable(a,[
//         ['activeAccountId',appState.activeAccountId]
//     ],null);
// }

export function updateAppStateActiveAccountId(activeAccountId){
    return _updateAppState('activeAccountId',activeAccountId)
}
/**
 * Updates settings in AppState.
 * @param {object} settings 
 */
export function updateSettings(settings){
    return _updateAppState('settings',settings);
}
////////////////////////////////////////////
/////////////////REMOVE/////////////////////
////////////////////////////////////////////

export function removeActivity(activityId){
    let a = singletons.ACTIVITY_TABLE;
    return singletons.DB
    .delete().from(a).where(a.id.eq(activityId)).exec();
}

export function removeAccount(account){
    let acc = singletons.ACCOUNTS_TABLE;
    let act = singletons.ACTIVITY_TABLE;
    let db= singletons.DB;
    //remove acccount and all activities
    let q1 =  db.delete().from(acc).where(acc.id.eq(account.id));
    let q2 =  db.delete().from(act).where(act.accountId.eq(account.id));
    var tx = db.createTransaction();
    return tx.exec([q1,q2])
}

export function cleanStories(removeStoriesOlderThan,subscriptionIds){
    //this method removes
    //a= orphan stories (stories with no subscription)
    //b= expired stories
    //c= its doesn't delete a story if it is bookmarked

    //
    // let st = singletons.STORIES_TABLE;
    // return singletons.DB
    // .delete().from(st).where(lf.op.not(st.subscriptionId.in(subscriptionIds))).exec();

    let st = singletons.STORIES_TABLE;
    let act = singletons.ACTIVITY_TABLE;
    let acc = singletons.ACCOUNTS_TABLE;
    let db= singletons.DB;

    var tx = db.createTransaction();
    let q1= db.select().from(acc);
    let q2= db.select().from(act);
    return tx.exec([q1,q2]).then((r)=>{
        let accounts = r[0];
        let activities = r[1];

        //collect subscriptions
        let subscriptions = reduce(accounts,(s,a)=>s.concat(a.subscriptions),[])
        //colection subscriptionIds
        let subscriptionIds =  uniq(map(subscriptions,s=>s.id))
        //should not delete stories which are not read
        let doNotDeleteIds = activities
                        .filter(a=>a.activityName !==ACTIVITY_NAMES.READ)
                        .map(a=>a.payload);
        doNotDeleteIds.push('--');
        subscriptionIds.push('--');
        //Added to handle case for empty array
        
        let predicate = [];
        // predicate.push(lf.op.not(st.subscriptionId.in(subscriptionIds)))
        let time = Date.now() - (removeStoriesOlderThan * 24 *60 *60*1000)
        // lf.op.not(st.subscriptionId.in(subscriptionIds))
        // st.published.lt(time)
        predicate.push(lf.op.or(lf.op.not(st.subscriptionId.in(subscriptionIds)),st.downloadedTime.lt(time)))
        predicate.push(lf.op.not(st.id.in(doNotDeleteIds)))
        // predicate.push(st.published.lt(time))
        // console.log(time,removeStoriesOlderThan,'RUN2');
        let q = db.delete().from(st).where(lf.op.and(...predicate))
        // let q = db.delete().from(st).where(st.published.lt(time))
        return q.exec();
    });
    // tx.exec([q3]).then(function(){
    //     console.log('done');
    // });
    
    // var tx = db.createTransaction();
    // return tx.begin([st,act,acc]).then(()=>{
    //     let q1= db.select().from(acc);
    //     let q2= db.select().from(act);
    //     return tx.attach([q1,q2]);
    // })
    // // .then((r1,r2)=>{
    // //     let q= db.select().from(act);
    // //     console.log(r1,r2);
    // //     return tx.attach(q);
    // // })
    // .then((r1,r2)=>{
    //     console.log(r1,r2);
    //     // let predicate =[act.activityName.eq('READ')];
    //     // let storyIds = stories.map((s)=>{
    //     //     return s.id;
    //     // })
    //     // predicate.push(lf.op.not(act.payload.in(storyIds)));
    //     let q = db.delete().from(st).where(st.id.eq('123243235'))
    //     return tx.attach(q);
    // })
    // .then(function(){
    //     return tx.commit();
    // });
}


export function removeOrphanActivities(){
    let st = singletons.STORIES_TABLE;
    let act = singletons.ACTIVITY_TABLE;
    let db= singletons.DB;

    //orphanStories= activities whos stories doesn;t exist

    var tx = db.createTransaction();
    return tx.begin([st,act]).then(()=>{
        //select all stories
        let q= db.select(st.id).from(st);
        return tx.attach(q);
    }).then((stories)=>{
        //an activity is eligible if its read
        let predicate =[act.activityName.eq('READ')];
        let storyIds = stories.map((s)=>{
            return s.id;
        })
        //remove activities which doesn't exist in storyIds(all stories inDB)
        predicate.push(lf.op.not(act.payload.in(storyIds)));
        let q = singletons.DB
            .delete().from(act).where(lf.op.and( ...predicate))
        return tx.attach(q);
    })
    .then(function(){
        return tx.commit();
    });
    
    //activityName = READ
    //and payload not in stories.id  


    // //storyIds =  ids of all stories in DB
    // let storyIds = singletons.DB.select(st.id).from(st);
    // let predicate =[act.activityName.eq('READ')];
    // predicate.push(lf.op.not(act.payload.in(storyIds)));
    // return singletons.DB
    // .delete().from(act).where(lf.op.and( ...predicate)).exec();  
    
    // return db.
    // select(st.id).
    // from(st, act).
    // leftOuterJoin(act, st.id.eq(act.payload)).
    // where(st.id.isNull()).
    //  exec();

    // return db.
    // select().
    // from( act).
    // where(lf.op.not(act.payload.in(singletons.DB.select(st.id).from(st)))).
    //  exec();
}