import {STORE_TYPES,setup} from './schema';


function isSafari(){
    try {
        let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        return isSafari;
    } catch (error) {
        return false;
    }
}

//All queries should be run after invoking this function
export default function init(){
    return isSafari()? setup(STORE_TYPES.websql) : setup(STORE_TYPES.indexeddb);
}