import React, { Component } from 'react';
import {xorBy,find,trim} from  'lodash';
import {StyleSheet} from 'aphrodite'
import {tCss} from '../utils'
import Ionicon from 'react-ionicons'
import COLORS from '../COLORS'

const fontSize = "20px";
const color = '#999';


class Menu extends Component {
    state= {
        categories: this.props.categories,
        newCategoryText:'',
        newSubscriptionName:this.props.subscription.title
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            categories: nextProps.categories,
            newCategoryText:''
        });
    }

    onCheckboxClick =(cat)=>{
        let cats= this.state.categories;
        let newCats = xorBy(cats,[cat],'id');
        this.setState({
            categories: newCats
        });
    }

    renderUncat(css2,checked,text="Uncat"){
        return <div key={`uncat-${checked}`} className={css2('row')}>                        
                <Ionicon
                    icon={checked?"ion-android-checkbox-outline":"ion-android-checkbox-outline-blank"}
                    fontSize={fontSize}
                    color={color}/> 
                &nbsp;&nbsp;&nbsp;
                <span>{text}</span>
        </div>
    }

    handleInputChange = (e)=>{
        this.setState({
            newCategoryText:trim(e.target.value)
        });
    }

    handleSubscriptionTitleChange =(e)=>{
        this.setState({
            newSubscriptionName:trim(e.target.value)
        });
    }


    handleSave = ()=>{
        let {subscription, allCategories,handleSubscribe,toggleMenu} = this.props;
        let {categories,newCategoryText,newSubscriptionName} = this.state;
        handleSubscribe(subscription,categories,newCategoryText,newSubscriptionName)
        toggleMenu(subscription.feedId);
    }
    

    render() {
        let {allCategories,theme,textStrings,subscription}  = this.props;
        let {categories,newCategoryText, newSubscriptionName} = this.state;
        
        let css2 = tCss(theme)(styles);
        let catDom  = allCategories.map((cat,i)=>{
            let checked= !!find(categories,{id:cat.id});
            return <div key={i} className={css2('row')} 
                        onClick={()=>this.onCheckboxClick(cat)}>                        
                        <Ionicon
                            icon={checked
                            ? "ion-android-checkbox-outline"
                            : "ion-android-checkbox-outline-blank"}
                            fontSize={fontSize}
                            color={color}/> 
                        &nbsp;&nbsp;&nbsp;
                        <span>{cat.label}</span>
            </div>
        })

        return (
            <div className={css2('container')}>                
                <div  className={css2('inputContainer')}  style={{marginBottom:10}}>
                    <input 
                        placeholder={textStrings.NEW_FOLDER_NAME}
                        autoFocus
                        type="text"   
                        className={css2('input')}  
                        value={newCategoryText} 
                        onChange={this.handleInputChange} 
                    />
                </div>
                <div  className={css2('inputContainer')}>
                    <input 
                        onFocus = {(e)=>{e.target.select()}}
                        type="text"   
                        className={css2('input')}  
                        value={newSubscriptionName} 
                        onChange={this.handleSubscriptionTitleChange} 
                    />
                </div>                
                <div  className={css2('foldersContainer')}>
                    {catDom}
                    {this.renderUncat(css2,categories<1 && newCategoryText==='', textStrings.UNCATEGORISED)}
                </div>
                <div  className={css2('subscribeButtonContainer')}>
                    <button  
                        className={css2('button')} 
                        onClick={this.handleSave}>
                        {textStrings.SAVE}
                    </button>
                </div>
            </div>
        );
    }
}

let styles= StyleSheet.create({
    container:{
        padding:10
    },
    foldersContainer:{
        maxHeight:230,
        overflowY:'auto',
    },
    inputContainer:{},
    input:{
        width:'100%',
        padding:5,
        fontSize:'1em'
    },
    subscribeButtonContainer:{
        paddingTop:5
    },
    button:{
        width:'100%',
        padding:10,
        border:'none',
        fontSize:'1em',
        cursor:'pointer',
        backgroundColor:COLORS.PRIMARY,
        color:'#fff'
    },
    row:{
        borderBottom:'1px solid #ccc',
        padding:10,
        display:'flex',
        alignItems:'center',
        cursor:'pointer'
    }
});

export default Menu;