import React, { Component } from 'react';
import {css,StyleSheet} from 'aphrodite';
import classnames from 'classnames'

const BottomBar = ({welcomeMsg1,welcomeMsg2})=>(
    <div className={css(styles.container)}>
        <div  className={css(styles.titleContainer)}>
            <div>
                <div className={css(styles.msg)}>{welcomeMsg1}</div>
                <div className={classnames(css(styles.msg),css(styles.msgBottom))}>{welcomeMsg2}</div>
            </div>
        </div>
    </div>
)

const styles= StyleSheet.create({
    container:{
        display:'flex',
        height:200,        
        color:'#fff',
        display:'flex',
    }, 
    titleContainer:{
        fontSize:'2.5em',
        display: 'flex',
        flex:1,
        'alignItems': 'flex-start',
        justifyContent:'center',
        textTransform: 'uppercase',
        color:'#fff',
        textAlign:'center',
        fontWeight:100
    },
    break:{
        // height:70,
        // display:'block',
        // '@media (min-width: 600px)':{
        //     display:'none'
        // }
    },
    msg:{
        display:'block',
        '@media (min-width: 600px)':{
            display:'inline'
        }
    },
    msgBottom:{
        '@media (max-width: 600px)':{
            marginTop:10,
            fontWeight:400
        } 
    }
});

export default BottomBar;