import React, { Component } from 'react';
import {THEMES} from '../common/enums'
import Colors from './COLORS';
import Overlay from './Overlay';
/**
 * Properties:
 * isOpen: boolean => Required
 * onHide: func =>Optional
 * style => Optional
 * theme
 * position: Object, Required. Popover.calculatePosition(event) => use this to calculate
 */

class Popover extends Component {

    render() {
        let {isOpen,style,position,theme,onHide} = this.props;
        if(!isOpen){
            return null;
        }

        return (
            <Overlay onHide={onHide}>
                <div className='animated  fadeIn' style={
                        {...styles.menu, ...style,...position
                            , ...styles[theme || THEMES.LIGHT]}
                    }>
                    {this.props.children}
                </div>
            </Overlay>
        );
    }
}

/**
 * Use this function to capture postion of Popover.
 * e=> Event that triggered popover
 * dist => margin of popover from original object
 */
Popover.calculatePosition = (e,dist=10)=>{
    if(e){
        let pos ={};
        if((window.innerWidth - e.pageX) < e.pageX){
            pos.right = (window.innerWidth -  e.pageX + dist) +'px'
        }else{
            pos.left =  (e.pageX + dist)+'px';
        }

        if((window.innerHeight - e.pageY) < e.pageY){
            pos.bottom = (window.innerHeight -  e.pageY + dist) +'px'
        }else{
            pos.top =  (e.pageY + dist)+'px';
        }
        return pos;
    }
    return {right:'0px',bottom:'0px'}
}

const styles= {
    [THEMES.LIGHT]:{
        backgroundColor:Colors.POPOVER_BG_LIGHT,
        boxShadow: '0 0 0 1px rgba(16,22,26,.1), 0 2px 4px rgba(16,22,26,.2), 0 8px 24px rgba(16,22,26,.2)',
    },
    [THEMES.DARK]:{
        backgroundColor:Colors.POPOVER_BG_DARK,
        boxShadow: '0 0 0 1px rgba(16,22,26,.9), 0 2px 4px rgba(16,22,26,.7), 0 8px 24px rgba(16,22,26,.7)',
    },
    menu:{
        position: 'absolute',   
        width: 200,
        // minHeight: 300,
        zIndex: Overlay.zIndex,
        borderRadius: 3,        
    },
    overlay:{
        height: '100vh',
        width: '100vw',
        zIndex: 1800,
        backgroundColor: 'transparent',
        position: 'absolute',
        top: 0,
        left: 0,
    }
}


export default Popover;