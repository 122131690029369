import {FINISH_SYNC,START_SYNC,UPDATE_DIMENSION} from '../actions/names'
import { getGlobals }  from '../../common/utils'

const globals = getGlobals()


const initialState ={
    isSyncing:false,
    windowDimensions:0
}

export default function reducer(state=initialState,action){
    if(action.type === START_SYNC){
        return {...state,isSyncing:true}
    }
    if(action.type=== FINISH_SYNC){
        return {...state,isSyncing:false}
    }

    if(action.type=== UPDATE_DIMENSION){
        return {...state,windowDimensions:globals.platform.getDimensions()}
    }
    return state
}