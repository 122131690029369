import {
    START_APP,
    GENERIC_ERROR,
    FETCH_ACCOUNT_FINISH,
    FETCH_APP_STATE_FINISH,
    FETCH_SETTINGS_FINISH,
    FETCH_SUBSCRIPTIONS_FINSIH,
    FETCH_ACTIVITIES_FINISH,
    CREATE_FIRST_ACCOUNT,
    FETCH_STORIES_START,
    CREATE_ACCOUNT_FINISH,    
    FETCH_UNREAD_COUNT_FINISH,
    //
    START_SYNC,FINISH_SYNC,
    FETCH_STORIES_FINISH,
    SEARCH_SUBSCRIPTIONS,
    SEARCH_SUBSCRIPTIONS_FINSIH,
    SEARCH_SUBSCRIPTIONS_CLEAR,
    UPDATE_OR_INSERT_SUBSCRIPTION,
    SELECT_STORY,
    TOGGLE_FOLDER,
    SELECT_FOLDER ,
    SELECT_SUBSCRIPTION ,
    CLEAR_STORY,
    CREATE_ACTIVITY,
    REMOVE_ACTIVITY,
    CREATE_ACTIVITY_FINISH,
    TOGGLE_STORY_VIEW,
    SWITCH_ACCOUNT,
    CHANGE_STORIES_FILTER,
    _UPDATE_ACCOUNT_STATE,
    CHANGE_SORT,
    CHANGE_SETTINGS,
    FETCH_MERCURY_ARTICLE_FINISH,
    FETCH_ACCOUNTS_FINISH,
    CREATE_ACCOUNT,REMOVE_ACCOUNT,
    REFRESH_TOKEN_FINISH,
    UPDATE_TOKEN_PROFILE,
    MARK_CURRENT_LIST_AS_READ,
    MARK_CURRENT_LIST_AS_READ_FINISH,
    SEARCH_STORY,
    CLOSE_SEARCH,
    UPDATE_STATUS,
    CLEAR_STATUS,
    HIDE_STATUS,
    UPDATE_SYNC_TIME,
    LOAD_LICENSES_START,
    LOAD_LICENSES_FINISH,
    PURCHASE_PRODUCT_START,
    PURCHASE_PRODUCT_FINISH,
    UPDATE_DIMENSION,
    PREV_STORY,
    NEXT_STORY
}  
from './names';

import {ACTIVITY_NAMES,STORY_VIEW} from '../../common/enums';

export function startApp(isAccountSwitch=false){
    //Invoked on App Start
    return {type:START_APP,payload:{isAccountSwitch}};
}

export function genericError(actionName:any,message:any){
    //Generic Error in Saga
    return {
        type: GENERIC_ERROR,
        payload: {actionName,message}
    };
}

export function fetchAccountFinish(account:any){
    //Account fetched from DB
    return {type:FETCH_ACCOUNT_FINISH,payload: account};
}

export function fetchAppStateFinish(appState:any){
    //Appstate fetched from DB
    return {type:FETCH_APP_STATE_FINISH,payload: appState};
}

export function fetchSettingsFinish(settings:any){
    //Settings is fetched from somewhere, payload is SettingsDoc
    return {type:FETCH_SETTINGS_FINISH,payload: settings};
}

export function fetchSubscriptionsFinish(subscriptions:any){
    return {type:FETCH_SUBSCRIPTIONS_FINSIH, payload:subscriptions}
}

export function fetchActivitiesFinish(activities:any){
    return {type: FETCH_ACTIVITIES_FINISH,payload:activities};
}
export function fetchAccountsFinish(accounts:any){
    return {type:FETCH_ACCOUNTS_FINISH,payload:accounts}
}
export function createFirstAccount(profile:any,type:any,token=null){
    return {type:CREATE_FIRST_ACCOUNT,payload:{profile, token,type}};
}

export function fetchStoriesStart(){
    return {type:FETCH_STORIES_START};
}

export function createAccountFinish(account:any){
    return {type:CREATE_ACCOUNT_FINISH, payload:account};
}


//OLD Start
////////////////////////
////////////////////////
////////////////////////
////////////////////////
////////////////////////


export function createAccount(profile:any,type:any,token=null){
    return {type:CREATE_ACCOUNT,payload:{profile, token,type}};
}


export function startSync(){
    return {type:START_SYNC};
}

export function finishSync(){
    return {type:FINISH_SYNC}
}

export function fetchStoriesFinish(stories:any){
    return {type:FETCH_STORIES_FINISH,payload: stories};
}

export function searchSubscriptions(q:any){
    return {type:SEARCH_SUBSCRIPTIONS,payload:q};
}
export function searchSubscriptionsFinsih(result:any){
    return {type:SEARCH_SUBSCRIPTIONS_FINSIH,payload:result};
}
export function searchSubscriptionsClear(){
    return {type:SEARCH_SUBSCRIPTIONS_CLEAR};
}
export function updateOrInsertSubscription(subscription:any){
    return {type:UPDATE_OR_INSERT_SUBSCRIPTION,payload:subscription}
}

export function toggleFolder(folder:any){
    return {type:TOGGLE_FOLDER,payload: folder}
}

export function selectStory(story:any){
    return {type:SELECT_STORY,payload: story}
}

export function selectFolder(folder:any){
    return {type:SELECT_FOLDER,payload: folder}
}
export function selectSubscription(subscription:any){
    return {type:SELECT_SUBSCRIPTION,payload: subscription}
}

export function clearStory(){
    return {type: CLEAR_STORY};
}



function _createActivity(activityName:any,payload:any){
    let a ={
        accountId:null, //Should be added later
        activityName:activityName,
        payload:payload,
        time:Date.now()+''
    };
    return a;
}
export function createReadActivity(storyId:any){
    let activity =  _createActivity(ACTIVITY_NAMES.READ,storyId);
    return {payload: activity,type:CREATE_ACTIVITY}
}
export function createBookmarkActivity(storyId:any){
    let activity =  _createActivity(ACTIVITY_NAMES.BOOKMARK,storyId);
    return {payload: activity,type:CREATE_ACTIVITY}
}
export function createFavActivity(storyId:any){
    let activity =  _createActivity(ACTIVITY_NAMES.FAV,storyId);
    return {payload: activity,type:CREATE_ACTIVITY}
}
export function removeActivity(activity:any){
    return {payload: activity,type:REMOVE_ACTIVITY}
}

export function createActivityFinish(activity:any){
    return {type:CREATE_ACTIVITY_FINISH, payload:activity}
}

function _changeStoryView(newViewName:any){
    return {type:TOGGLE_STORY_VIEW, payload:newViewName};
}

export function toggleWebView(){
    return _changeStoryView(STORY_VIEW.WEB)
}
export function toggleCleanView(){
    return _changeStoryView(STORY_VIEW.CLEAN)
}

export function switchAccount(account:any){
    return {payload:account, type:SWITCH_ACCOUNT}
}

export function changeStoriesFilter(filter:any){
    return {payload:filter, type:CHANGE_STORIES_FILTER}
}

export function _updateAccountState(){
    //this is internal action should not be called from Components
    return {type:_UPDATE_ACCOUNT_STATE}
}

export function changeSort(newValue:any){
    return {type: CHANGE_SORT,payload: newValue};
}

export function changeSettings(key:any,value:any,parseToInt=false){
    if(parseToInt){
        value = parseInt(value,10);
    }
    return {type: CHANGE_SETTINGS, payload:{key,value}};
}

export function changeLanguage(languageKey:any){
    return changeSettings('language',languageKey);
}

export function fetchMercuryArticleFinish(mercuryArticle:any){
    return {type:FETCH_MERCURY_ARTICLE_FINISH,payload:mercuryArticle};
}


export function fetchUnreadCountFinish(data:any){
    return{payload:data,type:FETCH_UNREAD_COUNT_FINISH}
}

export function refreshTokenFinish(token:any){
    return {
        type: REFRESH_TOKEN_FINISH,
        payload: token
    }
}

export function updateTokenProfile(profile:any,token:any){
    //This action is triggered when user creates a new account.
    //and we find that newly created account already existed.
    //so we just update the profile and token of that account
    return {
        type:UPDATE_TOKEN_PROFILE,
        payload:{profile,token}
    }
}

export function removeAccount(account:any){
    return {
        type:REMOVE_ACCOUNT,payload:account
    }
}

//Indicates that you want to mark current list as read.
export function markCurrentStoriesAsRead(){
    return {type:MARK_CURRENT_LIST_AS_READ}
}
export function markCurrentStoriesAsReadFinish(activities:any){
    return {type:MARK_CURRENT_LIST_AS_READ_FINISH,payload:activities}
}

export function searchStory(q:any){
    return {type:SEARCH_STORY, payload:q}
}

export function closeSearch(){
    return {type:CLOSE_SEARCH}
}

let _status_counter=0;
export function updateStatus(id:any,type:any,text:any,hideAfter:any){
    //id:string or null (for auto Id)
    //hideAfter = time in seconds
    //All statuses will be hidden after 5 minutes
    if(!id){
        _status_counter++;
        id= `${Date.now()}_${_status_counter}`
    }
    return {
        type:UPDATE_STATUS,
        payload:{id,type,text,hideAfter:hideAfter,hidden:false}
    }
}

export function clearStatus(id:any){
    return {
        type:CLEAR_STATUS,
        payload:id
    }
}

export function hideStatus(id:any){
    return {
        type:HIDE_STATUS,
        payload:id
    }
}

//Update sync time of account.
export function updateSyncTime(syncTimeObject:any){
    return {type:UPDATE_SYNC_TIME,payload:syncTimeObject}
}

export function loadLicenseStart(){
    return {type:LOAD_LICENSES_START}
}

export function loadLicenseFinish(products:any){
    return {
        type:LOAD_LICENSES_FINISH,
        payload: products
    }
}

export function purchaseProductStart(product:any){
    return {type:PURCHASE_PRODUCT_START,payload: product}
}

export function purchaseProductFinish(){
    return {type: PURCHASE_PRODUCT_START};
}

export function updateDimensions(){
    return {type:UPDATE_DIMENSION}
}

export function nextStory(){
    return {type:NEXT_STORY,payload:1}
}

export function prevStory(){
    return {type:PREV_STORY,payload:-1}
}