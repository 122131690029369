import React, { Component } from 'react';
import Ionicon from 'react-ionicons';
import {getProduct,isPremium} from '../utils';
import ProductView from './ProductView'
import {PRODUCTS_ENUM} from '../../common/enums'
import {purchaseProductStart} from '../../redux/actions/actionCreators'
import {connect} from 'react-redux';
import {css,StyleSheet} from 'aphrodite'

const fontSize = "30px";

class Catalogue extends Component {

    handleKeyPress =(e)=>{
        if (e.keyCode == 27) { 
            this.props.toggleCatalogue();
        }
    }

    componentDidMount(){
        window.addEventListener('keyup', this.handleKeyPress)
    }

    componentWillUnmount(){
        window.removeEventListener('keyup',this.handleKeyPress);
    }

    purchaseProduct =(product)=>{
        this.props.purchaseProductStart(product);
        this.props.toggleCatalogue();
    }

    render() {
        let {loaded,products} = this.props.license;
        if(!loaded){
            return null;
        }
        
        return (<div className={css(styles.container)}>   
            <div style={{
                padding:10,
                width:'100%',
                display:'flex',
                justifyContent:'center'
            }}>
                <div style={{
                    width:700,
                    maxWidth:'100%'
                }}>
                    <div className={css(styles.title)}>Purchase Now</div>
                    <div className={css(styles.productsContainer)}>
                        <ProductView purchaseProduct={this.purchaseProduct} product={getProduct(products,PRODUCTS_ENUM.ADDFREE)} />
                        <ProductView purchaseProduct={this.purchaseProduct} product={getProduct(products,PRODUCTS_ENUM.UNLIMITED)} />
                    </div>
                    <div className={css(styles.closeButton)} onClick={this.props.toggleCatalogue}>
                        <Ionicon color="#ffffff" icon="ion-android-close" fontSize={fontSize} />
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = StyleSheet.create({
    container:{
        position:'absolute',
        bottom:0,
        right:0,
        top:0,
        left:0,
        width:'100vw',
        'min-height':'100%',
        backgroundColor:'#161617',
        zIndex:2,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'column'
    },
    title:{
        fontSize:45,
        color:'#fff',
        marginBottom:50,
        textAlign:'center'
    },
    productsContainer:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        '@media (max-width: 650px)':{
            flexDirection:'column',
            alignItems:'center'
        }
    },
    closeButton:{
        width:50,
        height:50,
        position:'absolute',
        top:0,
        right:0,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer'
    }
});

const mapStateToProps = (state, ownProps) => {
    return {
        license: state.license
    }
}

export default connect(mapStateToProps, {purchaseProductStart})(Catalogue)