import React, {Component} from 'react';
import {StyleSheet} from 'aphrodite';
import Ionicon from 'react-ionicons';
import {startSync} from '../redux/actions/actionCreators';
import {connect} from 'react-redux'
import {tCss} from './utils'
import COLORS from './COLORS'

const fontSize = "20px";

class AppToolbar extends Component {
    render() {
        let {startSync,toggleSettings,toggleSubscriptions,settings,toggleAccounts,ui} = this.props;
        let css2 = tCss(settings.theme)(styles);
        let colClassName =  css2(['col']);
        return (
            <div className={css2(['container'])}>
                <div className={colClassName} onClick={toggleSettings}>
                    <Ionicon icon="ion-gear-a" fontSize={fontSize} className = {css2(['iconClass'])}/>
                </div>
                <div className={colClassName} onClick={toggleAccounts}>
                    <Ionicon icon="ion-person" fontSize={fontSize} className = {css2(['iconClass'])}/>
                </div>
                
                <div className={colClassName} onClick={toggleSubscriptions}>
                    <Ionicon icon="ion-android-add" fontSize={fontSize} className = {css2(['iconClass'])}/>
                </div>
                <div className={colClassName} onClick={startSync}>
                    <Ionicon 
                        icon="ion-ios-loop-strong" 
                        fontSize={fontSize} 
                        className = {css2(['iconClass'])  +  `${ui.isSyncing?' rotate':''}`}
                    />
                </div>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flex: 1,
        padding:'0px 10px',
    },
    col: {
        width: 40,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flex: 1,
        cursor:'pointer',
        opacity:'0.7',
        ':hover':{
            opacity:'1.0'
        },
    },
    iconClass_LIGHT:{
        fill:COLORS.BLACK4
    },
    iconClass_DARK:{
        fill:COLORS.WHITE_TEXT2
    }
});

const mapStateToProps = (state, ownProps) => {
    return {
        settings: state.settings.settings,
        ui:state.ui
    }
}

export default connect(mapStateToProps,{startSync})(AppToolbar)
