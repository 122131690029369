import React from 'react';
import {THEMES} from '../../common/enums'
import { StyleSheet} from 'aphrodite'
import {getFormattedTime,tCss} from '../utils'

const Classic = ({
    renderImage,
    story,
    selectStory,
    isSelected,
    subscription,
    isPopular,
    theme,
    isRead
}) => {
    let css2=  tCss(theme)(styles);
    let title = story.title;
    if(title.length>103){
        title =  title.slice(0,100) +"..."
    }
    return <div
        onClick={() => {
        selectStory(story)
    }}
    className ={css2('container',[{b:isSelected,t:'containerSelected',f:''}])}
    >
        <div className={css2('title',[{b:isRead,t:'seen_text',f:''}])}>{title}</div>
        <div className={css2('info',[{b:isRead,t:'seen_text',f:''}])}>
            <span>{subscription.title}</span>&nbsp;
            <span>{getFormattedTime(story.published)}</span>
        </div>
    </div>
}


const styles= StyleSheet.create({
    container:{
        height: 80,
        display: 'flex',
        cursor:'pointer',
        flexDirection:'column',
        padding:'10px 10px 0px 10px',
        fontWeight: 300,
        borderBottom:'1px solid rgba(0,0,0,0.2)',
    },
    [`containerSelected_${THEMES.LIGHT}`]: {
        backgroundColor: `rgba(0,0,0,0.1)`,
    },
    [`containerSelected_${THEMES.DARK}`]: {
        backgroundColor: `rgba(0,0,0,0.3)`,
    },
    title:{
        fontWeight: 400,
    },
    [`seen_text_${THEMES.LIGHT}`]:{
        color:'#999',
        fontWeight: 300,
    },
    [`seen_text_${THEMES.DARK}`]:{
        color:'#555',
        fontWeight: 300,
    },
    info:{
        fontSize: '0.85em',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        display: 'flex',
        alignItems: 'flex-end',
        marginTop: 'auto',
        paddingBottom: 10


    },
    [`info_${THEMES.LIGHT}`]:{color: '#555'},
    [`info_${THEMES.DARK}`]:{color: '#999'},
});

export default Classic;