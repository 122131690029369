import React, { Component } from 'react';
import {tCss} from '../utils'
import {createAccount, switchAccount,removeAccount} from '../../redux/actions/actionCreators';
import { getGlobals }  from '../../common/utils'
import {connect} from 'react-redux';
import styles from './styles'
import AccountsList from './AccountsList'
import AccountListRemove from './AccountListRemove'
import ServicesList from './ServicesList'
import LocalForm from './LocalForm'
import Popover from '../Popover'

const globals = getGlobals()


let screens={
    AccountsList,AccountListRemove,ServicesList,LocalForm
}

class AccountsPopover extends Component {
    state= {
        screenName:'AccountsList'
    }
    changeScreen =(screenName)=>{
        this.setState({
            screenName
        });        
    }
    handleRemove =(account)=>{
        this.props.removeAccount(account);
    }
    handleSwicthAccount =(account)=>{
        if(this.props.account.id != account.id){
            this.props.switchAccount(account);
        }
    }

    handleStartLoginFlow =(service)=>{
        if(service.getKey() ===  globals.services.local.getKey()){
            this.changeScreen('LocalForm')
        }else{
            service.startLoginFlow({
                onCompleted: this.onCreateAccount
            },null,globals.platform);
        }
    }
    onCreateAccount = (profile,type,token)=>{
        this.props.createAccount(profile,type,token);
        this.changeScreen('AccountsList')
    }
    componentWillReceiveProps(nextProps){
        if(!nextProps.isOpen){
            this.setState({
                screenName:'AccountsList'
            });
        }
    }
    
    render() {
        
        let {isOpen} = this.props;
        if(!isOpen){
            return null;
        }

        let {settings,textStrings,accounts,toggle,position,account} = this.props;
        let css2 = tCss(settings.theme)(styles);
        let Screen = screens[this.state.screenName];
        return (<Popover 
                style={{
                    // maxHeight:300,
                }}
                isOpen={isOpen} onHide={toggle} position={position} theme={settings.theme}>
                <Screen 
                    changeScreen ={this.changeScreen}
                    textStrings={textStrings}
                    accounts={accounts}
                    removeAccount={this.handleRemove}
                    switchAccount={this.handleSwicthAccount}
                    css2={css2}
                    startLoginFlow={this.handleStartLoginFlow} 
                    createAccount={this.onCreateAccount}
                    account={account}
                />
            </Popover>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        accounts: state.accounts,
        account:state.account,
        settings:state.settings.settings,
        textStrings:state.textStrings
    }
}

export default connect(mapStateToProps,{createAccount,switchAccount,removeAccount})(AccountsPopover)