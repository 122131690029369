import React, { Component } from 'react';
import StoryView from '../StoryView'
import StoryToolbar from '../StoryToolbar';
import Wallpaper from '../Wallpaper';
import RightPanelAd  from '../Monetisation/RightPanelAd'
import {nextStory,prevStory} from '../../redux/actions/actionCreators';
import {connect} from 'react-redux'
import { getGlobals}  from '../../common/utils'
const globals = getGlobals()

class RightPanel extends Component {
    _listening= false //we will respond to keydown events
    _scrollTimerHandler = null
    state ={
        scrollPosition:0
    }
    
    scrollToStoryTop =()=>{
        this.refs.storyViewContainer.scrollTop=0;
    }
    
    //This handles keypress event
    _handleKeyPress =(e)=>{
        //We are interested in right and left arrow keys only,  
        //when listening is swicted on
        if(this._listening && (e.keyCode === 37 || e.keyCode === 39)){
            e.preventDefault(); 
            //Stop any default behavior
            //call storyEvent based on keyCode
            if(e.keyCode === 37){                
                this.props.prevStory();
            }
            if(e.keyCode === 39){
                this.props.nextStory();
            }
            //Focus on story Element so that when user press keydown or keyup 
            //he can scroll the article
            this.refs.storyViewContainer.focus();
        }
    }

    _handleClick =(e)=>{
        //this is when storyview gets the focus
        e.stopPropagation(); //stop event bubble
        this._listening=true
    }
    
    _handleWindowClick = ()=>{
        //this is sort of blur.
        //This event is reached only when click happened outside the story box
        this._listening=false
    }

    componentWillUnmount(){
        window.removeEventListener('keydown',this._handleKeyPress);
        window.removeEventListener('click',this._handleWindowClick);
        clearTimeout(this._scrollTimerHandler);
    }
    
    componentDidMount(){
        window.addEventListener('keydown',this._handleKeyPress);
        window.addEventListener('click',this._handleWindowClick);
    }

    handleScroll =(e)=>{
        clearTimeout(this._scrollTimerHandler);
        this._scrollTimerHandler = setTimeout(()=>{
            this.setState({
                scrollPosition:this.refs.storyViewContainer.scrollTop
            });
        },100)
    }

    render() {
        let {css2,story,backButton,hidden} = this.props;
        let {platform} = globals

        if(hidden){
            return null;
        }
        if(!story){
            return <div className={ css2(['right','panel'])}>
                <Wallpaper />
            </div>
        }
        return (
            <div className={css2(['right','panel'])}
                onClick ={this._handleClick}
            >
                <div className={css2(['bar'])}>
                    <StoryToolbar backButton={backButton} />
                </div>
                <div   
                    onScroll={this.handleScroll}    
                    ref="storyViewContainer" className={css2(['content'])}>
                    <StoryView 
                        scrollPosition={this.state.scrollPosition} 
                        scrollToStoryTop={this.scrollToStoryTop} 
                    />                    
                </div>
                {/**For mac we don't display Ad-Panel */}
                {!platform.isMac() && <RightPanelAd story={story} />}
            </div> 
        );
    }
}

export default connect(null, {nextStory,prevStory})(RightPanel)