export const CREATE_FIRST_ACCOUNT = 'CREATE_FIRST_ACCOUNT';
export const START_APP = 'START_APP';
export const FETCH_ACCOUNT_FINISH = 'FETCH_ACCOUNT_FINISH';
export const FETCH_APP_STATE_FINISH = 'FETCH_APP_STATE_FINISH';
export const FETCH_SETTINGS_FINISH = 'FETCH_SETTINGS_FINISH';
export const FETCH_SUBSCRIPTIONS_FINSIH = 'FETCH_SUBSCRIPTIONS_FINSIH';
export const FETCH_ACTIVITIES_FINISH= 'FETCH_ACTIVITIES_FINISH';
export const FETCH_ACCOUNTS_FINISH = 'FETCH_ACCOUNTS_FINISH';
export const FETCH_STORIES_START = 'FETCH_STORIES_START';
export const FETCH_UNREAD_COUNT_FINISH ='FETCH_UNREAD_COUNT_FINISH';
//OLD

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';
export const CREATE_ACCOUNT_FINISH = 'CREATE_ACCOUNT_FINISH';
export const GENERIC_ERROR = 'GENERIC_ERROR';
export const START_SYNC = 'START_SYNC';
export const FINISH_SYNC = 'FINISH_SYNC';
export const FETCH_STORIES_FINISH = 'FETCH_STORIES_FINISH';
export const SEARCH_SUBSCRIPTIONS = 'SEARCH_SUBSCRIPTIONS';
export const SEARCH_SUBSCRIPTIONS_FINSIH = 'SEARCH_SUBSCRIPTIONS_FINSIH';
export const SEARCH_SUBSCRIPTIONS_CLEAR = 'SEARCH_SUBSCRIPTIONS_CLEAR';
export const UPDATE_OR_INSERT_SUBSCRIPTION = 'UPDATE_OR_INSERT_SUBSCRIPTION';

//Expand a folder if closed and vice vera
export const TOGGLE_FOLDER = 'TOGGLE_FOLDER';
export const SELECT_FOLDER = 'SELECT_FOLDER';
export const SELECT_SUBSCRIPTION = 'SELECT_SUBSCRIPTION';
export const SELECT_STORY = 'SELECT_STORY';
export const CLEAR_STORY = 'CLEAR_STORY'; //clear story from story reducer

export const CREATE_ACTIVITY ='CREATE_ACTIVITY';
export const CREATE_ACTIVITY_FINISH ='CREATE_ACTIVITY_FINISH';
export const REMOVE_ACTIVITY ='REMOVE_ACTIVITY';
export const TOGGLE_STORY_VIEW ='TOGGLE_STORY_VIEW';
export const SWITCH_ACCOUNT ='SWITCH_ACCOUNT';
export const CHANGE_STORIES_FILTER ='CHANGE_STORIES_FILTER';

//this is internal action should not be called from Components
export const _UPDATE_ACCOUNT_STATE ='_UPDATE_ACCOUNT_STATE';

export const CHANGE_SORT ='CHANGE_SORT';
export const CHANGE_SETTINGS ='CHANGE_SETTINGS';
export const FETCH_MERCURY_ARTICLE_FINISH ='FETCH_MERCURY_ARTICLE_FINISH';
export const REFRESH_TOKEN_FINISH = 'REFRESH_TOKEN_FINISH'
export const UPDATE_TOKEN_PROFILE = 'UPDATE_TOKEN_PROFILE'

export const MARK_CURRENT_LIST_AS_READ ='MARK_CURRENT_LIST_AS_READ';
export const MARK_CURRENT_LIST_AS_READ_FINISH ='MARK_CURRENT_LIST_AS_READ_FINISH';
export const SEARCH_STORY = 'SEARCH_STORY';
export const CLOSE_SEARCH = 'CLOSE_SEARCH';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const CLEAR_STATUS = 'CLEAR_STATUS';
export const HIDE_STATUS = 'HIDE_STATUS';
export const UPDATE_SYNC_TIME = 'UPDATE_SYNC_TIME';
export const LOAD_LICENSES_START = 'LOAD_LICENSES_START';
export const LOAD_LICENSES_FINISH = 'LOAD_LICENSES_FINISH';
export const PURCHASE_PRODUCT_START ='PURCHASE_PRODUCT_START';
export const PURCHASE_PRODUCT_FINISH ='PURCHASE_PRODUCT_FINISH';
export const UPDATE_DIMENSION ='UPDATE_DIMENSION';
export const PREV_STORY  ='PREV_STORY';
export const NEXT_STORY  ='NEXT_STORY';