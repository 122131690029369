import React from 'react'
import {css,StyleSheet} from 'aphrodite'
import {convertRGBAToCssString,changeColorShade, getGlobals} from '../common/utils'

const globals = getGlobals()
class SplashScreen extends React.Component {
    state ={
            width:1 //Width of progressbar
    }

    timer = null;

    componentDidMount(){
        globals.platform.hideTitleBar();//Gives a feeling of full screen

        //Loading time is usually upto 2 seconds
        this.timer = setInterval(()=>{
            let width = this.state.width+1;
            if(width>99){
                width = 100;
            }
            this.setState({width})
        },104)//14
    }
    componentWillUnmount(){
        clearInterval(this.timer)
    }

    render() {
        let {platform} = globals
        return (<div style={{
            backgroundColor:convertRGBAToCssString(platform.getAccentColorAsRGBA()),
        }} className={css(styles.container)}>
                    <div style={{
                        textAlign:'center'
                    }}>VEEN</div>
                    <div style={{
                        background: convertRGBAToCssString(changeColorShade(platform.getAccentColorAsRGBA(),-30)),
                    }} className={css(styles.progressOuter)}>
                        <div className={css(styles.progressInner)} style={{
                            width:`${this.state.width}%`
                        }}></div>
                    </div>
                </div>
        );
    }
}


// 

const styles = StyleSheet.create({
    container:{
        color:'#fff',
        padding:'0px 10px',
        display:'flex',
        flex:1,
        height:'100vh',
        width:'100vw',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        fontSize:60,
        '@media (max-width: 500px)': {
            fontSize:40,
        }
    },
    progressOuter:{
        maxWidth: 400,
        height: 2,
        marginTop: 20,
        borderRadius: 1,
        width:'100%',
        '@media (max-width: 500px)': {
            maxWidth: 250,
        }
    },
    progressInner:{
        background: 'rgba(255,255,255,0.9)',
        height: '100%',
        borderRadius: 1
    }
});
export default SplashScreen;