/**
 * Account Table
 * ---------
 * create table (id,subscriptionsArray)
 *
 * Stories Table
 * ---------
 * create table(id, title, subId)
 * Activity Table
 * ---------
 * create table (id, accountId, payload, activityName, time)
 * payload = can be storyId or subscrition id
 * activityName = read, fav, subscribe
 * 
 */


 import lf from 'lovefield';

const DB_NAME = 'veenreader';
const DB_VERSION = 1;
export const STORE_TYPES = {
    memory:'MEMORY',
    indexeddb:'INDEXED_DB',
    websql:'WEB_SQL'
}

/**Table Names */
const 
    ACCOUNTS_TABLE_NAME= "accounts",
    APP_STATE_TABLE_NAME= "appstate",
    ACTIVITY_TABLE_NAME="activity",
    STORIES_TABLE_NAME=  "stories",
    MERCURY_ARTICLES_TABLE_NAME= "mercuryarticles"
    ;

//Queries file will use these singletons.
//They are filled inside setup function.
export const singletons ={
    ACCOUNTS_TABLE: null,
    APP_STATE_TABLE: null,
    ACTIVITY_TABLE:null,
    STORIES_TABLE:  null,
    MERCURY_ARTICLES_TABLE: null,
    DB : null
};

/**Returns you a store type.*/
function getStoreType(type){
    return lf.schema.DataStoreType[type];
}

/**
 * Utilty function that returns a table schema
 */
function getSchema(db,tableName){
    return db.getSchema().table(tableName);
}

//All queries should be run after invoking this function
//This function setups database, does following
//1. Create schema
//2. Fill singletons
export function setup(storeType){
    //Schema Builer
    let schemaBuilder = lf.schema.create(DB_NAME, DB_VERSION); 
    
    //store type
    let _storeType = getStoreType(storeType);

    //Accounts Table
    schemaBuilder.createTable(ACCOUNTS_TABLE_NAME)
    
    .addColumn('id', lf.Type.STRING)
    .addColumn('type', lf.Type.STRING)
    .addColumn('created_at', lf.Type.INTEGER) 
    .addColumn('token', lf.Type.OBJECT)  //nullable
    .addColumn('profile', lf.Type.OBJECT)  //nullable

    //subscriptions in array format
    .addColumn('subscriptions', lf.Type.OBJECT) 
    .addColumn('syncTime', lf.Type.OBJECT) 
    
    //State of user
    .addColumn('openFolderIds', lf.Type.OBJECT) 
    .addColumn('selectedFolderId', lf.Type.STRING) //nullable
    .addColumn('selectedSubscriptionId', lf.Type.STRING) //nullable
    .addColumn('selectedStoryId', lf.Type.STRING) //nullable
    .addColumn('storyFilter', lf.Type.STRING) //nullable, filter for story List
    .addColumn('sort', lf.Type.OBJECT) //nullable
    
    .addNullable(['sort','storyFilter','selectedFolderId','selectedSubscriptionId','selectedStoryId','token','profile'])
    .addPrimaryKey(['id']);

    //App State Table//1
    schemaBuilder
    .createTable(APP_STATE_TABLE_NAME)
    .addColumn('id', lf.Type.INTEGER)
    .addColumn('activeAccountId', lf.Type.STRING)
    .addColumn('settings', lf.Type.OBJECT)
    .addPrimaryKey(['id'],true)
    .addForeignKey('fk_AccountId', {
      local: 'activeAccountId',
      ref: ACCOUNTS_TABLE_NAME + '.id'
    });    

    //Story Table
    schemaBuilder
    .createTable(STORIES_TABLE_NAME)
    .addColumn('id', lf.Type.STRING)
    .addColumn('title', lf.Type.STRING)
    .addColumn('published', lf.Type.INTEGER)
    .addColumn('downloadedTime', lf.Type.INTEGER)
    .addColumn('subscriptionId', lf.Type.STRING)
    .addColumn('url', lf.Type.STRING)
    .addColumn('payload',lf.Type.OBJECT)
    .addColumn('popularity',lf.Type.INTEGER)
    .addPrimaryKey(['id'])
    .addNullable(['published'])
    ;

    //Activity Table
    //id, accountId, payload, activityName, time
    schemaBuilder
    .createTable(ACTIVITY_TABLE_NAME)
    .addColumn('id', lf.Type.INTEGER)
    .addColumn('accountId', lf.Type.STRING)
    .addColumn('payload', lf.Type.STRING)
    .addColumn('activityName', lf.Type.STRING)
    .addColumn('time', lf.Type.STRING)
    .addPrimaryKey(['id'],true);

    //Mercury Articles
    schemaBuilder
    .createTable(MERCURY_ARTICLES_TABLE_NAME)
    .addColumn('id', lf.Type.INTEGER)
    .addColumn('storyId', lf.Type.STRING)
    .addColumn('title', lf.Type.STRING)
    .addColumn('url', lf.Type.STRING)
    .addColumn('contentText', lf.Type.STRING)
    .addColumn('author', lf.Type.STRING)
    .addColumn('dek', lf.Type.STRING)
    .addColumn('mercuryUrl', lf.Type.STRING) //this resolved url from Mercury
    .addColumn('payload', lf.Type.OBJECT)
    .addColumn('downloadedTime', lf.Type.STRING)
    .addColumn('summaryText', lf.Type.STRING)
    .addColumn('word_count',lf.Type.INTEGER)
    .addNullable(['contentText','dek','author','summaryText'])
    .addPrimaryKey(['id'],true);

    //Connect and fill singletons
    return schemaBuilder
          .connect({storeType: _storeType})
          .then(function(db){
            singletons.DB  = db;
            singletons.ACCOUNTS_TABLE = getSchema(db, ACCOUNTS_TABLE_NAME);
            singletons.APP_STATE_TABLE = getSchema(db, APP_STATE_TABLE_NAME);
            singletons.STORIES_TABLE = getSchema(db, STORIES_TABLE_NAME);
            singletons.ACTIVITY_TABLE = getSchema(db, ACTIVITY_TABLE_NAME);
            singletons.MERCURY_ARTICLES_TABLE = getSchema(db, MERCURY_ARTICLES_TABLE_NAME);
          })
}
