import React, { Component } from 'react';
import Menu from './Menu';
import Popover from '../Popover'
import {tCss} from '../utils'
import {StyleSheet}  from  'aphrodite'
import {THEMES} from '../../common/enums'
import COLORS from '../COLORS'

class Subscription extends Component {
    render() {
        let {subscription,position,theme,toggleMenu,open,allCategories,categories,handleSubscribe,textStrings}  = this.props;
        let css2 =  tCss(theme)(styles);
        return (
            <div className={css2('container')}>
                <div className={css2('left')}>
                    <div className={css2('row')}>
                        <img className={css2('img')} src={subscription.iconUrl} alt=''  />
                        {this.props.i}
                        {subscription.title}
                    </div>
                    <div className={css2(['row','lightText'])}>{subscription.website}</div>
                    <div className={css2(['row','lightText'])}>{subscription.subscribers} Subscribers</div>
                    <div className={css2(['row','lightText'])}>{subscription.velocity} items per week</div>
                </div>

                <div className={css2('right')}>
                    <button  className={css2('button')} onClick={(e)=>toggleMenu(subscription.feedId,Popover.calculatePosition(e))}>
                        {textStrings.SUBSCRIBE}
                    </button>
                </div>                

                <Popover 
                    style={{
                        minHeight:350,
                        width:300
                    }}
                    theme={theme}
                    isOpen={open} 
                    position={position}  
                    onHide={(e)=>toggleMenu(subscription.feedId)}>
                        <Menu     
                            categories={categories} 
                            allCategories={allCategories} 
                            handleSubscribe ={handleSubscribe}
                            subscription={subscription} 
                            theme={theme}
                            textStrings={textStrings}
                            toggleMenu={toggleMenu}
                        />
                </Popover>

            </div>
        );
    }
}

const styles= StyleSheet.create({
    container:{
        padding:'10px 0px',
        display:'flex'
    },
    [`container_${THEMES.DARK}`]:{
        borderBottom:'1px solid rgba(0,0,0,0.4)',
    },
    [`container_${THEMES.LIGHT}`]:{
        borderBottom:'1px solid rgba(0,0,0,0.2)',
    },
    left:{
        flex:1,
    },
    right:{
        width:100,
    },
    row:{
        display:'flex',
        alignItems:'center',
        padding:'5px 0px'
    },
    img:{
        width:30,
        height:30,
        borderRadius:15,
        marginRight:10
    },
    lightText:{
        fontWeight: 300,
        fontSize: '0.85em',
        color: '#777',
    },
    [`lightText_${THEMES.LIGHT}`]:{
        
    },
    [`lightText_${THEMES.DARK}`]:{

    },
    button:{
        padding:10,
        backgroundColor:COLORS.SUCCESS,
        border:'none',
        color:'#fff',
        fontSize:'1em',
        cursor:'pointer'
    }
});

export default Subscription;