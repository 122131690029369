import {find,pick} from 'lodash';
import {NAMES} from '../common/enums'
import { getGlobals }  from '../common/utils'

const globals = getGlobals()

export function accountDbToDoc(account){
    if(account && !account.syncTime){
        account.syncTime={};
    }
    return account;
}

export function accountDocToDb(account){
    return account;
}

export function appStateDbToDoc(appstate){
    return appstate;
}

export function appStateDocToDb(appState){
    return appState;
}

/**
 * Converts subscriptionsDoc to folders
 * @param subscriptions 
 */
export function subscriptionDocsToFolders(subscriptions){
    let folders =[];
    let unCategorisedCategory = {
        id:NAMES.UNCATEGORISED_FOLDER_ID,
        label:'Uncategorised' //Todo:this label should come from strings
    };
    for(let i=0;i<subscriptions.length;i++){
        let sub = subscriptions[i];
        let categories = sub.categories;
        if(categories.length ===0){
            categories = [unCategorisedCategory]
        }
        for(let j=0;j<categories.length;j++){
            let category = categories[j];
            let folder = find(folders,{id:category.id});
            if(!folder){
                folder ={
                    id:category.id,
                    label:category.label,
                    subscprions:[],
                };
                folders.push(folder);
            }
            folder.subscprions.push(sub);
        }
    }
    return folders;
}

export function subscriptionHTTPToDoc(type,subscription){
    const {services} = globals
    if(type=== services.feedly.getKey() || type=== services.local.getKey()){
        let s=  pick(subscription,['id','title','iconUrl','visualUrl','website','velocity','subscribers','categories']);
        s.url = (subscription.id || subscription.feedId).substr(5);
        return s;
    }
}

export function subscriptionDocToDb(subscription){
    return subscription;
}

export function storyHTTPToDoc(type,story){
    const {services} = globals
    if(type === services.feedly.getKey() || type === services.local.getKey()){
        let fs = {
            id: story.id,
            title: story.title || '',
            published: story.published+'',
            downloadedTime: Date.now()+'',
            subscriptionId: story.origin.streamId,
            url: (story.alternate[0]).href,
            popularity:Math.floor((story.engagementRate || 0)*100),
        };
        let payload = {
            summaryText: (story.content || story.summary || {}).content,
            summaryDir : (story.summary || {direction: 'ltr'}).direction,
            contentText : (story.content || {}).content,
            contentDir : (story.content || {direction: 'ltr'}).direction,                    
            author: story.author,
            thumbnail: story.thumbnail,
            visual : story.visual,
            enclosure:story.enclosure,
            engagement: story.engagement || 0,
            engagementRate: story.engagementRate || 0,
            cdnAmpUrl: story.cdnAmpUrl,                             
        }
        fs.payload =payload;
        return fs;
    }
}

export function storyDocToDB(story){
    return story;    
}

export function mercuryArticleHTTPToDoc(story,rawArticle){
    let formattedArticle ={
        author:rawArticle.author,
        contentText:rawArticle.content,
        dek:rawArticle.dek,
        summaryText:rawArticle.excerpt,
        downloadedTime:Date.now(),
        url:story.url,
        mercuryUrl:rawArticle.url,
        storyId:story.id,
        title:rawArticle.title,
        payload:{
            contentDir: rawArticle.direction,
            lead_image_url:rawArticle.lead_image_url,
            next_page_url:rawArticle.next_page_url,
            rendered_pages:rawArticle.rendered_pages,
            total_pages:rawArticle.total_pages,
        },
        word_count:rawArticle.word_count || (rawArticle.content || '').length
    };
    return formattedArticle;
}

export function mercuryArticleDocToDb(article){
    return article;
}

export function subscriptionsDocToDBProp(subscriptions){
    return subscriptions;
}

export function mercuryDbToDoc(article){
    return article;
}

//
export function createActivity(activityName,payload,accountId){
    let a={
        accountId:accountId, //Should be added later
        activityName:activityName,
        payload:payload,
        time:Date.now()+''
    };
    return a;
}