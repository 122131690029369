import React, { Component } from 'react';
import Image from '../images/bg.jpg'
import {connect} from 'react-redux'
// import services from '../../services'
import Topbar from './Topbar'
import BottomBar from './BottomBar'
import {Middlebar, StatusBar} from './MiddleBar';
import {createFirstAccount,changeLanguage} from '../../redux/actions/actionCreators';
import Help from './Help'
import Languages from './Language'
import { getGlobals }  from '../../common/utils'

const globals = getGlobals()

class Welcome extends Component {

    state ={
        isWaiting:false,
        status:null,
        fullName:'',
    }

    componentDidMount(){
        globals.platform.hideTitleBar();
    }

    //1
    hanldeFullNameChange =(e)=>  {
        this.setState({
            fullName: e.target.value
        })
    }
    
    //2
    toggleWaiting =(status=null)=>{
        this.setState({
            isWaiting: !this.state.isWaiting,
            status
        });
    }

    //3
    onStatusUpdate = (msg)=>{
        this.setState({
            status:this.props.textStrings[msg] +'...'
        });
    }
    
    //4
    onCompleted= (profile,type,token)=>{
        this.props.createFirstAccount(profile,type,token);
    }

    //5
    onButtonClick =(service)=>{
        this.toggleWaiting(`Loging with ${service.getKey()}....`);
        service.startLoginFlow({
            onStatusUpdate: this.onStatusUpdate,
            onCompleted: this.onCompleted,
            onCancel: this.onCancel,
            onError: this.onError,
        },this.state.fullName,globals.platform);
    }

    onCancel= (msg)=>{
        this.toggleWaiting();
    }
    onError = (msg,e)=>{
        console.log(msg);
        console.log(e);
    }

    render() {
        let {textStrings,ui,changeLanguage}  = this.props;
        return (
            <div style={styles.container}>
                <Topbar />
                {
                    this.state.isWaiting? 
                    <StatusBar status={this.state.status || textStrings.WAIT}  /> :
                    <Middlebar textStrings={textStrings} 
                    hanldeFullNameChange={this.hanldeFullNameChange} 
                    onButtonClick={this.onButtonClick} />
                }
                
                {/*
                <Middlebar textStrings={textStrings} 
                hanldeFullNameChange={this.hanldeFullNameChange} 
                onButtonClick={this.onButtonClick} />
                */}

                <BottomBar 
                    welcomeMsg1 ={textStrings.WELCOME_MSG_1}
                    welcomeMsg2 ={textStrings.WELCOME_MSG_2}
                />
                <Help 
                        windowDimensions ={ui.windowDimensions}
                        textStrings={textStrings}
                />
                <Languages textStrings={textStrings} changeLanguage={changeLanguage}/>
            </div>
        );
    }
}


const styles ={
    container:{
        backgroundImage:`url(${Image})`,
        backgroundPosition: 'center',
        backgroundSize:'cover',
        flexDirection:'column',
        display:'flex',
        minHeight:500,
        width:'100vw',
        
        position:'absolute',
        top:0,
        left:0,
        right:0,
        bottom:0
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        settings: state.settings.settings,
        textStrings:state.textStrings,
        ui:state.ui
    }
}

export default connect(mapStateToProps,{createFirstAccount,changeLanguage})(Welcome)