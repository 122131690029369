import * as actionCreators from '../actions/actionCreators';
import { put,select,call,} from 'redux-saga/effects'
import { getGlobals }  from '../../common/utils'

const globals = getGlobals()

export function* loadLicense(){
    let {license}  = yield select((state)=>state);
    let products =  yield call(globals.platform.loadLicense,license.products);
    yield put(actionCreators.loadLicenseFinish(products))
}

export function* purchaseProductStart(action){
    //Purchase Product
    yield call (globals.platform.purchaseProduct,action.payload);
    //Load License again
    yield call(loadLicense)
}
