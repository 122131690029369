import React, { Component } from 'react';
import {getFormattedDate,tCss} from '../utils'
import {StyleSheet} from 'aphrodite'
import Colors from '../COLORS'

const CleanView = ({mercuryArticle,settings,story})=>{
    let css =  tCss(settings.theme)(styles);
    return <div className={css(['container'])}>
        <div className={css(['title'])}>
            {story.title}
        </div>
        <div className={css(['meta'])}        >
            {mercuryArticle.author}{mercuryArticle.author? '/':''}{getFormattedDate(story.published)}
        </div>
        <div 
            style={{
                fontSize:settings.fontSize
            }}
            className={ css(['content'])}
            dangerouslySetInnerHTML={{__html:mercuryArticle.contentText}}>
        </div>
    </div>

}

//Copied from Default View
const styles = StyleSheet.create({
    container:{
        padding:'0px 15px',
    },
    title:{
        fontSize:'1.8em',
        padding:'10px 0px',        
    },
    title_LIGHT:{
        color:Colors.BLACK_TEXT2
    },
    title_DARK:{
        color:Colors.WHITE_TEXT2
    },
    content:{
        lineHeight:1.7,
        padding:'15px 15px'
    },
    meta:{
        fontSize:'0.9em',
        padding:'0px 0px',
        fontWeight:300,
        textTransform:'uppercase'
    }
});

export default CleanView;