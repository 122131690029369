import {
    FETCH_ACCOUNTS_FINISH,CREATE_ACCOUNT_FINISH,UPDATE_TOKEN_PROFILE,REMOVE_ACCOUNT} from '../actions/names';

const initialState =[];

export default function reducer(state= initialState, action){
    if(action.type === FETCH_ACCOUNTS_FINISH){
        return action.payload;
    }
    if(action.type === CREATE_ACCOUNT_FINISH){
        return state.concat(action.payload);
    }
    if(action.type === UPDATE_TOKEN_PROFILE){
        let {profile,token} = action.payload;
        return state.map((acc)=>{
            if(acc.id === profile.id){
                acc.profile = profile;
                acc.token = token;
            }
            return acc;
        })
    }

    if(action.type === REMOVE_ACCOUNT){
        let account = action.payload;
        return state.filter((a)=>{
            return a.id !== account.id
        })
    }

    return state;
}