import {SELECT_STORY,CLEAR_STORY,TOGGLE_STORY_VIEW, FETCH_MERCURY_ARTICLE_FINISH,SWITCH_ACCOUNT} from '../actions/names';
import {STORY_VIEW,} from '../../common/enums'

function getDefaultView(story){
    return {
        story:story,
        view:STORY_VIEW.DEFAULT,
        mercuryArticle:null
    }
}

const initialState = getDefaultView(null);


export default function reducer(state= initialState, action){
    if(action.type === SWITCH_ACCOUNT){
        return getDefaultView(null);
    }
    if(action.type === SELECT_STORY){       
        return getDefaultView(action.payload);
    }
    if(action.type === CLEAR_STORY){
        return getDefaultView(null);
    }

    if(action.type === TOGGLE_STORY_VIEW){
        //If view is already there reset to default
        if(action.payload === state.view){
            return {...state,view:STORY_VIEW.DEFAULT}
        }
        return {...state,view:action.payload}
    }

    if(action.type === FETCH_MERCURY_ARTICLE_FINISH){
        return {...state, mercuryArticle:action.payload};
    }

    return state;
}