import {getFormattedUrl,__getProfile, __getToken, getKey,getRedirectUrl} from './feedly'

const LoginStatus = {
    STARTING_AUTH: 'STARTING_AUTH',
    WAITING_FOR_AUTHRIZATION: 'WAITING_FOR_AUTHRIZATION',
    COLLECTING_TOKEN: 'COLLECTING_TOKEN',
    COLLECTING_PROFILE: 'COLLECTING_PROFILE',
    ERROR: 'ERROR',
}


const noop = () => {}
const getDatafromResponse = (res) => res.data;
const getStateForWeb = () => window.location.href;
const getStateForWin = () => "";
const getStateForMac = () => "";
const getStateForChrome = () => `https://${window.location.host}.chromiumapp.org`;
const emptyState = () => "";
const getToken = (authCode, platform) => platform.makeWebRequest(__getToken(authCode))
const getProfile = (token, platform) => platform.makeWebRequest(__getProfile(token))

const getState = (platform) => {
    /**Feedly Auth System accepts a  state. Same state comes back after successful login.
     * We are are passing different states  based on platform
     * Returns a funcion
     */
    return function () {
        let platformKey = platform.getKey();
        let states = {
            "web": getStateForWeb,
            "win": getStateForWin,
            "chr": getStateForChrome
        }
        return (states[platformKey] || emptyState)();
    }
}


function getAuthCodeFromUrl(url) {
    //http://stackoverflow.com/questions/8648892/convert-url-parameters-to-a-javascript-object
    /* Format of redirect URL
      http://localhost:8080/?code=AxOA7ma2u1ckRLwNsRahh7qeiAdTOqnyyxI0zqyAlwqyhPSnEgY2zfRIEl_ByPEsJegdpmZ-W9TT-7722onDgU1PEL-JxExrqRFP0T_ik2JGz81fAmL64oHAfaxxDTdTyDqxM3DXfjDrZI7gxZZzcplHgY4kmS3ZmYKWBJ9MAd6KDOOZR5dgZGYWG50&state=#
      */
    try {
        let urlObj = new URL(url);
        let search = urlObj.search.substring(1);
        let o = JSON.parse('{"' + decodeURI(search)
            .replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
        if (o.error || o.code) {
            return o;
        }
        throw ""
    } catch (error) {
        return {
            error: 'Unknown error'
        };
    }
}


function handleRedirect(callbacks, platform) {
    return function (redirectURI) {
        //get code from url
        let authCode = getAuthCodeFromUrl(redirectURI);
        if (authCode.error) {
            callbacks.onError(LoginStatus.ERROR, 'Could parse redirect URL');
        } else {
            //Get Token from access_code
            callbacks.onStatusUpdate(LoginStatus.COLLECTING_TOKEN);
            getToken(authCode.code, platform).then(function (res1) {
                //Token Received
                let token = getDatafromResponse(res1);
                token.created_at = Date.now();
                //Get Profile
                callbacks.onStatusUpdate(LoginStatus.COLLECTING_PROFILE);
                getProfile(token, platform).then(function (res2) {
                    //Profile Received
                    //Format account and call success
                    let profile = getDatafromResponse(res2)
                    callbacks.onCompleted(profile, getKey(), token);
                }).catch(function (e) {
                    //Error in getting profile
                    callbacks.onError(LoginStatus.ERROR, e);
                });
            }).catch(function (e) {
                //Error in getting token
                callbacks.onError(LoginStatus.ERROR, e);
            })
        }
    }
}

function startLoginFlow(callbacks, fullName, platform) {
    //onStatusUpdate,onCompleted,onCancel,onError
    /**FLOW
     * Redirect user to auth window. After successfull auth you will be redirected to 
     * an url where access code is availbale. Platform.LoginFlow returns us the full url
     * a. Now we parse it to collect access_code
     * b. Using access_code, make webrequest and collect  access_token
     * c. make web request and collect profile
     * d. DONE!!
     */

    //Sanitize callbacks
    let {
        onStatusUpdate = noop, onCompleted = noop, onCancel = noop, onError = noop
    } = callbacks;
    let sanitizedCallbacks = {
        onStatusUpdate,
        onCompleted,
        onCancel,
        onError
    };
    onStatusUpdate(LoginStatus.WAITING_FOR_AUTHRIZATION);
    //Get Formatted URL with state. 
    //State is passed in login flow and its returned as it is by the AuthSystem
    //We will pass this url in Platform auth flow system.Eg: Popup in browser.     
    let formattedUrl = getFormattedUrl(getState(platform));
    //on successsfull completion browser/system will redirect us to a link
    //Which is picked  by Platform Auth system. 
    //This link contains auth_code and we need to exchange this auth code to get an auth token
    //Platform expects an 'onSuccess' function
    //that gets invoked when user successfully authenticates our app
    //and we are redirected to AUTH URL, onSuccess function
    //receives this url. 
    //We will do further processing in this function
    let hackedCallbacks = {
        ...sanitizedCallbacks,
        ...{
            onSuccess: handleRedirect(sanitizedCallbacks, platform)
        }
    };
    onStatusUpdate(LoginStatus.WAITING_FOR_AUTHRIZATION);
    //If any error happens or, user cancels the auth Flow
    //platform.launchAuthFlow will automatically call relevant method
    platform.launchAuthFlow(formattedUrl, hackedCallbacks, getRedirectUrl());
}


export {
    startLoginFlow
};
