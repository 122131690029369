import lang from '../../lang/';
import {CHANGE_SETTINGS,FETCH_SETTINGS_FINISH,SWITCH_ACCOUNT} from '../actions/names';

//Default language is English
const initialState = lang.getEnglishStrings();

export default function reducer(state= initialState,action){
    if(action.type === SWITCH_ACCOUNT){
        return initialState;
    }

    //Handle Change Settings
    if(action.type === CHANGE_SETTINGS && action.payload.key === 'language'){
        return lang.getStrings(action.payload.value);
    }

    //Handle App Load settings
    if(action.type === FETCH_SETTINGS_FINISH){
        let languageKey = action.payload.language;
        if(languageKey){
            return lang.getStrings(languageKey);
        }
    }

    return state;
}