import React, { Component } from 'react';
import {StyleSheet} from 'aphrodite';
import classnames from 'classnames';
import {connect} from 'react-redux';
import {changeLanguage, changeSettings} from '../../redux/actions/actionCreators';
import {THEMES, TEMPLATES} from '../../common/enums'
import Overlay from '../Overlay'
import Colors from '../COLORS'
import {tCss} from '../utils'
import lang from '../../lang';

class Settings extends Component {
    render() {
        let {changeLanguage,settings,changeSettings,textStrings,isOpen,toggle} = this.props;
        
        if(!isOpen){
            return  null;
        }
        let css2= tCss(settings.theme)(styles)
        return (<Overlay onHide={toggle} style={{
            backgroundColor:'rgba(0,0,0,0.2)'
        }}>

                        <div className={classnames(css2('container'), 'animated','slideInRight')}>
                            <div className={css2('header')}>
                                {textStrings.SETTINGS}
                            </div>

                            <div className={css2('content')}>
                                    <div className={css2('row')}>
                                        <div className={css2('rowLeft')}>
                                            {textStrings.FONT_SIZE}
                                        </div>
                                        <div  className={css2('rowRight')}>
                                            <select value={settings.fontSize} onChange={(e)=>{changeSettings('fontSize',e.target.value,true)}} className={css2('select')}>
                                                <option value={12}>12</option>
                                                <option value={14}>14</option>
                                                <option value={16}>16</option>
                                                <option value={18}>18</option>
                                                <option value={20}>20</option>
                                                <option value={22}>22</option>
                                                <option value={24}>24</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className={css2('row')}>
                                        <div className={css2('rowLeft')}>
                                            {textStrings.LANGUAGE}
                                        </div>
                                        <div className={css2('rowRight')}>
                                            <select onChange={(e)=>{changeLanguage(e.target.value)}} value={settings.language} className={css2('select')}>
                                                {lang.getActiveNames().map((lang,i)=>{
                                                    return <option key={i} value={lang.key}>{lang.displayName}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>


                                    <div className={css2('row')}>
                                        <div className={css2('rowLeft')}>
                                            {textStrings.THEME}
                                        </div>
                                        <div className={css2('rowRight')}>
                                            <select value={settings.theme} onChange={(e)=>{changeSettings('theme',e.target.value)}} className={css2('select')}>
                                                <option value={THEMES.LIGHT}>{textStrings.LIGHT}</option>
                                                <option value={THEMES.DARK}>{textStrings.DARK}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className={css2('row')}>
                                        <div className={css2('rowLeft')}>
                                                {textStrings.SYNC_ON_START}
                                        </div>
                                        <div className={css2('rowRight')}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={settings.syncOnStart} 
                                                        onChange={()=>{changeSettings('syncOnStart',!settings.syncOnStart)}} />
                                                    <span className="slider round"></span>
                                                </label>
                                        </div>
                                    </div>

                                    <div className={css2('row')}>
                                        <div className={css2('rowLeft')}>
                                            {textStrings.REMOVE_ITEMS_OLDER_THAN}
                                        </div>
                                        <div className={css2('rowRight')}>
                                            <select value={settings.removeItemsOlderThan}  
                                                onChange={(e)=>{changeSettings('removeItemsOlderThan',e.target.value,true)}} className={css2('select')}>
                                                <option value={2}>2 {textStrings.DAYS}</option>
                                                <option value={5}>5 {textStrings.DAYS}</option>
                                                <option value={15}>15 {textStrings.DAYS}</option>
                                                <option value={30}>30 {textStrings.DAYS}</option>
                                                <option value={60}>60 {textStrings.DAYS}</option>
                                                <option value={999999999}>{textStrings.NEVER}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className={css2('row')}>
                                        <div className={css2('rowLeft')}>
                                            {textStrings.TEMPLATE}
                                        </div>
                                        <div className={css2('rowRight')}>
                                            <select value={settings.template} onChange={(e)=>{changeSettings('template',e.target.value)}} className={css2('select')}>
                                                <option value={TEMPLATES.TILE}>{textStrings.TILE}</option>
                                                <option value={TEMPLATES.CARD}>{textStrings.CARD}</option>
                                                <option value={TEMPLATES.CLASSIC}>{textStrings.CLASSIC}</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className={css2('row')}>
                                        <div className={css2('rowLeft')}>
                                            {textStrings.REFRESH_AFTER}
                                        </div>
                                        <div className={css2('rowRight')}>
                                            <select value={settings.refreshAfter} onChange={(e)=>{changeSettings('refreshAfter',e.target.value,true)}} className={css2('select')}>
                                                    <option value="5">{textStrings.EVERY_X_MINUTES(5)}</option>
                                                    <option value="10">{textStrings.EVERY_X_MINUTES(10)}</option>
                                                    <option value="15">{textStrings.EVERY_X_MINUTES(15)}</option>
                                                    <option value="30">{textStrings.EVERY_X_MINUTES(30)}</option>
                                                    <option value="45">{textStrings.EVERY_X_MINUTES(45)}</option>
                                                    <option value="60">{textStrings.EVERY_X_HOUR(1)}</option>
                                                    <option value="120">{textStrings.EVERY_X_HOUR(2)}</option>
                                                    <option value="14400">{textStrings.MANUALLY}</option>
                                            </select>
                                        </div>
                                    </div>


                            </div>

                            <div className={css2('footer')}>                                
                                <div className={css2('footerRow')}>                                    
                                    {textStrings.SUPPORT_EMAIL}: theveenreader@gmail.com
                                </div>
                                <div  className={css2('footerRow')}>
                                    <a rel="noopener noreferrer" target="_blank" href='http://privacy.veenreader.com/'>{textStrings.PRIVACY_POLICY}</a>&nbsp;&nbsp;&nbsp;Version {process.env.REACT_APP_VERSION}
                                </div>
                            </div>
                        </div>
                </Overlay>
        );
    }
}

const styles= StyleSheet.create({
    container:{
        position:'absolute',
        top:0,
        right:0,
        bottom:0,
        width:400,
        height:'100vh',
        zIndex:Overlay.zIndex,
        display:'flex',
        flexDirection:'column',
        maxWidth:'100vw'
    },
    [`container_${THEMES.LIGHT}`]:{
        backgroundColor:'#fff',
    },
    [`container_${THEMES.DARK}`]:{
        backgroundColor:'#333',
        color:Colors.WHITE1
    },    
    header:{
        color:Colors.WHITE1,
        backgroundColor:Colors.PRIMARY,
        padding:10,
        fontSize:'1.5em',
        fontWeight:100
    },
    content:{
        overflowY:'auto',
        flex:1,
        padding:10
    },
    footer:{
        padding:10,
        backgroundColor:'#f4f4f4',
        borderTop:'1px solid #ccc'
    },
    footerRow:{
        padding:'5px 10px'
    },
    rowHeader:{
        fontSize:'1.2em'
    },
    row:{
        display:'flex',
    },
    [`row_${THEMES.LIGHT}`]:{
        borderBottom:'1px solid #ccc',
    },
    [`row_${THEMES.DARK}`]:{
        borderBottom:'1px solid #444',
    },
    rowLeft:{
        flex:7,
        padding:10,
        display:'flex',
        alignItems:'center'
    },
    rowRight:{
        flex:4,
        padding:10,
        display:'flex',
        alignItems:'center'
    },
    [`select_${THEMES.LIGHT}`]:{
    },
    [`select_${THEMES.DARK}`]:{
        color:Colors.WHITE_TEXT2,
    }
});

const mapStateToProps = (state, ownProps) => {
    return {
        settings:state.settings.settings,
        textStrings:state.textStrings
    }
}

export default connect(mapStateToProps, {changeLanguage,changeSettings})(Settings)