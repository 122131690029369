import React, { Component } from 'react';
import Ionicon from 'react-ionicons';
import {connect} from 'react-redux'
import {StyleSheet} from 'aphrodite'
import {tCss,isPremium} from '../utils'
import COLORS from '../COLORS';
import {THEMES} from '../../common/enums'


const fontSize = "20px";

class BuyButton extends Component {
    render() {
        let {settings,license,toggleCatalogue} = this.props;settings = settings.settings;        
        if(!license.loaded || isPremium(license.products)){
            return null;
        }
        let css2 = tCss(settings.theme)(styles);
        return (
            <div className={css2('container')} onClick={toggleCatalogue}>
                <Ionicon 
                    icon="ion-android-cart" 
                    fontSize={fontSize} 
                    className = {css2(['iconClass'])}
                />
            </div>
        );
    }
}

const styles= StyleSheet.create({
    container:{
        display:'flex',
        width:'100%',
        height:'100%',
        justifyContent:'center',
        alignItems:'center',
    },
    [`iconClass_${THEMES.LIGHT}`]:{
        fill:COLORS.BLACK_TEXT1
    },
    [`iconClass_${THEMES.DARK}`]:{
        fill:COLORS.WHITE_TEXT2
    },
});

const mapStateToProps = (state, ownProps) => {
    return {
        settings: state.settings,
        license:state.license
    }
}

export default connect(mapStateToProps)(BuyButton)