import {FETCH_SETTINGS_FINISH,CHANGE_SETTINGS,SWITCH_ACCOUNT} from '../actions/names';
import {THEMES, TEMPLATES} from '../../common/enums';
import lang from '../../lang/';



const defaultSettings ={
    fontSize:16,
    theme:THEMES.LIGHT,//TOdo: this should come from conf
    language:lang.getEnglishKey(),
    syncOnStart:true,
    refreshAfter:10, //time in minutes
    removeItemsOlderThan:15, //days
    template:TEMPLATES.TILE,
    defaultSmallLeftPanelIn3Pane:false
}

const initialState ={
    //this is set by user. Only this part is stored in DB
    _user:{},        

    //This is computed from defaultSettings and _user. 
    //Components should use this property
    settings:{...defaultSettings}  
};

export default function reducer(state= initialState, action){
    if(action.type === SWITCH_ACCOUNT){
        return initialState;
    }

    //If no account found then {} is received
    if(action.type === FETCH_SETTINGS_FINISH){
        let userSettings = action.payload || {};
        let finalSettings = {
            _user: userSettings,
            settings:{...defaultSettings,...userSettings}
        }
        return finalSettings;
    }

    if(action.type === CHANGE_SETTINGS){
        let {key,value} = action.payload;
        let userSettings = {...state._user,[key]:value};
        let finalSettings =  {
            _user: userSettings,
            settings:{...defaultSettings,...userSettings}
        }
        return finalSettings;        
    }

    return state;
}