import english from './english';
import hindi from './hindi';
import russian from './russian';
import {StringsType} from './Types';


const ENGLISH_KEY = "english";
const NAMES= [
    {
        displayName:'English',
        key:ENGLISH_KEY,
        stringsGen:english,
        active:true
    },
    {
        displayName:'हिंदी',
        key:'hindi',
        stringsGen:hindi,
        active:false
    },
    {
        displayName:'русский',
        key:'russian',
        stringsGen:russian,
        active:true
    }
];

const methods = {
    getStrings:function(key){
        if(key === ENGLISH_KEY){
            return methods.getEnglishStrings();
        }
        for (let i=0; i < NAMES.length;i++){
            let name = NAMES[i];
            if(name.key === key){
                return {...english(ENGLISH_KEY),...name.stringsGen(key)}
            }
        }
    },
    getNames:function(){
        return NAMES;
    },
    getActiveNames:function(){
        return methods.getNames().filter(n=>n.active);
    },
    getEnglishStrings:function(){
        return english(ENGLISH_KEY);
    },
    getEnglishKey:function(){
        return ENGLISH_KEY;
    }
}

export default methods;
export {StringsType};