import { call,put, select } from 'redux-saga/effects'
import striptags from './scriptags'
import {sortBy} from 'lodash'
import stopWords from './stopwords';
import * as actionCreators from '../actions/actionCreators';
import { delay} from 'redux-saga/effects'
import { getGlobals }  from '../../common/utils'
const globals = getGlobals()

var All_Stories=[];

//Convert story to some text that can be searched
function getTextFromStory(story){
    var res= [];
    if(story){
        res.push(story.title);
        if(story.payload.summaryText){
            res.push(story.payload.summaryText);
        }
        if(story.payload.contentText){
            res.push(story.payload.contentText);
        }
    }

    return striptags(res.join(" ")).toLowerCase().split("").filter((c)=>{
        return   ("#$%&'’()*+,-./:;<=>?@[\]^_`{|}~").lastIndexOf(c)<0;
    }).join("");
}

//Returns an array of words. Words may repeat.
function textToWords(text){
    return text.split(" ");
}

//Creates a frequency chart for each word in text
function wordsArrToWordsVector(words){
    var o = {};
    for(let i=0;i<words.length;i++){
        let word = words[i];
        //Word should not be stopwords.
        if(stopWords.indexOf(word)<0){
            o[word] = (o[word] || 0)+1;
        }
    }
    return o;
}

let times = {
    s1:0,
    s2:0,
    s3:0
}
let _cache={};
//looks for a storyVector in cache
var getStoryVector = (story)=>{
    return _cache[story.id]
    // if(_cache[story.id]){
    //     return _cache[story.id];
    // }else{
    //     let t1= Date.now();
    //     let text = getTextFromStory(story);
    //     let t2= Date.now();
    //     let words = textToWords(text)
    //     let t3= Date.now();
    //     var storyVector = wordsArrToWordsVector(words);
    //     let t4= Date.now();
    //     times.s1 = times.s1 + t2-t1;
    //     times.s2 = times.s2 + t3-t2;
    //     times.s3 = times.s3 + t4-t3;
    //     _cache[story.id] = storyVector;
    //     return storyVector;
    // }    
}



function* getStoryVector2(story){
    let t1= Date.now();
    let text = getTextFromStory(story);
    let t2= Date.now();
    let words = textToWords(text)
    let t3= Date.now();
    var storyVector = wordsArrToWordsVector(words);
    let t4= Date.now();
    times.s1 = times.s1 + t2-t1;
    times.s2 = times.s2 + t3-t2;
    times.s3 = times.s3 + t4-t3;
    _cache[story.id] = storyVector;
    return storyVector;
}


function resetCache(){
    _cache={}
}


function inner_product(d1,d2){
    var sum =0.0;
    for (let key in d1){
        if(d2[key]){
            sum = sum + d1[key] + d2[key]
        }
    }
    return sum;
}

function vector_angle(d1,d2){
    var numerator = inner_product(d1,d2);
    var denominator = Math.sqrt(inner_product(d1,d1)* inner_product(d2,d2));
    return Math.acos(numerator/denominator);
}


function textToVector(text){
    return wordsArrToWordsVector(textToWords(text));
}

function getSuggestedStories(textVector,allStories){
    if(allStories.length<1){
        return [];
    }

    // console.log(textVector)

    let storiesDict =[]
    for(let i=0;i<allStories.length;i++){
        let s = allStories[i];
        var testStory = getStoryVector(s);
        //Move further only if story is cached.
        if(testStory){
            let distance = Math.round(vector_angle(textVector,testStory)*10000);
            // let distance = vector_angle(dictStory,testStory);
            if(distance < 15200){
                storiesDict.push({
                    distance: distance,
                    story:s
                });
            }
        }
    }
    return sortBy(storiesDict,['distance']);
}

export function* handleSearch(action){
    //Collect keyword
    let q= action.payload;
    let t1= Date.now();
    //keyword to vector
    let qVector = textToVector((q || '').toLowerCase());
    //get results
    let results = getSuggestedStories(qVector,All_Stories);
    //extract stories
    // console.log(results[0])
    results = results.map((r)=>r.story)
    yield put(actionCreators.fetchStoriesFinish(results));
    let t2= Date.now();
    // console.log(t2-t1,'SearchTime');
}   


export function* buildCache(){
    resetCache();
    const DB = globals.db;
    const {subscriptions} = yield select((state)=>state);
    let allStories = yield call(DB.fetchStories,subscriptions.map((m)=>m.id));
    All_Stories = allStories;


    //This prepares cache
    //This has to be non blocking
    // console.log('Total stories: ',allStories.length);
    let t1= Date.now();
    for(let i=0;i<allStories.length;i++){
        // console.log(i); 
        try {
            let testStory =  yield call(getStoryVector2,allStories[i]);
            yield delay(2)
            // if(i>403 && i<425){
            //     console.log(allStories[i],"-----",'allStories[i]');
            //     console.log(testStory,'-----','testStory');
            // }
        } catch (error) {
            console.log('Err',error);
        }
    }
    let t2= Date.now();
    // console.log(times);
    // console.log(t2-t1,'Cache Building TIme:', allStories.length,((t2-t1)/allStories.length));
    // console.log(_cache['2IAKmisI+skG5eJD6FDanzV4JS35tHTM1ILbuVimBro=_15e2c17c4bb:5ee1071:218de7ce']);
}