import React, { Component } from 'react';
import { connect } from 'react-redux';
import Splash from './Splash';
import Welcome from './Welcome';
import './main.css'
import LayoutManager from './Layouts/LayoutManager';
import { updateDimensions } from '../redux/actions/actionCreators'


class ScreenManager extends Component {
    _resizeHandler = null;

    _handleResize = () => {
        //Clear any running timer
        window.clearTimeout(this._resizeHandler);
        //start new timer
        this._resizeHandler = window.setTimeout(() => {
            this.props.updateDimensions();
        }, 150)
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this._handleResize)
    }
    componentDidMount() {
        window.addEventListener('resize', this._handleResize)
    }

    render() {
        // return (<Splash />)

        let { appState, ui }  = this.props;
        if (appState.ready) {
            if (appState.accountFound) {
                return (<LayoutManager  windowDimensions= { ui.windowDimensions } />)
            } else {
                return (<Welcome />)
            }
        } else {
            return (<Splash />)
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        appState: state.appState,
        ui: state.ui
    }
}

export default connect(mapStateToProps, { updateDimensions })(ScreenManager);