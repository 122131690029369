export default function(key){
    let lang ={
    key:key,
    WAIT:'Подождите...',

    //Add Strings Here
    WELCOME_MSG_1 : "welcome to",
    WELCOME_MSG_2 : " veen",
    ERROR : "ошибка",
    SUCCESS:"успех",

    OR: "либо",
    FEEDLY : "feedly",
    FACEBOOK:"facebook",
    FULLNAME:"Имя",
    START_WITH_LOCAL_ACCOUNT : "начать с локальной учетной записи",
    TWITTER :"щебет",
    LOCAL:"местный",

    //Accounts
    SWITCH_ACCOUNT:"Сменить аккаунт",
    ADD_NEW : "Dobavit' novoye",
    REMOVE_ACCOUNT:"Удалить",
    REMOVE_ACCOUNT_SCREEN:"Удалить Счет",
    UNDO_REMOVE:"расстегивать",
    CHOOSE_A_SERVICE:"Выберите услугу",
    CREATE_LOCAL_ACCOUNT:"Создание локальной учетной записи",
    CREATE:"Создайте",

    BACK:'назад',

    ALL:'все',
    BOOKMARKS:'закладки',
    FAVOURITE:'фаворит',
    UNREAD:'непрочитанный',

    SETTINGS:'настройки',
    SYNCING:'Синхронизации',
    SYNC_ON_START:'Синхронизация при запуске',
    ON:'на',
    OFF:'от',
    GENERAl:'Генеральная',
    TEMPLATE:'шаблон',
    READING_OPTIONS:'Параметры чтения',
    FONT_SIZE:'Размер шрифта',
    THEME:'тема',
    REMOVE_ITEMS_OLDER_THAN:'Удалить элементы старше, чем',
    NEVER:'Никогда',
    MANUALLY:'ВРУЧНУЮ',
    DAYS:'дней',
    HINDI:'Hindi',
    ENGLISH:'English',
    CARD:'карты',
    TILE:'Кафельная плитка',
    CLASSIC:'классический',


    MARK_AS_READ:'пометить, как прочитанное',
    MARK_AS_UNREAD:'Отметить как непрочитанное',
    WEB_VIEW:'Webview',
    COPY_LINK:'Копировать ссылку',
    NEWEST_FIRST:'Новый первый',
    OLDEST_FIRST:'Старый',
    POPULAR_FIRST:'Популярные первые',
    LIGHT:'световой',
    DARK:'Темно',
    LANGUAGE:'язык',
    SEARCH:'Поиск',
    TYPE_TITLE_URL:'введите заглавие, предмет, url, ключевое слово',
    SEARCH_BAR_TEXT:'История поиска',
    SUBSCRIBE:'Подписывайся',
    UNCATEGORISED:'Uncategorised',
    SAVE:'Сохранить',
    NEW_FOLDER_NAME:'Необязательный: Новое имя папки',
    REFRESH_AFTER:'Обновить после',
    EVERY_X_MINUTES:(n)=>{return `каждые ${n} минуты`},
    EVERY_X_HOUR:(n) =>{return `каждые ${n} часа`},
    STATUS_COPIED_TO_CLIPBOARD:'Скопировано в буфер обмена!!',
    SYNC_TOKEN:'Синхронизирующий токен',
    SYNC_FINISHED:'Синхронизация завершена',
    SYNC_SUBSCRIPTIONS:'Синхронизация подписки',
    SYNC_STORIES:'Синхронизировать истории',

    STARTING_AUTH:'Запуск авторизации',
    WAITING_FOR_AUTHRIZATION:'Ожидание авторизации',
    COLLECTING_TOKEN:'Собирающий токен',
    COLLECTING_PROFILE:'Сбор профиля',
    APP_NAME:'Veen',
    HELP:'Помогите',
    MARK_LIST_AS_READ:'Отметить список как прочитанный',
    SUPPORT_EMAIL:'Поддержка электронной почты',
    PRIVACY_POLICY:'политика конфиденциальности'
    };
    return lang;
};