import {LOAD_LICENSES_FINISH} from '../actions/names';
import {PRODUCTS_ENUM} from '../../common/enums'

//View components use. 
//This info to display however price comes from platform
function getProducts(){
    var products = {
        [`${PRODUCTS_ENUM.UNLIMITED}`]: {
            isActive : false,
            label : 'Unlimited',
            productId : PRODUCTS_ENUM.UNLIMITED,
            features:[
                'Remove ads',
                'Create and manage unlimited accounts'
            ],
            price:0.1,
        },
        [`${PRODUCTS_ENUM.ADDFREE}`] : {
            isActive: false,
            label: 'Ad-Free',
            productId: PRODUCTS_ENUM.ADDFREE,
            features:[
                'Remove ads',
                'Create and manage 1 account per service'
            ],
            price:0.2,
        },
    };
    return products;
}

const initialState= {
    products:getProducts(),
    loaded:false
};

function reducer(state=initialState,action){
    if(action.type === LOAD_LICENSES_FINISH){
        return {
            ...state, products:action.payload,loaded:true
        }
    }
    return state;
}
export default reducer;