import React from 'react';
import services from '../../services'
import Spacer from './Spacer'


const Col = ({children})=>(<div>{children}</div>)

const Input = ({placeholder,hanldeFullNameChange}) => (<input placeholder={placeholder} type="text" style={styles.inputText} onChange={hanldeFullNameChange} />)

const Button = ({text,textColor,bgColor,onClick,service}) => {
    return <button
             onClick={() =>{onClick(service)}} 
             style={{...styles.button,...{
                    color:textColor || '#fff',
                    textTransform: 'uppercase',
                    backgroundColor:bgColor || '#fff'
                }}}>{text}</button>
}

const StatusBar = ({status})=>(
    <div style={{...styles.container, justifyContent:'center'}}>
        {status} 
    </div>
)

const Middlebar = ({textStrings,onButtonClick,hanldeFullNameChange})=>(
    <div style={styles.container}>
        <div style={styles.formContainer}>
            <Col>
                <form onSubmit={(e)=>{
                    e.preventDefault();
                    onButtonClick(services.local);
                }}>
                    <Input hanldeFullNameChange={hanldeFullNameChange} placeholder={textStrings.FULLNAME}  />
                </form>
            </Col>
            <Col>
                <Button onClick={onButtonClick} text={textStrings.START_WITH_LOCAL_ACCOUNT} bgColor="#b02727"  service={services.local} />
            </Col>
            <Col><Spacer textStrings={textStrings} /></Col>
            <Col>
                <Button onClick={onButtonClick} text={textStrings.FEEDLY} bgColor="green"   service={services.feedly}  />
            </Col>
        
            {/**
            <Col><Spacer textStrings={textStrings} /></Col>
            <Col>
                <Button onClick={onButtonClick} text={textStrings.FACEBOOK} bgColor="#3d5b99" service={services.facebook} />
            </Col>
            <Col><Spacer textStrings={textStrings} /></Col>
            <Col>
                <Button onClick={onButtonClick} text={textStrings.TWITTER} bgColor="#03a9f4"  />
            </Col>
            * 
             */}
        </div>
    </div>
)

let styles= {
    container:{
        display:'flex',
        flex:1,
        color:'#fff',
        alignItems:'center'
    },      
           
    formContainer:{
        display:'flex',
        flexDirection:'column',
        padding:20,
        maxWidth:600,
        margin:'0px auto',
        width:'100%',
        backgroundColor:'rgba(0, 0, 0, 0.71)',
        boder:'1px solid #555',
        borderRadius:3,
    },
    button:{
        width:'100%',
        border:'none',
        padding:10,
        borderRadius:3,
        color:'#fff',
        cursor:'pointer',
        fontSize:14
    },
    inputText:{
        width:'100%',
        marginBottom:10,
        padding:10,
        color:'#000',
        textTransform: 'capitalize',
        fontSize:14
    }
}

export  {Middlebar, StatusBar};