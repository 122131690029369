import AdSense from './Adense';
import React, { Component } from 'react';

class Adv extends Component {
    componentDidMount(){
        let {setWidth} = this.props;
        setWidth(468);
        
    }
    
    render() {
        let {zoom} = this.props;
        return(
            <AdSense 
                client='ca-pub-1136075622746933'
                slot='2821161591' 
                style={{
                        width: 468, 
                        height: 60,
                        display:'inline-block',
                        zoom: zoom,
                    }}
            />
        )
    }
}

export default Adv;
