export default function(key){
    let lang ={
    key:key,
    WAIT:'Loading...',

    //Add Strings Here
    WELCOME_MSG_1 : "welcome to",
    WELCOME_MSG_2 : " veen",
    ERROR : "Error",
    SUCCESS:"Success",

    OR: "or",
    FEEDLY : "feedly",
    FACEBOOK:"facebook",
    FULLNAME:"full name",
    START_WITH_LOCAL_ACCOUNT : "start with local account",
    TWITTER :"twitter",
    LOCAL:"local",

    //Accounts
    SWITCH_ACCOUNT:"Switch Account",
    ADD_NEW : "Add New",
    REMOVE_ACCOUNT:"Remove",
    REMOVE_ACCOUNT_SCREEN:"Remove Account",
    UNDO_REMOVE:"Undo",
    CHOOSE_A_SERVICE:"Choose a service",
    CREATE_LOCAL_ACCOUNT:"Create a local account",
    CREATE:"Create",

    BACK:'Back',

    ALL:'All',
    BOOKMARKS:'Bookmarks',
    FAVOURITE:'Favourites',
    UNREAD:'Unread',

    SETTINGS:'Settings',
    SYNCING:'Syncing',
    SYNC_ON_START:'Sync on start',
    ON:'On',
    OFF:'Off',
    GENERAl:'General',
    TEMPLATE:'Template',
    READING_OPTIONS:'Reading Options',
    FONT_SIZE:'Font Size',
    THEME:'Theme',
    REMOVE_ITEMS_OLDER_THAN:'Remove items older than',
    NEVER:'Never',
    MANUALLY:'Manually',
    DAYS:'Days',
    HINDI:'Hindi',
    ENGLISH:'English',
    CARD:'Card',
    TILE:'Tile',
    CLASSIC:'Classic',


    MARK_AS_READ:'Mark as read',
    MARK_AS_UNREAD:'Mark as unread',
    WEB_VIEW:'Webview',
    COPY_LINK:'Copy link',
    NEWEST_FIRST:'Newest first',
    OLDEST_FIRST:'Oldest First',
    POPULAR_FIRST:'Popular First',
    LIGHT:'Light',
    DARK:'Dark',
    LANGUAGE:'Language',
    SEARCH:'Search',
    TYPE_TITLE_URL:'Type title, topic,  url or keyword',
    SEARCH_BAR_TEXT:'Search stories',
    SUBSCRIBE:'Subscribe',
    UNCATEGORISED:'Uncategorised',
    SAVE:'Save',
    NEW_FOLDER_NAME:'Optional: New folder name',
    REFRESH_AFTER:'Refresh after',
    EVERY_X_MINUTES:(n)=>{return `Every ${n} minutes`},
    EVERY_X_HOUR:(n) =>{return `Every ${n} hour${n>0?'s':''}`},
    STATUS_COPIED_TO_CLIPBOARD:'Copied to clipboard!!',
    SYNC_TOKEN:'Sync token',
    SYNC_FINISHED:'Sync finished',
    SYNC_SUBSCRIPTIONS:'Sync subscriptions',
    SYNC_STORIES:'Sync stories',

    STARTING_AUTH:'Starting authorization',
    WAITING_FOR_AUTHRIZATION:'Waiting for authorization',
    COLLECTING_TOKEN:'Collecting Token',
    COLLECTING_PROFILE:'Collecting Profile',
    APP_NAME:'Veen',
    HELP:'Help',
    MARK_LIST_AS_READ:'Mark list as read',
    SUPPORT_EMAIL:'Support email',
    PRIVACY_POLICY:'Privacy Policy'
    };
    return lang;
};

