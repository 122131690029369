import moment from 'moment';
import {css} from 'aphrodite';
import classnames from 'classnames';
import {curry,find} from 'lodash'
import { getGlobals }  from '../common/utils'

const globals = getGlobals()

export const noop= ()=>{}

export function getFormattedTime(num){
    return moment
    .unix(num / 1000)
    .fromNow()
}

export function getFormattedDate(num){
    return moment
    .unix(num / 1000)
    .format("MMM Do YYYY");
}


//recieves style object, looks for property and its themed property
//pushes the values to array.
//Mutataes the original array
function _pushProps(styleObject,prop,themeName,arr){
    arr.push(css(styleObject[prop]))
    arr.push(css(styleObject[`${prop}_${themeName}`]))
    return arr;
}

//This methods retruns value that can can durectly be served to 'className' prop of react element
//styleObject = object created from aphrodite
//themeName = string, theme_name
//props =  array of properties to look for in styles object(Stylesheet.create). Base and themed element will be added
//Conditionals: they works just like above, however they provide us a way to pick 
//props easily. .b=>condition to  check, .t=>prop to pic if true, .f=>prop to pick if false

//Usage: 
//let css2 = tCss(settings.theme)(styles); //create HOF css2
//className={css2(['panel','left'])} =>panel,left are props of styled object
//className={css2  (['panel','left'],[{b:true,t:'red',f:'blue'}] }
//className = {css2('prop1')} => only single prop, for multiple use array
function css2(themeName,styleObject,props,conditionals=[]){
    let c= [];
    //{b:boolean,t:string,f:string}

    if(typeof(props) ==="string"){
        props = [props];
    }

    for(let i=0;i<props.length;i++){
        let prop = props[i];
        _pushProps(styleObject,prop,themeName,c)
    }

    for(let i=0;i<conditionals.length;i++){
        let prop = conditionals[i].b ?conditionals[i].t: conditionals[i].f;
        _pushProps(styleObject,prop,themeName,c)
    }

    return classnames(c);
}

export function tCss(themeName){
    //Themed css, returns HOF css2
    return curry(css2)(themeName)
}


function extractHostname(url) {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("://") > -1) {
        hostname = url.split('/')[2];
    }
    else {
        hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
}

export function getGoogleIcon(url){
    return `https://www.google.com/s2/favicons?domain=${extractHostname(url)}`;
}

export function getSubscription (subscriptions, story) {
    return find(subscriptions, {id: story.subscriptionId});
}

export function isStoryRead(story,activities){
    return !!find(activities.read, {payload: story.id})
}


export function getProduct(products,enumProduct){
    return products[enumProduct]
}

export function isPremium(products){
    for (var key in products){
        if(products[key].isActive){
            return true;
        }
    }
    return globals.platform.isWelcomePeriod() ||  false;
}