import React, { Component } from 'react';
import {StyleSheet, css} from 'aphrodite';
import classnames from 'classnames';
import Ionicon from 'react-ionicons';
import Popover from '../Popover';
import {connect} from 'react-redux'
import {changeStoriesFilter,changeSort,markCurrentStoriesAsRead, searchStory,closeSearch} from '../../redux/actions/actionCreators';
import {STORIES_LIST_FILTER, STORY_LIST_SORT_DIRECTION,THEMES} from '../../common/enums';
import {tCss} from '../utils';
import Colors from '../COLORS'
import BulkMarkAsReadPopOver from './BulkMarkAsReadPopOver'
import FilterMenuPopOver from './FilterMenuPopover'

const fontSize = "20px";
const color = '#777';

const SortMenuPopover = ({isOpen,onHide,position,textStrings,onSortClick,sort,theme} )=>{
    return <Popover
        isOpen={isOpen}
        onHide= {onHide}
        position={position}
        theme={theme}
        style={{height:''}}
    >  
        <div className={classnames(css(styles.popoverContainer))}>
            <div 
            className={classnames(css(styles.popoverRow),{[css(styles.popoverSelected)]:STORY_LIST_SORT_DIRECTION.NEWEST_FIRST === sort})}
            onClick={()=>onSortClick(STORY_LIST_SORT_DIRECTION.NEWEST_FIRST)}>{textStrings.NEWEST_FIRST}</div>
            
            <div 
            className={classnames(css(styles.popoverRow),{[css(styles.popoverSelected)]:STORY_LIST_SORT_DIRECTION.POPULAR_FIRST === sort})}
            onClick={()=>onSortClick(STORY_LIST_SORT_DIRECTION.POPULAR_FIRST)}>{textStrings.POPULAR_FIRST}</div>
            
            <div 
            className={classnames(css(styles.popoverRow),{[css(styles.popoverSelected)]:STORY_LIST_SORT_DIRECTION.OLDEST_FIRST === sort})}
            onClick={()=>onSortClick(STORY_LIST_SORT_DIRECTION.OLDEST_FIRST)}>{textStrings.OLDEST_FIRST}</div>
        </div>
    </Popover>  
}

const defaultState = {
    isSortMenuOpen:false,
    isFilterMenuOpen:false,
    isMarkAsReadMenuOpen:false,
    isSearchOpen:false,
    position:null,
    searchText:''
}

class StoryListToolbar extends Component {

    state= defaultState;
    
    toggleFilterMenu =(e)=>{
        let newState = {
            ...defaultState,
            isFilterMenuOpen: !this.state.isFilterMenuOpen,
            position:Popover.calculatePosition(e)
        };
        this.setState(newState);
    }

    toggleSortMenu =(e)=>{
        let newState = {
            ...defaultState,
            isSortMenuOpen: !this.state.isSortMenuOpen,
            position:Popover.calculatePosition(e)
        };
        this.setState(newState);
    }

    toggleMarkAsRead =(e)=>{
        let newState = {
            ...defaultState,
            isMarkAsReadMenuOpen: !this.state.isMarkAsReadMenuOpen,
            position:Popover.calculatePosition(e)
        };
        this.setState(newState);
    }

    onSortClick = (value)=>{
        this.props.changeSort(value);
        this.toggleSortMenu();
    }

    onFilterClick = (value)=>{
        if(value != this.props.filter){
            this.props.changeStoriesFilter(value);
            this.toggleFilterMenu();
        }
    }

    onMarkBulkStories = ()=>{
        this.props.markCurrentStoriesAsRead();
        this.toggleMarkAsRead();
    }

    toggleSearch =()=>{
        this.setState({
            isSearchOpen:!this.state.isSearchOpen,
            searchText:''
        });
        if(this.state.isSearchOpen){
            this.props.closeSearch();
        }
    }

    onSearchSubmit =(e)=>{
        e.preventDefault();
        // console.log(this.state.searchText);
        this.props.searchStory(this.state.searchText)
    }

    renderSearchBox(){
        let css2 = tCss(this.props.settings.theme)(styles);
        return <form className={css2(['container','searchContainer'])} onSubmit={this.onSearchSubmit}>
            <div className={css2('col')}>
                <Ionicon icon="ion-ios-search-strong" fontSize={fontSize} color={color}/>
            </div>
            <div className={css2(['col','inputContainer'])}>
                <input 
                    placeholder={this.props.textStrings.SEARCH_BAR_TEXT}
                    autoFocus={true}
                    className={css2('inputElement')} 
                    type='text'
                    onChange={(e)=>this.setState({searchText:e.target.value})}
                />
            </div>
            <div className={css2('col')} onClick={this.toggleSearch} style={{marginLeft:'auto'}}>
                <Ionicon icon="ion-android-close" fontSize={fontSize} color={color}/>
            </div> 
        </form>
    }


    renderButtons(){
        let css2 = tCss(this.props.settings.theme)(styles);
        let {filter} = this.props;
        let icons= {
            [STORIES_LIST_FILTER.ALL]:'ion-grid',
            [STORIES_LIST_FILTER.UNREAD]:'ion-record',
            [STORIES_LIST_FILTER.FAVS]:'ion-android-favorite',
            [STORIES_LIST_FILTER.BOOKMARKED]:'ion-bookmark',
        };
        let filterIcon = icons[filter || STORIES_LIST_FILTER.ALL];
        return  <div className={classnames(css(styles.container))}>
                        <div className={css2('col')}  onClick={this.toggleMarkAsRead}>
                            <Ionicon icon="ion-android-done-all" fontSize={fontSize} color={color}/>
                        </div>
                        <div className={css2('col')} onClick={this.toggleSortMenu}>
                            <Ionicon icon="ion-android-funnel" fontSize={fontSize} color={color}/>
                            {/*<CustomIcon icon="ion-android-funnel" fontSize={fontSize} color={color} />*/}
                        </div>
                        <div className={css2('col')} onClick={this.toggleFilterMenu}>
                            <Ionicon icon={filterIcon} fontSize={fontSize} color={color}/>
                        </div>
                        <div className={css2('col')} onClick={this.toggleSearch} style={{marginLeft:'auto'}}>
                            <Ionicon icon="ion-ios-search-strong" fontSize={fontSize} color={color}/>
                        </div> 
                </div>
    }

    render() {
        let {textStrings,sort,settings,filter} = this.props;
        let theme= settings.theme;
        let css2 = tCss(theme)(styles);
        return (
            <div style={{width:'100%',flex:1,display:'flex'}}>
                {this.state.isSearchOpen? this.renderSearchBox(): this.renderButtons() }
                <SortMenuPopover
                    isOpen={this.state.isSortMenuOpen}
                    onHide= {this.toggleSortMenu}
                    position={this.state.position}
                    textStrings= {textStrings}
                    onSortClick= {this.onSortClick}
                    sort={sort}
                    theme ={theme}
                 />  
                 <FilterMenuPopOver
                    theme={theme}
                    isOpen={this.state.isFilterMenuOpen}          
                    onHide={this.toggleFilterMenu}
                    position={this.state.position}
                    textStrings= {textStrings}
                    onFilterClick= {this.onFilterClick}
                    css2={css2}
                    value={filter}
                />
                <BulkMarkAsReadPopOver
                    css2= {css2}
                    theme={theme}
                    isOpen={this.state.isMarkAsReadMenuOpen}          
                    onHide={this.toggleMarkAsRead}
                    position={this.state.position}
                    textStrings= {textStrings}
                    onMarkBulkStories= {this.onMarkBulkStories}
                />
            </div>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flex: 1,
        justifyContent:'flex-start',
        borderTop:'1px solid transparent'
    },
    searchContainer:{
        borderTop:'1px solid #ddd'
    },
    [`searchContainer_${THEMES.DARK}`]:{
        borderTop:`1px solid ${Colors.BLACK5}`
    },
    col: {
        width: 60,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    inputContainer:{
        width:'100%'
    },
    inputElement:{
        width:'100%',
        height:'90%',
        border:'none',
        fontSize:'1em',
    },
    [`inputElement_${THEMES.DARK}`]:{
        backgroundColor:Colors.BLACK4,
        caretColor:Colors.WHITE1,
        color:Colors.WHITE3
    },
    popoverContainer:{
    },
    popoverRow:{
        display:'flex',
        padding:10,
        cursor:'pointer',
        alignItems:'center',
        ':hover':{
            backgroundColor:'rgba(0,0,0,0.1)'
        }
    },
    popoverSelected:{
        backgroundColor:'rgba(0,0,0,0.2)'
    },
    popoverRowLeft:{
        width:40
    },
    popoverRowRight:{
        flex:1
    }
});

const mapStateToProps = (state, ownProps) => {
    let {account} = state;
    return {
        textStrings: state.textStrings,
        sort: account.sort,
        settings: state.settings.settings,
        filter: account.storyFilter,
    }
}

export default connect(mapStateToProps, {closeSearch,changeStoriesFilter,changeSort,markCurrentStoriesAsRead,searchStory})(StoryListToolbar);