//ALL static literal are stored in this file.
import { THEMES  } from './enums';

export const isProduction = ()=> process.env.NODE_ENV === 'production'

// development configuration
const config = {
    // APP
    DEFAULT_THEME: THEMES.LIGHT,
    PROXY_URL:'https://shark-backend.herokuapp.com/relay',

    // Feedly
    VEEN_FEEDLY_CLIENT_ID:'veenhub',
    VEEN_FEEDLY_CLIENT_SECRET:'RPT9EJ4DCNQSM3E9YLN1LOXV',
    VEEN_FEEDLY_OAUTH_REDIRECT:'https://app.veenreader.com/',
}

// productions configuration
const productionConfig = {
    // Feedly
    VEEN_FEEDLY_OAUTH_REDIRECT:'https://app.veenreader.com',
}

// We will export this
let finalConfig = config;

// in production env, overrite dev-config with prod config
if(isProduction()){
    finalConfig = { ...finalConfig, ...productionConfig}
}

export default finalConfig;