import React from 'react';
import { connect } from 'react-redux'
import Converters from '../entities/converter.web'
import {selectFolder,selectSubscription,toggleFolder} from '../redux/actions/actionCreators';
import {includes} from 'lodash';
import {STORIES_LIST_FILTER} from '../common/enums';
import {StyleSheet} from 'aphrodite';
import Ionicon from 'react-ionicons';
import {tCss, getGoogleIcon} from './utils'
import COLORS from './COLORS';
import styled from 'styled-components'
import _ from 'lodash'

const fontSize = "15px";
const barBorderColor =COLORS.BLUE1;

const StaticFolder = ({title,selectFolder,folder,selectedFolderId,icon,count,css2})=>{
    const isSelected = selectedFolderId === folder.id;
    return <div className={css2(['staticFolderRow'],[{b:isSelected,t:'staticFolderRowSelected',f:''}])} onClick={()=>{selectFolder(folder)}}>
        <div className={css2(['borderBar'],[{b:isSelected,t:'borderBarSelected',f:''}])}></div>
        <div><Ionicon  icon={icon} fontSize={fontSize} className={css2(['iconStyle'])} /></div>
        <div className={css2(['staticFolderTitle'],[{t:'staticFolderTitleSelected', b:isSelected,f:''}])}>
            <span>{title}</span>
            <span className={css2(['staticFolderCount'])}>{count || ''}</span>  
        </div>
    </div>
}

const FolderLabel = ({folder,selectFolder,toggleFolder,isOpen,number,isSelected,css2})=>{
    let iconName= isOpen ? 'ion-ios-arrow-down':'ion-ios-arrow-left'
    return <div className={css2(['folderRow'])}>
                <div className={css2(['borderBar'],[{b:isSelected,t:'borderBarSelected',f:''}])}></div>
                <div className={css2(['folderTitle'])}  onClick={()=>selectFolder(folder)}>
                    <span className={css2([],[{b:isSelected,t:'folderTitleSelected',f:''}])}>{folder.label}</span>
                    <span className={css2(['folderCount'])}>{number}</span>
                </div>
                <div
                    onClick={()=>toggleFolder(folder)} 
                    className={css2(['folderIconContainer'])}
                >
                    <Ionicon icon={iconName} fontSize={fontSize} className={css2(['iconStyle'])} />
                </div>
            </div>
}


const SubscriptionView = ({subscription,unreadCount,isSelected,selectSubscription,css2})=>{
    return <div 
                onClick={()=>selectSubscription(subscription)}
                className={css2(['subscriptionRow'],[{b:isSelected,t:'subscriptionRowSelected',f:''}])}>
                <div className={css2(['subscriptionIconContainer'])}>
                    <img className={css2(['subscriptionIconImg'],[{b:isSelected,t:'',f:'grayScale'}])} src={subscription.iconUrl || getGoogleIcon(subscription.website)} alt='' />
                </div>
                <div className={css2(['subscriptionTitleContainer'])}>
                    <span  className={css2(['subscriptionTitle'],[{b:isSelected,t:'subscriptionTitleSelected',f:''}])}>
                        {wrapSubscprionTitleName(subscription.title)} 
                    </span>
                    <span className={css2(['subscriptionCount'])}>
                        {unreadCount || ''}
                    </span>
                </div>
        </div>
}

const FolderList = (props)=>{
    let {selectFolder,selectSubscription,toggleFolder,unreadCount,
        openFolderIds,selectedFolderId,selectedSubscriptionId,textStrings,settings}  = props;
    let folders = Converters.subscriptionDocsToFolders(props.subscriptions);
    let css2= tCss(settings.theme)(styles);

    let foldersDom =  folders.map((folder,i)=>{
        let isOpen = includes(openFolderIds,folder.id);
        let folderUnread = 0;
        let subscriptionDom = folder.subscprions.map((sub,j)=>{
            let _unreaderCount = parseInt(unreadCount[sub.id],10) || 0;
            folderUnread += _unreaderCount;
            return <SubscriptionView 
                        key ={`${sub.id}-${folder.id}`}
                        subscription={sub} 
                        unreadCount = {_unreaderCount}
                        selectSubscription = {selectSubscription}
                        isSelected = {selectedSubscriptionId === sub.id}
                        css2 ={css2}
                    />
        });

        return <div key={folder.id}>
                <FolderLabel 
                    selectFolder ={selectFolder}
                    toggleFolder= {toggleFolder}
                    folder = {folder}
                    isOpen= {isOpen}
                    number={folderUnread}
                    isSelected= {selectedFolderId === folder.id}
                    css2={css2}
                />
                {isOpen && subscriptionDom}
        </div>
     });

    return (<div  className={css2(['container'])}>
            <StaticFolder css2={css2} title={textStrings.ALL}       icon="ion-grid"  selectFolder={selectFolder} folder={{id:STORIES_LIST_FILTER.ALL}} selectedFolderId={selectedFolderId}/>
            <StaticFolder css2={css2} title={textStrings.UNREAD}    icon="ion-record" count={unreadCount[textStrings.UNREAD]} selectFolder={selectFolder} folder={{id:STORIES_LIST_FILTER.UNREAD}} selectedFolderId={selectedFolderId} />
            <StaticFolder css2={css2} title={textStrings.FAVOURITE} icon="ion-android-favorite" count={unreadCount[textStrings.FAVOURITE]} selectFolder={selectFolder} folder={{id:STORIES_LIST_FILTER.FAVS}} selectedFolderId={selectedFolderId} />
            <StaticFolder css2={css2} title={textStrings.BOOKMARKS} icon="ion-bookmark" count={unreadCount[textStrings.BOOKMARKS]} selectFolder={selectFolder} folder={{id:STORIES_LIST_FILTER.BOOKMARKED}} selectedFolderId={selectedFolderId} />
            <Separator />
            {foldersDom}
        </div>
    );
}


const styles= StyleSheet.create({
    container:{
        minHeight:'100%',
        zIndex:10,
        fontSize:14
    },
    grayScale:{
        filter:'grayscale(100%)'
    },
    iconStyle_LIGHT:{
        fill: COLORS.BLACK6
    },
    iconStyle_DARK:{
        fill: COLORS.WHITE_TEXT2
    },
    staticFolderRow:{
        display:'flex',
        alignItems:'center',
        cursor:'pointer',
    },
    borderBar:{
        width:4,
        marginRight:10,
        height:30,
    },
    borderBarSelected:{
        backgroundColor:barBorderColor,
    },
    staticFolderTitle:{
        padding:12,
        fontWeight:300,
    },
    staticFolderTitleSelected:{
        fontWeight:600
    },
    staticFolderCount:{
        paddingLeft:5,
        opacity:'0.4',
        fontSize:'0.8em'
    },
    folderRow:{
        display:'flex',
        alignItems:'center',
        cursor:'pointer',
        color: '#999',
        fontSize: 12,
    },
    folderTitle:{
        fontWeight:600,
        flex:1,
    },
    folderTitleSelected:{
        fontWeight:600,
    },
    folderCount:{
        paddingLeft:5,
        opacity:'0.4',
        fontSize:'0.8em',
    },
    folderIconContainer:{
        padding:'10px 15px 10px 10px',
        opacity:'0.4',
    },
    subscriptionRow:{
        display:'flex',
        alignItems:'center',
        cursor:'pointer',
        paddingLeft:24,
        borderBottom:'1px solid transparent',
        borderTop:'1px solid transparent',
    },
    subscriptionRowSelected_LIGHT:{
        borderBottom:'1px solid #cdd2da',
        borderTop:'1px solid #cdd2da',
        backgroundColor:'rgba(209,219,232,0.67)'
    },
    subscriptionRowSelected_DARK:{
        borderBottom:'1px solid #555',
        borderTop:'1px solid #555',
        backgroundColor:'rgba(0,0,0,0.30)'
    },
    subscriptionIconContainer:{
        width:25,
    },
    subscriptionIconImg:{
        width:20,
        height:20,
        borderRadius:10
    },
    subscriptionTitleContainer:{
        flex:1,
        padding:10,
    },
    subscriptionTitle:{
        fontWeight:400,
        overflow:'hidden'
    },
    subscriptionTitleSelected:{
    },
    subscriptionCount:{
        paddingLeft:5,
        opacity:'0.4',
        fontSize:'0.8em',
    }
});

const Separator = styled.div`
    background-color:transparent;
    height:16px;
`

const mapStateToProps = (state, ownProps) => {
    return {
        subscriptions: state.subscriptions,
        openFolderIds:state.account.openFolderIds,
        selectedFolderId:state.account.selectedFolderId,
        selectedSubscriptionId:state.account.selectedSubscriptionId,
        textStrings:state.textStrings,
        unreadCount: state.unreadCount,
        settings:state.settings.settings
    }
}

const wrapSubscprionTitleName = (name='')=>{
    if(name.length>=20){
        let words = name.split(" ")
        let lastWord = _.last(words).split("-")
        name = `${_.first(words)}....${_.last(lastWord)}`
    }
    return name;
}


export default connect(mapStateToProps,{selectFolder,selectSubscription,toggleFolder})(FolderList)