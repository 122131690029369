import React, { Component } from 'react';
import Logo from '../images/logo.png'

const TopBar = ()=>(
    <div style={styles.container}>
        <div style={styles.logoContainer}>
            <img src={Logo}  style={styles.logo} />
        </div>
    </div>
)

const styles = {
    container:{
        display:'flex',
        height:100,
        color:'#fff',
    },
    logoContainer:{
        width:100,
        'alignItems': 'center',
        justifyContent:'center',
        display:'flex'
    },  
    logo:{
        width:75
    },
}


export default TopBar;