
export default function(key){
    let lang ={
    key:key,
    WAIT:'कृपया प्रतीक्षा करें',

    //Add Strings Here
    WELCOME_MSG : "वीन रीडर आपका स्वागत  करता है",
    ERROR : "एरर",
    SUCCESS:"Success",

    OR: "या",
    FEEDLY : "फीडली",
    FACEBOOK:"फेसबुक",
    FULLNAME:"पूरा नाम",
    START_WITH_LOCAL_ACCOUNT : "स्थानीय खाता के साथ शुरू करें",
    TWITTER :"ट्विटर",
    LOCAL:"स्थानीय",


    //Accounts
    SWITCH_ACCOUNT:"खाता बदलिये",
    ADD_NEW : "नया खाता",
    REMOVE_ACCOUNT:"हटाये",
    REMOVE_ACCOUNT_SCREEN:"खाता हटाये",
    UNDO_REMOVE:"वापस  लाएं",
    CHOOSE_A_SERVICE:"एक सेवा चुनें",
    CREATE_LOCAL_ACCOUNT:"स्थानीय खाता बनाये",
    CREATE:"बनाये",

    BACK:'पिछला',
    ALL:'सारे',
    FAVOURITE:'पसंदीदा',
    BOOKMARKS:'बुक्मार्क',
    THEME:'थीम'
    
    };
    

    //
    return lang;
}