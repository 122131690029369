import React from 'react';
import Ionicon from 'react-ionicons'

const fontSize = "20px";
const color = '#999';

const NavButton = ({visible,position,onClick,left,ui,scrollPosition,changeStory,iconName})=>{
    if(!visible){
        return null;
    }
    let style ={
        position:'absolute',
        top:`calc(30vh + ${scrollPosition}px)`,
        border:'none',
        background:'transparent'
    }
    if(position==="left"){
        style.left=0;
        style.padding='15px 15px 15px 5px';
    }else{
        style.right = 0;
        style.padding='15px 5px 15px 15px';
    }
    return <button style={style} onClick={changeStory}>
        <Ionicon
        // style={iconStyle}
            icon={iconName}
            fontSize={fontSize}
            color={color}/>
    </button>
}


export default NavButton