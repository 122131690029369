import React  from 'react';
import Ionicon from 'react-ionicons';
import { getGlobals }  from '../../common/utils'

const globals = getGlobals()

const LocalAccountForm = ({createAccount,textStrings,css2,changeScreen,switchAccount} )=>{
    let fullName='';
    return <div className={css2('container')}>
            <div className={css2('header')}>{textStrings.CREATE_LOCAL_ACCOUNT}</div>
            <div className={css2('content')} style={{padding:10}}>
                <input 
                    onChange={(e)=>fullName = e.target.value}
                    type='text' placeholder={textStrings.FULLNAME} style={{
                    width:'100%',padding:10,fontSize:'1em'
                }}/>
            </div>     
            
            <div className={css2(['row'])}>
            <div className={css2(['rowLeft','flex1'])}>
                    <div onClick={()=>changeScreen('ServicesList')} className={css2('linkButton')} style={{
                    }}>
                    <Ionicon className={css2('icon')} icon="ion-ios-arrow-back" fontSize={'16px'} />
                    Back
                    </div>
                </div>
                <div className={css2(['rowLeft','flex1'])}>
                    <div onClick={()=>{
                        globals.services.local.startLoginFlow({onCompleted:createAccount},fullName);
                    }} className={css2('button')} style={{
                    }}>Create</div>
                </div>
            </div>



        </div>
}

export default LocalAccountForm;