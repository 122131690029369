
import axios from 'axios';

//Internals
const __INSTALL_TIME_KEY = '___i___';
const WELCOME_DAYS= 15;

class Platform {  
    _PROXY_URL = null //(set during init, if needed)
    
    //<START>Following methods should overridden only if these is some special reason 
    getKey(){
        return this.key;
    }

    //A subclass if overrides init should call super.init()
    init(options){
        let { proxyUrl} = options

        // Set proxy url
        this._PROXY_URL = proxyUrl;

        //Initialize Platform
        this.addInstallTime();
    }

    addInstallTime(){
        //This saves peristance information when was app installed on user device
        if (this.isStorageSupported() && !this.getInstallTime()) {
            // localStorage.setItem(installTimeKey,Date.now());
            this.setValueInStorage(__INSTALL_TIME_KEY,Date.now());
        }
    }

    getInstallTime(){
        return this.getValueFromStorage(__INSTALL_TIME_KEY);
    }

    isWelcomePeriod(){
        let TimeInDays = WELCOME_DAYS;
        if(this.isStorageSupported()){
            let diff = Date.now() -  this.getInstallTime();
            if(diff < TimeInDays*24*60*60*1000){
                return true;
            }
        }
        return false;
    }
    
    //<END>Following methods should overridden only if these is some special reason 
    

    //Subclass(Platforms) should override these props/methods start
    //Most below methods are either fake or empty
    key =  Platform.keys.DEFAULT
    isStorageSupported(){return true;} //local storage not DB. use full for storing things like install time etc
    setValueInStorage(key,value){}
    getValueFromStorage(key){}
    copyToClipBoard(text){console.log('Implement copy to clipboard');}
    getDimensions (){console.log('getDimensions');}
    launchAuthFlow(formattedUrl, callbacks,redirectUrl){console.log('launchAuthFlow');}
    //Subclass should override these props/methods end

    loadLicense(products){
        //input=>products=>array of products that's defined in reducer
        //return=> promise.
        //That resolves to an array of products
        //two props (isActive, price) are modified in original product
        //Price should be formatted price, that can be displayed directly to user
        return new Promise((resolve,reject)=>{
            resolve(products);
        })
    }

    purchaseProduct(product){
        //input=>a product.
        //return=>Promise
        //It should start a purchase_flow.
        //It should modify the enviornment such that
        //after successful purchase, when loadlicense is invoked correct licenses are loaded
        //promise may not resolve to any data
        return new Promise(function(resolve,reject){
            product.isActive = true;
            resolve();
        })
    } 

    hideTitleBar(){
        //Doesn't technically hides title bar just makes it transparent
        //If a platform wants to implement it, it can.
        //Used in windows
    }
    updateAppTitlebarColor(){
        //Updates color of title bar.
        //Used in windows
    }

    getAccentColorAsRGBA(){
        return {
            r:0,g:99,b:177,a:1
        }
    }

    updateBadgeNumber(strNum,title,rawNumber){
        //str  number is formatted, its 99+ if its greater than 99,
        //rawNumber is integer
        //Title is usefull in web as of now
    }
    clearBadgeNumber(title){
         //Title is usefull in web as of now
    }

    badgeIconSyncing(){

    }
    badgeIconClear(){
        
    }

    makeWebRequest(options){
        return axios(options)
    }

    getProxyUrl(){
        return this._PROXY_URL;
    }

    isMac(){
        return this.key ===Platform.keys.MAC
    }

    isWeb(){
        return this.key ===Platform.keys.WEB
    }
}

Platform.keys= {
    DEFAULT:'default',
    WEB:'web',
    WIN:'win',
    IOS:'ios',
    ANDROID:'android',
    MAC:'mac'
}

export default Platform;