import React, { Component } from 'react';
import {connect} from 'react-redux'
import {updateStatus,clearStatus,hideStatus} from '../../redux/actions/actionCreators'
import {StyleSheet} from 'aphrodite'
import {tCss} from '../utils'
import {THEMES} from '../../common/enums'
import Ionicon from 'react-ionicons';
import COLORS from '../COLORS'


const fontSize = "25px";

const StatusView= ({status,index,css2,hideStatus})=>{
    return <div className={css2('container')} style={{
        bottom: 30 + index*60
    }}>
            <div className={css2('left')}>{status.text}</div>
            <div className={css2('right')} onClick={()=>hideStatus(status.id)}>
                <Ionicon 
                    fontSize={fontSize} 
                    className={css2('iconClass')} 
                    icon="ion-android-close"
                />
            </div>
    </div>
}

class Status extends Component {
    render() {
        let {status,settings,hideStatus} = this.props;
        settings= settings.settings;
        let css2=  tCss(settings.theme)(styles);
        if(status.length<1){
            return null
        }
        return (
            <div>
                {status.filter((s)=>!s.hidden).map((s,i)=>{
                    return <StatusView 
                        css2={css2} 
                        key={s.id} 
                        hideStatus={hideStatus}
                        index={i} status={s} />
                })}
            </div>
        );
    }
}

const styles= StyleSheet.create({
    container:{
        position:'absolute',        
        width:300,
        right:10,
        height:45,
        display:'flex',
        alignItems:'center',
        borderRadius:3,
        zIndex:1,
    },
    left:{
        display:'flex',
        alignItems:'center',
        flex:1,
        height:'100%',
        paddingLeft:10
    },
    right:{
        display:'flex',
        alignItems:'center',
        width:50,
        height:'100%',
        justifyContent:'center'
    },
    [`container_${THEMES.LIGHT}`]:{
        backgroundColor:'#444',
        color:'#f4f4f4',
    },
    [`container_${THEMES.DARK}`]:{
        backgroundColor:'#eee',
        color:'#101010',
    },
    iconClass_LIGHT:{
        fill:COLORS.WHITE_TEXT2
    },
    iconClass_DARK:{
        fill:COLORS.BLACK4
    }
});


const mapStateToProps = (state, ownProps) => {
    return {
        status: state.status,
        settings:state.settings
    }
}

export default connect(mapStateToProps,{updateStatus,clearStatus,hideStatus})(Status)
