import {UPDATE_STATUS,CLEAR_STATUS,HIDE_STATUS} from '../actions/names'
import {updateStatus} from '../actions/actionCreators'
import {findIndex} from 'lodash'
// id,type,text,hideAfter,hidden
//hideAfter:time in seconds

//Hidden status exists in system but they are not visible to user
//, they remain hidden even after update

const initialState = [
    // updateStatus(1,'','Hello_1').payload,
    // updateStatus(2,'','Hello_3').payload,
];

const reducer =(state=initialState,action)=>{
    if(action.type === UPDATE_STATUS){
        let _status  = action.payload;
        let index = findIndex(state,{id:_status.id});
        if(index>-1){
            //replace
            return state.map((s)=>{
                if(s.id === _status.id){
                    return _status
                }
                return s;
            })
        }else{
            return state.concat(_status)
        }
    }

    if(action.type === CLEAR_STATUS){
        return state.filter((s)=>{
            return s.id != action.payload
        });
    }
    if(action.type === HIDE_STATUS){
        return state.map((s)=>{
            if(s.id === action.payload){
                return {...s,hidden:true}
            }
            return s;
        })
    }

    return state;
}

export default reducer;