import React, { Component } from 'react';
import LeftPanel from './LeftPanel';
import MiddlePanel from './MiddlePanel';
import RightPanel from './RightPanel';
import {MIDDLE_PANEL_2_WIDTH} from './styles'
import SubscriptionSystemUI from '../SubscriptionSystem'


class Layout2 extends Component {
    componentWillUnmount(){
        this.props.resetLeftPanel();
    }
    render() {
        let {css2,toggleCatalogue,story,isSubscriptionSystemOpen,smallLeftPanel,toggleLeftPanel,toggleSettings,toggleSubscriptions,toggleAccounts,layout} = this.props;
        return (
            <div className={css2(['container'])}>
                <LeftPanel
                        css2={css2}
                        smallLeftPanel ={smallLeftPanel}
                        toggleSettings={toggleSettings} 
                        toggleSubscriptions={toggleSubscriptions} 
                        toggleAccounts = {toggleAccounts}
                        toggleLeftPanel ={toggleLeftPanel}
                        floating={true}
                        toggleCatalogue={toggleCatalogue}
                    />
                <SubscriptionSystemUI hidden={!isSubscriptionSystemOpen} onClose={toggleSubscriptions} />
                <MiddlePanel 
                        width={MIDDLE_PANEL_2_WIDTH}
                        css2={css2}
                        containerCss ='middle_2'
                        layout={layout}
                        style={{
                            width:MIDDLE_PANEL_2_WIDTH - 40,
                            marginLeft:!smallLeftPanel?40:0
                        }}
                        hidden={isSubscriptionSystemOpen}
                />
                <RightPanel 
                    css2={css2}
                    backButton={false}
                    hidden={isSubscriptionSystemOpen}
                    story={story}
                />
            </div>
        );
    }
}

export default Layout2;