import React, { Component } from 'react';

const Video = ({className})=>{
    return <div className={className}>
            <iframe width="900" 
                height="650"
                frameBorder="0" 
                allowFullScreen="allowfullscreen"
                src="https://www.youtube.com/embed/0Ttf00QtDfA?autoplay=1">
            </iframe>
    </div>
}

export default Video;