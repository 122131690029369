import React from 'react';
import Ionicon from 'react-ionicons';
import Avatar from './Avatar'

const AccountsList = ({textStrings,css2,accounts,changeScreen,removeAccount,account} )=>{
    return <div className={css2('container')}>
            <div className={css2('header')}>{textStrings.REMOVE_ACCOUNT_SCREEN}</div>
            <div className={css2('content')}>
                {accounts.map((acc)=>{
                    return <div key={acc.id} className={css2(['row'])}>
                        <div className={css2('rowLeft')} style={{width:40}}>
                            <Avatar css2={css2}  image={acc.profile.picture} icon="ion-android-person"/>
                        </div>
                        <div className={css2(['rowRight','flex1'])}>
                            <div>{acc.profile.fullName}</div>
                            <div className={css2('textLight')}>{acc.type}</div>
                        </div>
                        {acc.id !== account.id?<div 
                            onClick={()=>{removeAccount(acc);changeScreen('AccountsList');}}
                            className={css2(['rowLeft','linkButton'])} style={{width:70}}>
                        Remove</div>:''}
                    </div>
                })}
            </div>


            <div className={css2(['row'])}>
                <div className={css2(['rowLeft','flex1'])}>
                    <div onClick={()=>changeScreen('AccountsList')} className={css2('linkButton')} style={{
                    }}>
                    <Ionicon className={css2('icon')}  icon="ion-ios-arrow-back" fontSize={'16px'} />
                    Back
                    </div>
                </div>
                <div className={css2(['rowRight','flex1'])}>
                
                </div>
            </div>


        </div>
}

export default AccountsList;