import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Platform from './platform/Platform.web'
import DB from './db/index';
import services from './services/';



ReactDOM.render(<App platform={new Platform()} database={DB} services={services} />, document.getElementById('root'));
