import {combineReducers} from 'redux';
import appStateReducer from './appState';
import accountReducer from './account';
import settings from './settings';
import subscriptions from './subscriptions';
import stories from './stories';
import searchedSubscriptions from './searchedSubscriptions';
import story from './story';
import activities from './activities';
import textStrings from './textStrings';
import accounts from './accounts';
import unreadCount from './unreadCount'
import ui from './ui'
import status from './status'
import license from './license'


// const noop= (state,action)=>{ 
//     console.log(action.type);
//     return {};
// }

const rootReducer = {
    appState:appStateReducer,
    account:accountReducer,
    accounts,
    activities, 
    stories:stories,
    settings:settings,
    subscriptions: subscriptions,
    searchedSubscriptions:searchedSubscriptions,
    story:story,
    textStrings: textStrings,
    unreadCount,
    ui,
    status,
    license
};

export default  combineReducers(rootReducer);
