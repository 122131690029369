import Platform from './PlatformBase'
import {
    launchAuthFlow
} from './webLoginFlow';

let _clipBoard = null;
let _proxyUrl= null;

class PlatformWeb extends Platform {
    key = Platform.keys.WEB

    init(options) {
        super.init(options);

        let { proxyUrl} = options
        _proxyUrl= proxyUrl;

        // //Create a div for clipboard
        _clipBoard = document.createElement('div');
        _clipBoard.setAttribute('id', 'clipboard');
        _clipBoard.style.display = "none";
        // document.body.append(_clipBoard);
        document.body.appendChild(_clipBoard);

        //For Clipboard API
        document.addEventListener('copy', function (e) {
            e.clipboardData.setData('text/plain', _clipBoard.innerHTML);
            // We want our data, not data from any selection, to be written to the clipboard
            e.preventDefault();
        });
    }

    getDimensions() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    isStorageSupported() {
        if (typeof (Storage) !== "undefined") {
            return true;
        }
        return false;
    }

    setValueInStorage(key, value) {
        localStorage.setItem(key, value);
    }

    getValueFromStorage(key) {
        return localStorage.getItem(key);
    }

    launchAuthFlow(formattedUrl, callbacks, redirectUrl) {
        return launchAuthFlow(formattedUrl, callbacks, redirectUrl);
    }

    updateBadgeNumber(num, title) {
        document.title = `(${num}) ${title}`
    }

    clearBadgeNumber(title) {
        document.title = title;
    }
    copyToClipBoard(text) {
        //Update text inside clipboard element
        _clipBoard.innerHTML = text;
        // create a Range object
        var range = document.createRange();
        range.selectNode(_clipBoard);
        window.getSelection().addRange(range);
        try {
            //execute the copy command  
            var successful = document.execCommand('copy');
            // var msg = successful ? 'successful' : 'unsuccessful';  
            // console.log('Copy email command was ' + msg);  
        } catch (err) {
            console.log('Oops, unable to copy');
        }
        // Remove the selections - NOTE: Should use
        // removeRange(range) when it is supported  
        window.getSelection().removeAllRanges();


        // var copyEvent = new ClipboardEvent('copy', {
        //     dataType: 'text/plain',
        //     data: text,
        //     clipboardData:text
        // });
        // document.dispatchEvent(copyEvent);
        // // document.execCommand('copy');
    }

    makeWebRequest(options={}) {
        let {
            corsAllowed,
            ...restOptions
        } = options;
        let finalOptions = restOptions;
        if (!corsAllowed) {
            finalOptions = {
                url: _proxyUrl,
                data: restOptions,
                method: 'POST'
            }
        }
        return super.makeWebRequest(finalOptions)
    }
}

export default PlatformWeb