import React from 'react';
import FolderListSmall2 from '../FolderListSmall'
import FoldersList from '../FolderList'
import UserInfo from '../UserInfo'
import AppToolbar from '../AppToolbar'
const LEFT_COLUMN_FULL_WIDTH= 220;



const LeftPanel = ({css2,smallLeftPanel,toggleCatalogue,floating,toggleSettings,toggleSubscriptions,toggleAccounts,toggleLeftPanel})=>{
    if(smallLeftPanel){
        return (
                <FolderListSmall
                    toggleCatalogue={toggleCatalogue}
                    toggleSettings={toggleSettings} 
                    toggleSubscriptions={toggleSubscriptions} 
                    toggleAccounts = {toggleAccounts}
                    toggleLeftPanel ={()=>{toggleLeftPanel(smallLeftPanel)}}
                />
        );
    }else{
        let floatingCSS = floating ? floatingCss : {}
        return(
            <div 
                style={{ 
                    width: LEFT_COLUMN_FULL_WIDTH,
                    ...floatingCSS
                }} className={css2(['panel','left'])}>
                <div className={css2('bar')}>
                    <UserInfo 
                        toggleLeftPanel ={()=>{toggleLeftPanel(smallLeftPanel)}} 
                        toggleCatalogue={toggleCatalogue} />
                </div>
                <div className={css2(['content'])}>
                    <FoldersList />
                </div>
                <div className={css2(['bar'])}>
                    <AppToolbar 
                        toggleSettings={toggleSettings} 
                        toggleSubscriptions={toggleSubscriptions} 
                        toggleAccounts = {toggleAccounts}
                    />
                </div>
            </div>)
    }
}

const FolderListSmall = (props)=> <div 
             style={{ width:40, height:'100vh', backgroundColor:'#e9ebee'}}>
    <FolderListSmall2 {...props} />
</div>

const floatingCss = {
    top:0,
    left:0,
    height:'100vh',
    position:'absolute',
}

export default LeftPanel;