import React, { Component } from 'react';
import {tCss} from '../utils'
import {StyleSheet} from 'aphrodite'
import Ionicon from 'react-ionicons';
import COLORS from '../COLORS'
import {trim,isEmpty} from 'lodash'
import {THEMES} from '../../common/enums'


const fontSize = "20px";
class InputForm extends Component {
    state ={
        text:''
    }

    handleInputChange =(e)=>{
        this.setState({
            text:e.target.value
        });
    }
    clearText =()=>{
        this.setState({
            text:''
        });
    }

    handleSubmit =(e)=>{
        e.preventDefault();
        let {text} = this.state;
        text =  trim(text)
        if(!isEmpty(text)){
            this.props.searchSubscriptions(this.state.text);
        }
    }
    
    render() {
        let text = '';
        let {textStrings,theme}  = this.props;
        let css2=  tCss(theme)(styles);
        return (
            <form className={css2('container')} onSubmit={this.handleSubmit}>
                    <div className={css2('left')}>
                        <Ionicon  icon="ion-android-search" fontSize={fontSize} className = {css2(['iconClass'])}/>
                    </div>
                    <div className={css2('middle')}>
                        <input type="text" 
                            autoFocus={true} 
                            placeholder={textStrings.TYPE_TITLE_URL}
                            className={css2('input')}
                            value={this.state.text} 
                            onChange={this.handleInputChange} />
                    </div>
                    <div onClick={this.clearText} className={css2('right')}>
                        <Ionicon  
                            icon="ion-android-close" 
                            fontSize={fontSize} 
                            className = {css2('iconClass',[{
                                b:this.state.text ==='',
                                t: 'hidden',
                                f:''
                            }])}/>
                    </div>
            </form>            
        );
    }
}


const styles = StyleSheet.create({
    container:{
        display:'flex',
        marginBottom:30,
        justifyContent:'center',
        width:'100%',
        padding:10,
    },
    [`container_${THEMES.LIGHT}`]:{
        borderBottom:'1px solid rgba(0,0,0,0.2)'
    },
    [`container_${THEMES.DARK}`]:{
        borderBottom:'1px solid rgba(0,0,0,0.4)'
    },
    left:{
        padding:10,
        paddingRight:0,
        width:40,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    },
    [`left_${THEMES.LIGHT}`]:{
        backgroundColor:'#fff'
    },
    [`left_${THEMES.DARK}`]:{
        backgroundColor:'#000',
    },
    middle:{
        flex:1,
        display:'flex',
        alignItems:'center',
    },
    [`middle_${THEMES.LIGHT}`]:{
        backgroundColor:'#fff'
    },
    [`middle_${THEMES.DARK}`]:{
        backgroundColor:'#000',
    },
    right:{
        padding:10,
        width:40,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    },
    [`right_${THEMES.LIGHT}`]:{
        backgroundColor:'#fff'
    },
    [`right_${THEMES.DARK}`]:{
        backgroundColor:'#000',
    },
    input:{
        width:'100%',
        padding:10,
        fontSize:'1em',
        border:'none'
    },
    [`input_${THEMES.DARK}`]:{
        backgroundColor:'#000',
        caretColor:COLORS.WHITE1,
        color:COLORS.WHITE3
    },
    button:{
        marginLeft:30,
        width:100
    },
    iconClass:{
        fill:'#888',
        fillOpacity:'0.9',
    },
    iconClass_LIGHT:{
        fill:COLORS.BLACK4
    },
    iconClass_DARK:{
        fill:COLORS.WHITE_TEXT2
    },
    hidden:{
        visibility:'hidden'
    }
});

export default InputForm;