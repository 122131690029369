import React, { Component } from 'react';
import {StyleSheet} from 'aphrodite';
import Ionicon  from 'react-ionicons'
import {connect} from 'react-redux'
import {tCss} from './utils'
import COLORS from './COLORS';
import BuyButton from './Monetisation/BuyButton'

const fontSize = "25px";

class UserInfo extends Component {
    render() {
        let {settings,toggleLeftPanel,toggleCatalogue} = this.props;
        let css2= tCss(settings.theme)(styles);
        return (
            <div className={css2(['container'])}>
                <div
                    onClick = {toggleLeftPanel} 
                    className={css2(['left'])}
                >
                    <Ionicon 
                        icon="ion-navicon" 
                        fontSize={fontSize} 
                        className={css2(['iconClass'])} 
                    />
                </div>
                <div className={css2(['middle'])}></div>
                <div className={css2(['right'])}>
                    <BuyButton toggleCatalogue={toggleCatalogue}/>
                </div>
            </div>
        );
    }
}

const styles= StyleSheet.create({
    container:{
        display:'flex',
        flex:1,
    },
    iconClass_LIGHT:{
        fill:COLORS.BLACK_TEXT1
    },
    iconClass_DARK:{
        fill:COLORS.WHITE_TEXT2
    },
    left:{
        width:40,
        alignItems:'center',
        justifyContent:'center',
        display:'flex',
    },
    middle:{
        display:'flex',
        flex:1,
        alignItems:'center',
        justifyContent:'center'
    },
    right:{
        width:40,
        alignItems:'center',
        justifyContent:'center',
        display:'flex',
    }
});

const mapStateToProps = (state, ownProps) => {
    return {
        settings:state.settings.settings
    }
}

export default connect(mapStateToProps)(UserInfo)