import React, { Component } from 'react';
import LeftPanel from './LeftPanel';
import MiddlePanel from './MiddlePanel';
import {MIDDLE_PANEL_3_WIDTH} from './styles'
import RightPanel from './RightPanel';
import SubscriptionSystemUI from '../SubscriptionSystem'

class Layout3 extends Component {
    componentWillUnmount(){
        this.props.resetLeftPanel();
    }
    render() {
        let {css2,toggleCatalogue,story,isSubscriptionSystemOpen,smallLeftPanel,toggleLeftPanel,toggleSettings,toggleSubscriptions,toggleAccounts,layout} = this.props;
        return (
            <div className={css2(['container'])}>
                    <LeftPanel
                        css2={css2}
                        smallLeftPanel ={smallLeftPanel}
                        toggleSettings={toggleSettings} 
                        toggleSubscriptions={toggleSubscriptions} 
                        toggleAccounts = {toggleAccounts}
                        toggleLeftPanel ={toggleLeftPanel}
                        floating={false}
                        toggleCatalogue={toggleCatalogue}
                    />
                    <SubscriptionSystemUI hidden={!isSubscriptionSystemOpen} onClose={toggleSubscriptions} />
                    <MiddlePanel 
                        hidden={isSubscriptionSystemOpen}
                        width={MIDDLE_PANEL_3_WIDTH}
                        css2={css2}
                        containerCss ='middle'
                        layout={layout}
                    />
                    <RightPanel 
                        hidden={isSubscriptionSystemOpen}
                        css2={css2}
                        backButton={false}
                        story={story}
                    />
            </div>
        );
    }
}

export default Layout3;