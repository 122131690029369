const isEnclosureAnImage = (item) => {
    //Only href defined assume its images
    
    if (item.href && !item.type) {
        return true;
    }

    if (item.href && item.type && item.type.substr(0, 5) == "image") {
        return true
    }


    return false
}


const getStoryThumbnail = (story) => {
    let {thumbnail, visual, enclosure} = story.payload;

    // if(enclosure){
    //     console.log(enclosure);
    // }


    //First: check in thumbnail
    if (thumbnail && thumbnail.length > 0 && thumbnail[0].url && thumbnail[0].url != "none") {
        return thumbnail[0].url
    }

    //check in visual
    if (visual && visual.url && visual.url != "none") {
        return visual.url;
    }

    //loop in enclosure
    if (enclosure && enclosure.length > 0) {
        for (let i = 0; i < enclosure.length; i++) {
            let item = enclosure[i];
            if (isEnclosureAnImage(item)) {
                return item.href;
            }
        }
    }
}

export  {isEnclosureAnImage,getStoryThumbnail}