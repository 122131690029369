import React from 'react';

const Spacer = ({textStrings}) =>{
    return (<div style={styles.container}>
        <span  style={styles.line}></span>
        <span style={styles.or}>{textStrings.OR}</span>
        <span style={styles.line}></span>
    </div>)
}


let styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:5
    },
    line: {
        width: '43%',
        display: 'block',
        height: 1,
        backgroundColor: '#444'
    },
    or: {
        width: '14%',
        textAlign: 'center',
        fontSize: 20,
        color: '#777'
    }
}

export default Spacer;