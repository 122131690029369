import React, { Component } from 'react';
import {connect} from 'react-redux'
import Ionicon  from 'react-ionicons'
import {StyleSheet} from 'aphrodite';
import COLORS from './COLORS';
import {tCss} from './utils'
import {startSync} from '../redux/actions/actionCreators';
import {THEMES} from '../common/enums'


const fontSize = "25px";
const fontSizeSmall = "20px";

const FolderListSmall = (props) => {
    let {startSync,settings,ui,toggleSettings,toggleSubscriptions,toggleAccounts,toggleLeftPanel} = props;
    let css2= tCss(settings.theme)(styles);
    return (
        <div className={css2(['container'])}>
            <div className={css2('iconContainer')} onClick={toggleLeftPanel}>
                <Ionicon icon="ion-navicon" fontSize={fontSize} className={css2(['iconClass'])} />  
            </div>
            <div className={css2('iconContainer')} onClick={toggleSubscriptions} style={{
                marginTop:'auto'
            }}>
                <Ionicon icon="ion-android-add" fontSize={fontSizeSmall} className={css2(['iconClass'])} />  
            </div>
            <div className={css2('iconContainer')} onClick={toggleAccounts}>
                <Ionicon icon="ion-person" fontSize={fontSizeSmall} className={css2(['iconClass'])} />  
            </div>
            <div className={css2('iconContainer')} onClick={startSync}>
                <Ionicon 
                        icon="ion-ios-loop-strong" fontSize={fontSizeSmall} 
                        className = {css2(['iconClass'])  +  `${ui.isSyncing?' rotate':''}`}
                />  
            </div>
            <div className={css2('iconContainer')} onClick={toggleSettings}>
                <Ionicon icon="ion-gear-a" fontSize={fontSizeSmall} className={css2(['iconClass'])} />  
            </div>
        </div>
    );
}


const styles= StyleSheet.create({
    container:{
        height:'100%' ,
        display:'flex',
        flexDirection:'column'
    },
    [`container_${THEMES.DARK}`]:{
        backgroundColor:'#333'
    },
    iconContainer:{
        display:'flex',
        justifyContent:'center',
        height:40,
        alignItems:'center',
        cursor:'pointer',
        opacity:'0.7',
        ':hover':{
            opacity:'1.0'
        },
    },
    iconClass_LIGHT:{
        fill:COLORS.BLACK4
    },
    iconClass_DARK:{
        fill:COLORS.WHITE_TEXT2
    },
});


const mapStateToProps = (state, ownProps) => {
    return {
        settings:state.settings.settings,
        ui:state.ui
    }
}

export default connect(mapStateToProps,{startSync})(FolderListSmall)