import React, { Component } from 'react';
import {tCss} from '../utils'
import {StyleSheet} from 'aphrodite'
import {connect} from 'react-redux'
import Converters from '../../entities/converter.web'
import COLORS from '../COLORS'
import {searchSubscriptions,updateOrInsertSubscription,searchSubscriptionsClear} from '../../redux/actions/actionCreators';
import {xor,includes,find} from 'lodash';
import {getAllCategories,getCategories,createCategory} from './helpers';
import Subscription from './Subscription';
import InputForm from './InputForm'
import {THEMES} from '../../common/enums'
import {isEmpty} from 'lodash'



class SubscriptionSystem extends Component {

    state = {
        //Stores list of subscription ids whose menus are visble
        menuVisibleIds:[] ,
        position:null
    }

    /**
     * If Menu visible, hide it. If hidden, show it.
     */
    toggleMenu = (subcriptionId,position)=>{
        let openIds= this.state.menuVisibleIds;
        this.setState({
            menuVisibleIds: xor(openIds,[subcriptionId]),
            position:position
        });
    }

    handleSubscribe = (subscription,categories,newCategoryText,newSubscriptionName)=>{
        let {account} = this.props;

        if(newCategoryText !==''){
            let newCategory= createCategory(account.id,newCategoryText);
            
            //If new category doesn't exist add it
            let isCategoryExists = categories.length>0 &&  !find(categories,function(cat){
                return cat.id.toLowerCase() !== newCategory.id.toLowerCase();
            });

            if(!isCategoryExists){
                categories =categories.concat(newCategory);
            }
        }
        
        //For Feedly and Local
        subscription.id = subscription.feedId;
        subscription.categories = categories;
        
        let subscriptionDoc = Converters.subscriptionHTTPToDoc(account.type,subscription);
        if(newSubscriptionName && !isEmpty(newSubscriptionName)){
            subscriptionDoc.title = newSubscriptionName;
        }
        this.props.updateOrInsertSubscription(subscriptionDoc);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.hidden){
            this.props.searchSubscriptionsClear();
        }
    }

    render() {
        let {searchedSubscriptions: results,subscriptions,settings,onClose,searchSubscriptions,textStrings,hidden}  = this.props;
        if(hidden){
            return null;
        }
        let {menuVisibleIds} =  this.state;
        let resultsDom =null;
        let text= '';
        let allCategories = getAllCategories(subscriptions);
        let css2= tCss(settings.theme)(styles);

        // //Temp Codes START
        // if(!results){
        //     results = fakeResults.results;
        // }
        // //Temp Codes END

        if(results){
            resultsDom =  results.map((subscription,i)=>{
                return <Subscription 
                    key={subscription.feedId} 
                    textStrings ={textStrings}
                    theme={settings.theme}
                    position ={this.state.position}
                    handleSubscribe ={this.handleSubscribe}
                    allCategories={allCategories}
                    categories= {getCategories(subscriptions,subscription.feedId)}
                    subscription={subscription} 
                    toggleMenu={this.toggleMenu}
                    open ={includes(menuVisibleIds,subscription.feedId)}
                />
            });    
        }

        return (
            <div className={css2('container')}>
                <InputForm 
                    searchSubscriptions={searchSubscriptions}
                    textStrings={textStrings}
                    theme={settings.theme}
                />
                <div className={css2('content')}>                                       
                    <div>{resultsDom}</div>
                </div>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    container:{
        display:'flex',
        flex:1,
        flexDirection:'column',
        alignItems:'flex-start',
        borderLeft:'1px solid rgba(0,0,0,0.2)',
    },
    [`container_${THEMES.LIGHT}`]:{
        backgroundColor:COLORS.BLUE_GRAY1,
    },
    [`container_${THEMES.DARK}`]:{
        backgroundColor: COLORS.BLACK4,
        color:COLORS.WHITE_TEXT2,
    },
    content:{
        flex:1,
        overflowY:'auto',
        width:'100%',
        padding:15
    },
    bar:{
        height:40,
        width:'100%',
        display:'flex',
        justifyContent:'flex-start',
        alignItems:'center',
        backgroundColor:'red'
    },
    iconContainer:{
        width:40,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        height:'100%',
        cursor:'pointer'
    },
    iconClass_LIGHT:{
        fill:COLORS.BLACK4
    },
    iconClass_DARK:{
        fill:COLORS.WHITE_TEXT2
    },
});

const mapStateToProps = (state, ownProps) => {
    return {
        settings: state.settings.settings,
        searchedSubscriptions: state.searchedSubscriptions,
        subscriptions:state.subscriptions,
        account:state.account,
        textStrings:state.textStrings
    }
}

export default connect(mapStateToProps,{searchSubscriptions,updateOrInsertSubscription,searchSubscriptionsClear})(SubscriptionSystem)