import React, { Component } from 'react';
import Ionicon from 'react-ionicons';

const Avatar = ({image,css2,icon,round=true,className,dimension=34})=>{
    if(image){
        return <div 
        className= {className}
        style={{
            width:dimension,
            height:dimension,
            borderRadius:round === true? 17:'',
            backgroundPosition: '50% 25%',
            backgroundSize: 'cover',
            backgroundImage:`url('${image}')`
        }}></div>
    }
    return <Ionicon className={css2('icon')} icon={icon} fontSize={'30px'} />
}

export default Avatar;