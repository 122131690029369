// import {MercuryArticleDoc} from './docTypes';
/**
 * Types for reducers
 */

export interface Profile {
    id:string;
    fullName:string;
}

export type Account ={
    id:string,
    type:string,
    created_at:number,
    token?:Object,  
    profile:Profile,  

    //State of user
    openFolderIds:Array<string>, 
    selectedFolderId?:string, 
    selectedSubscriptionId?:string, 
    selectedStoryId?:string, 
    storyFilter?:string, 
    sort?:Object, 
    syncTime:Object
}

/**
 * Type: AppState in reducer.
 * id, activeAccount (null) -  means account not found.
 */
export type AppState ={
    id?:number,
    activeAccountId?:string,
    ready:boolean,
    accountFound:boolean,
};


export interface Subscription {
    id:string; 
    title:string;
    iconUrl:string;
    visualUrl:string;
    website:string;
    velocity:string;
    subscribers:string;
    categories?:[Category];
    url:string; 
}
export interface Category{
    id:string;
    label:string;
}

export interface Folder extends Category{
    subscprions:[Subscription]
}

//This is structure of payload for feedly Story
export interface FeedlyStoryPayload {
    summaryText : string;
    summaryDir : string;
    contentText : string;
    contentDir : string;
    author : string;
    thumbnail : string;
    visual : string;
    enclosure : string;
    engagement : string;
    engagementRate : string;
    cdnAmpUrl : string;
}

export interface Story {
    id:string;  
    title:string;
    published:string;
    downloadedTime:number;
    subscriptionId:string;
    url:string;
    popularity:number;
    payload:object;    
}

//Feedly Story
export interface FeedlyStory extends Story{
    payload:FeedlyStoryPayload; 
}

///Creators

/**
 * Creates an defaultState
 */
export function createDefaultAppState(){
    let appState  = {
        accountFound:false,
        ready:false,
        activeAccountId:null,
        id:null
    };    
    return  appState;
}

export interface Activity{
    id:number;
    accountId:string;
    payload:string;
    activityName:string;
    time:string;
}

export interface Settings {
    fontSize:number;
    theme:string;  
    language:any; 
    syncOnStart:boolean;
    refreshAfter:number;
    removeItemsOlderThan:number;
    template:string;
    defaultSmallLeftPanelIn3Pane:boolean;//this is default value, not current value
}


// export interface MercuryArticle extends MercuryArticleDoc{}

// export interface Settings {
//     fontSize:number;
//     theme:string;  
//     language:any; 
//     syncOnStart:boolean;
//     refreshAfter:number;
//     removeItemsOlderThan:number;
//     template:string;
// }

export interface Status{
    id:string,
    type:string,
    text:string,
    hideAfter:string,//time in seconds
    hidden:string
}

export interface License {
    products:Array<any>,
    loaded:boolean
}