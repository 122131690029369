import React, {Component} from 'react';
import {connect} from 'react-redux'
import {selectStory,nextStory,prevStory} from '../../redux/actions/actionCreators';
import {getSubscription, isStoryRead} from '../utils'
import { List } from 'react-virtualized';
import {TEMPLATES} from '../../common/enums'
import Tile from './Tile'
import Card from './Card'
import Classic from './Classic'
import {getStoryIndex} from '../../redux/selectors/index'
// import Adv from './Adv'

// let ADV_KEY= "__ADV_KEY__";

var templateMapping = {
    [TEMPLATES.TILE]:{template: Tile,height:105},
    [TEMPLATES.CARD]:{template: Card,height:202},
    [TEMPLATES.CLASSIC]:{template: Classic,height:80},
}

class StoryList extends Component {
    _listening= false //we will respond to keydown events

    //Handle keypress event. We act only if we are listening and 
    //keys are of our interest
    _handleKeyPress =(e)=>{
        if(this._listening && (e.keyCode === 38 || e.keyCode === 40)){
            e.preventDefault();
            if(e.keyCode === 38){                
                this.props.prevStory();
            }
            if(e.keyCode === 40){
                this.props.nextStory()
            }
        }
    }

    componentWillUnmount(){
        window.removeEventListener('keydown',this._handleKeyPress);
    }
    
    componentDidMount(){
        window.addEventListener('keydown',this._handleKeyPress);
    }

    rowRenderer = ({
            index,       // Index of row
            isScrolling, // The List is currently being scrolled
            isVisible,   // This row is visible within the List (eg it is not an overscanned row)
            key,         // Unique key within array of rendered rows
            parent,      // Reference to the parent List (instance)
            style        // Style object to be applied to row (to position it);
                        // This must be passed through to the rendered row element.
        })=>{
            let {stories,selectStory,selectedStoryId,activities,subscriptions,theme,settings,layout} = this.props;
            const story = stories[index];
            // if(story === ADV_KEY){
            //     return <Adv key={index} theme={theme} style={style} />
            // }
            let Template = templateMapping[settings.template].template;
            return (<div key={key} style={style}>
                        <Template
                            isRead={isStoryRead(story,activities)}
                            subscription={getSubscription(subscriptions, story)}
                            story={story}
                            key={story.id}
                            isSelected= {story.id === selectedStoryId}
                            selectStory={selectStory}
                            theme={theme}
                            renderImage ={isVisible}
                            layout={layout}
                        />
                    </div>)
    }
    
    render(){
        let {stories, width,selectStory, selectedStoryId, subscriptions,theme,activities,settings,storyIndex}  =  this.props;
        let height = templateMapping[settings.template].height;
        return <div ref="listContainer"  
                    onFocus = {()=>{this._listening= true}}
                    onBlur = {()=>{this._listening=false}}
            >
                    <List
                        className='hide-outline'
                        width={width}
                        height={window.innerHeight - 40}
                        rowHeight={height}
                        rowCount={stories.length}
                        rowRenderer={this.rowRenderer}
                        overscanRowCount={20}
                        forceUpdate = {()=>{
                            return true;
                        }}
                        ref="list"
                        scrollToIndex = {storyIndex}
                        // scrollToAlignment="center"
                    />
        </div>
    }
}


const mapStateToProps = (state, ownProps) => {
    // let displayAdd= !isPremium(state.license.products);
    // let stories2 = [], stories=state.stories;//This contain stories with adv.
    // if(displayAdd){
    //     for(let i=0;i<stories.length;i++){
    //         let s = stories[i];
    //         if((i-3)%10 ===0){
    //             stories2.push(ADV_KEY);
    //         }
    //         stories2.push(stories[i]);
    //     }
    // }else{
    //     stories2 = stories;
    // }

    return {
        subscriptions: state.subscriptions, 
        stories: state.stories, 
        selectedStoryId: state.account.selectedStoryId,
        theme:state.settings.settings.theme,
        activities:state.activities,
        settings:state.settings.settings,
        storyIndex:getStoryIndex(state)
    }
}

export default connect(mapStateToProps, {selectStory,nextStory,prevStory})(StoryList)
