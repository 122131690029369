import {FETCH_UNREAD_COUNT_FINISH} from '../actions/names';

let initialState ={};

export default function reducer(state=initialState,action){
    if(action.type === FETCH_UNREAD_COUNT_FINISH){
        return action.payload;
    }
    return state;
}
