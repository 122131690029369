import React from 'react';
import Avatar from './Avatar'

const AccountsList = ({textStrings,css2,accounts,changeScreen,switchAccount,account} )=>{
    return <div className={css2('container')}>
            <div className={css2('header')}>{textStrings.SWITCH_ACCOUNT}</div>
            <div className={css2('content')}>
                {accounts.map((acc)=>{
                    return <div onClick={()=>{switchAccount(acc)}} key={acc.id} className={css2(['row','rowHover'],[{b:acc.id ==account.id,t:'rowSelected',f:''}])}>
                        <div className={css2('rowLeft')} style={{width:40}}>
                            <Avatar css2={css2} image={acc.profile.picture} icon="ion-android-person"/>
                        </div>
                        <div className={css2(['rowRight','flex1'])}>
                            <div>{acc.profile.fullName}</div>
                            <div className={css2('textLight')}>{acc.type}</div>
                        </div>                        
                    </div>
                })}
            </div>
            <div className={css2(['row'])}>
                <div className={css2(['rowLeft','flex1'])}>
                    <div onClick={()=>changeScreen('ServicesList')} className={css2('button')} style={{
                    }}>Add New</div>
                </div>
                <div className={css2(['rowRight','flex1'])}>
                    <div onClick={()=>changeScreen('AccountListRemove')} className={css2('button')} style={{
                    }}>Remove</div>
                </div>
            </div>
        </div>
}

export default AccountsList;