import React, { useState, useEffect } from 'react';
import Image from './images/bg.jpg';
import { getGlobals }  from '../common/utils'

const WallPaper = ()=>{
    const [image, setImage] = useState(Image)

    useEffect(() => {
        const { platform } = getGlobals()
        let options = {
            url: 'https://www.bing.com/HPImageArchive.aspx?format=js&idx=0&n=1',//&mkt=en-UK', //&mkt=en-US (without this it automatically decides Market)
            corsAllowed: false
        };
        platform.makeWebRequest(options).then((res) => {
            try {
                let url = 'https://www.bing.com' + res.data.images[0]['url'];
                setImage(url)
            } catch (error) {}
        }).catch(() => {})
    },[])

    return (
        <div className='wallpaper' style={{
            height:'100vh',
            width:'100%',
            backgroundColor:'transparent',
            backgroundImage:`url('${image}')`,
            backgroundPosition: '50% 25%',
            backgroundSize: 'cover'
        }}>
        </div>
    );
}

export default WallPaper;