import React from 'react';
import Ionicon from 'react-ionicons';
import Avatar from './Avatar'
import FeedlyImage from '../images/feedly_512.png'
import RssImage from '../images/rss-black.png'
import { getGlobals }  from '../../common/utils'

const globals = getGlobals()



const ServicesList = ({textStrings,css2,changeScreen,switchAccount,startLoginFlow} )=>{
    const {services} = globals
    return <div className={css2('container')}>
            <div className={css2('header')}>{textStrings.CHOOSE_A_SERVICE}</div>
            <div className={css2('content')}>

                <div onClick={()=>startLoginFlow(services.feedly)} className={css2(['row','rowHover'])} style={{padding:5}}>
                    <div className={css2('rowLeft')} style={{width:40}}>
                        <Avatar css2={css2} dimension={30} round={false} image={FeedlyImage} />
                    </div>
                    <div className={css2(['rowRight','flex1'])}>
                        <div>{'Feedly'}</div>
                    </div>                        
                </div>

                <div onClick={()=>startLoginFlow(services.local)} className={css2(['row','rowHover'])} style={{padding:5}}>
                    <div className={css2('rowLeft')} style={{width:40}}>
                        <Avatar css2={css2} dimension={20} round={false} image={RssImage} className={css2('invertImageColor')} />
                    </div>
                    <div className={css2(['rowRight','flex1'])}>
                        <div>{'Local'}</div>
                    </div>                        
                </div>


            </div>     
            
            
            <div className={css2(['row'])}>
            <div className={css2(['rowLeft','flex1'])}>
                    <div onClick={()=>changeScreen('AccountsList')} className={css2('linkButton')} style={{
                    }}>
                    <Ionicon className={css2('icon')} icon="ion-ios-arrow-back" fontSize={'16px'} />
                    Back
                    </div>
                </div>
                <div className={css2(['rowRight','flex1'])}>
                
                </div>
            </div>



        </div>
}

export default ServicesList;