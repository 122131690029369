import React, { Component } from 'react';
import Popover from '../Popover';

const BulkMarkAsReadPopOver =({isOpen,theme,onHide,position,textStrings,css2,onMarkBulkStories})=>{
    return <Popover
        isOpen={isOpen}
        onHide= {onHide}
        position={position}
        theme={theme}
        style={{height:''}}>
        <div style={{
            padding:0
        }}>
            <div className={css2('popoverRow')} style={{
                width:'100%',
                padding:10,
                border:'none'
            }} onClick={onMarkBulkStories}>{textStrings.MARK_LIST_AS_READ}</div>
        </div>
    </Popover>
}

export default BulkMarkAsReadPopOver;