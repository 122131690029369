import {FETCH_SUBSCRIPTIONS_FINSIH, UPDATE_OR_INSERT_SUBSCRIPTION,SWITCH_ACCOUNT} from '../actions/names';
import {updateOrInsert} from '../../common/utils';

const initialState = [];

export default function reducer(state = initialState, action) {
    if(action.type === SWITCH_ACCOUNT){
        return initialState;
    }
    if (action.type === FETCH_SUBSCRIPTIONS_FINSIH) {
        return action.payload;
    }

    if (action.type === UPDATE_OR_INSERT_SUBSCRIPTION) {
        return updateOrInsert(state,action.payload,'id');
    }

    return state;
}