import {SEARCH_SUBSCRIPTIONS_FINSIH,SWITCH_ACCOUNT,SEARCH_SUBSCRIPTIONS_CLEAR}  from '../actions/names';

const initialState = null;


export default function reducer(state = initialState,action){
    if(action.type === SWITCH_ACCOUNT || action.type === SEARCH_SUBSCRIPTIONS_CLEAR){
        return initialState;
    }
    if(action.type === SEARCH_SUBSCRIPTIONS_FINSIH){
        return action.payload
    }
    return state;
}