import React, {  useEffect } from 'react';
import { Provider } from 'react-redux'
import store from './redux/store'
import { initializeApp , setDatabase, setPlatform, setServices } from './common/utils';
import {startApp} from './redux/actions/actionCreators'
import ScreenManager from './components/ScreenManager'


const App = (props)=>{
    const {platform, database, services } = props
    setPlatform(platform);
    setDatabase(database);
    setServices(services);

    useEffect(()=>{
        initializeApp(()=>{
            store.dispatch(startApp());
        })
    },[]);
    return (<Provider store={store}>
        <ScreenManager />
    </Provider>);
}

export default App;