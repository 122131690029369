// import {TypeCreator, Category} from '../setup/global';
import {find, reduce,uniqBy} from 'lodash';


const TypeCreator = {
    formatFeedlySubscriptionsForDBFromHttp:function(){}
}

/**
 * This function accepts all subscriptions from reducers and returns all unique categories
 * @param {*} subscriptions From Reducers
 */
export function getAllCategories(subscriptions) {
    //collect all categories, then find unique
    let categories = uniqBy(reduce(subscriptions, function (acc, sub) {
        return acc.concat(sub.categories);
    }, []),'id');
    return categories;
}


/**
 * returns categories of a subscriptionId,returns empty array if subscription not found
 * @param {*} subscriptions All subcriptions
 * @param {*} subscriptionId whose categories to be found
 */
export function getCategories(subscriptions, subscriptionId){
    //find subscription, then return its categories
    let categories =(find(subscriptions,{id:subscriptionId}) || {categories:[]}).categories;
    return categories;
}


/**
 * Returns Db compatible subscription from Explored subscription
 * @param {*} subscription from Explored
 * @param {*} categories,  optional categories that will attached
 */
// export function convertSearchedSubscription(subscription,categories=[]) {
//     subscription.id = subscription.feedId;
//     subscription.categories = categories;
//     return formatFeedlySubscriptionsForDBFromHttp([subscription])[0];
// }


export function createCategory(accountId,newCategoryText){
    let cat ={
        id:`user/${accountId}/category/${newCategoryText}`,
        label:newCategoryText
    };
    return cat;
}