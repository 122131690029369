import {StyleSheet} from 'aphrodite'
import {THEMES} from '../../common/enums'
import Colors from '../COLORS'

const styles= StyleSheet.create({
    container:{
        width:300,
        height:300,
        display:'flex',
        flexDirection:'column',
    },
    [`container_${THEMES.LIGHT}`]:{
        backgroundColor:'#fff',
        border:'1px solid #ccc',
    },
    [`container_${THEMES.DARK}`]:{
        backgroundColor:Colors.BLACK4,
        border:`1px solid ${Colors.BLACK6}`,
    },
    header:{
        width:'100%',
        padding:12
    },
    [`header_${THEMES.LIGHT}`]:{
        color:'#101010',
        borderBottom:'1px solid #ccc',
    },
    [`header_${THEMES.DARK}`]:{
        color:'#eee',
        borderBottom:'1px solid #777',
    },
    content:{
        borderBottom:'1px solid #ccc',
        width:'100%',
        flex:1,
        overflowY:'auto',
        fontSize:14
    },
    [`content_${THEMES.LIGHT}`]:{
        borderBottom:'1px solid #ccc',
    },
    [`content_${THEMES.DARK}`]:{
        borderBottom:'1px solid #777',
    },
    row:{
        width:'100%',
        display:'flex',
        flexDirection:'row',
    },
    [`row_${THEMES.LIGHT}`]:{
        borderBottom:'1px solid #ccc'
    },
    [`row_${THEMES.DARK}`]:{
        border:`1px solid ${Colors.BLACK6}`,
        color:'#eee'
    },
    [`rowSelected_${THEMES.LIGHT}`]:{
        fontWeight:600,
        backgroundColor:'#eee'
    },
    [`rowSelected_${THEMES.DARK}`]:{
        fontWeight:600,
        backgroundColor:'#000'
    },
    rowHover:{
        cursor:'pointer',
    },
    [`rowHover_${THEMES.LIGHT}`]:{
        ':hover':{
            backgroundColor:'#eee'
        }
    },
    [`rowHover_${THEMES.DARK}`]:{
        ':hover':{
            backgroundColor:'#000'
        }
    },
    rowLeft:{
        justifyContent:'center',
        alignItems:'center',
        display:'flex',
    },
    rowRight:{
        alignItems:'center',
        padding:11
    },
    textLight:{
        fontSize:12,
        color:'#999'
    },
    flex1:{
        flex:1
    },
    button:{        
        padding: 7,     
        fontSize: 14,
        width: '95%',
        textAlign: 'center',
        margin: 5,
        border:'1px solid #999',
        cursor:'pointer'
    },
    linkButton:{
        padding: 7,     
        fontSize: 14,
        textAlign: 'center',
        margin: 5,
        cursor:'pointer',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    [`icon_${THEMES.LIGHT}`]:{
        fill:'#333'
    },
    [`icon_${THEMES.DARK}`]:{
        fill:'#aaa'
    },
    [`invertImageColor_${THEMES.DARK}`]:{
        filter:'invert(0.8)'
    }    
});

export default styles;