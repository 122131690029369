import {FETCH_ACCOUNT_FINISH, 
        SELECT_STORY, 
        SELECT_FOLDER,        
        TOGGLE_FOLDER,
        SELECT_SUBSCRIPTION,
        SWITCH_ACCOUNT,
        CHANGE_STORIES_FILTER,
        CHANGE_SORT,
        REFRESH_TOKEN_FINISH,
        UPDATE_TOKEN_PROFILE,
        UPDATE_SYNC_TIME
} 
from '../actions/names';
import {xor, includes} from 'lodash';
import {ARR_STORIES_LIST_FILTER} from '../../common/enums';

const initialState = null;

export default function reducer(state = initialState,action){
    
    if(action.type === FETCH_ACCOUNT_FINISH){
        return action.payload;
    }

    ///OLD START

    if(action.type === SWITCH_ACCOUNT){
        return initialState;
    }

    if(action.type === TOGGLE_FOLDER){
        let openIds = xor(state.openFolderIds,[action.payload.id]);
        return {...state,openFolderIds: openIds };
    }

    if(action.type === SELECT_STORY){
        return {...state,selectedStoryId: action.payload.id };
    }

    if(action.type === SELECT_FOLDER){  
        //if folderId is any of the STORY_FILTER then clear storyFilter
        //As click came from static label and they themselves act as filter.
        const _storyFilter =   includes(ARR_STORIES_LIST_FILTER,action.payload.id)? null : state.storyFilter;
        return {
                ...state,
                selectedFolderId: action.payload.id, 
                selectedSubscriptionId:null,
                selectedStoryId:null,
                storyFilter: _storyFilter
            };
    }

    if(action.type === SELECT_SUBSCRIPTION){
        return {
                ...state,
                selectedSubscriptionId: action.payload.id ,
                selectedFolderId:null,
                selectedStoryId:null
            };
    }

    if(action.type === CHANGE_STORIES_FILTER){
        return {
            ...state,
            storyFilter:action.payload,
            selectedStoryId:null
        }
    }

    if(action.type === CHANGE_SORT){
        return {
            ...state,
            sort:action.payload
        };
    }

    if(action.type === REFRESH_TOKEN_FINISH){
        return {...state,token:action.payload}
    }

    if(action.type ===UPDATE_TOKEN_PROFILE){
        let {profile,token} = action.payload;
        //Update this account only if its loaded
        if(profile.id === state.profile.id){
            return {...state,profile:profile,token:token}
        }
    }

    if(action.type === UPDATE_SYNC_TIME){
        return {
            ...state,syncTime:action.payload
        }
    }

    return state;
}