import React, { Component } from 'react';
import {connect} from 'react-redux'
import {STORY_VIEW} from '../../common/enums';
import {nextStory,prevStory} from '../../redux/actions/actionCreators';
import DefaultView from './DefaultView'
import WebView from './Webview'
import CleanView from './CleanView'
import NavButton from './NavButton'
import {getStoryIndex} from '../../redux/selectors/index'

const Empty = ()=>{
    return <div>Empty View</div>
}

const WaitView = ({status})=>{
    return <div style={{
        textAlign: 'center',
        paddingTop: 30,
        fontSize: 14,
        color: '#999'
    }}>{status}</div>
}

class StoryView extends Component {

    _cleanImgTags =()=>{
        //Some image tags come with width and height
        //remove them
        let container = document.getElementById('story-container');
        let imgs = container.querySelectorAll('img');
        if(imgs && imgs.length > 0 && imgs.forEach){
            imgs.forEach((node)=>{
                try {
                    node.removeAttribute('width');
                    node.removeAttribute('height');
                    node.removeAttribute('sizes');
                } catch (error) {
                }
            })
        }
    }
    
    componentDidUpdate(prevProps){
        this._cleanImgTags(); 
        if(this.props.story.story.id != prevProps.story.story.id){
            this.props.scrollToStoryTop();  
        }
    }

    componentDidMount(){
        this._cleanImgTags();
    }

    render() {
        let {story,view,mercuryArticle} = this.props.story; 
        if(!story){
            return <Empty />
        }
        let {settings,textStrings,nextStory,prevStory,storyIndex,stories}  = this.props;

        let Elm = Empty;
        if(view === STORY_VIEW.DEFAULT){
            Elm = DefaultView;
        }
        if(view === STORY_VIEW.WEB){
            Elm = WebView;
        }
        if(view === STORY_VIEW.CLEAN){
            if(!mercuryArticle){
                Elm = WaitView
            }else{
                Elm = CleanView;
            }
        }
        return (
           <div id="story-container">
                <Elm  
                    status ={textStrings.WAIT}
                    story={story} 
                    mercuryArticle={mercuryArticle}
                    settings={settings} 
                    textStrings={textStrings} 
                />
                <NavButton visible={storyIndex>0} iconName="ion-chevron-left" changeStory={prevStory} scrollPosition={this.props.scrollPosition} position="left" left={30} />                
                <NavButton visible={storyIndex<stories.length-1} iconName="ion-chevron-right" changeStory={nextStory} scrollPosition={this.props.scrollPosition} position="right" left={0} />                
           </div>
        );


    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        story: state.story,
        settings:state.settings.settings,
        textStrings:state.textStrings,
        stories:state.stories,
        storyIndex:getStoryIndex(state)
    }
}

export default connect(mapStateToProps,{nextStory,prevStory})(StoryView);