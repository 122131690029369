import { call,take, put, cancel, takeEvery, select, fork } from 'redux-saga/effects'
import { delay} from 'redux-saga/effects'
import * as actionCreators from '../actions/actionCreators';
import {find, includes,flow,reduce} from 'lodash';
import {NAMES as ENUM_NAMES,STORY_LIST_SORT_DIRECTION,ARR_STORIES_LIST_FILTER,STORIES_LIST_FILTER,STORY_VIEW, ACTIVITY_NAMES, STATUS_TYPES} from '../../common/enums';
import {START_APP,CREATE_FIRST_ACCOUNT,START_SYNC,FETCH_STORIES_START,_UPDATE_ACCOUNT_STATE, TOGGLE_FOLDER,SELECT_STORY,SELECT_FOLDER,SELECT_SUBSCRIPTION,CHANGE_STORIES_FILTER,CHANGE_SORT,CREATE_ACCOUNT,CHANGE_SETTINGS,SWITCH_ACCOUNT,CREATE_ACTIVITY,REMOVE_ACTIVITY,TOGGLE_STORY_VIEW, SEARCH_SUBSCRIPTIONS,UPDATE_OR_INSERT_SUBSCRIPTION,MARK_CURRENT_LIST_AS_READ, SEARCH_STORY,CLOSE_SEARCH,UPDATE_STATUS,REMOVE_ACCOUNT,PURCHASE_PRODUCT_START,PREV_STORY,NEXT_STORY} from '../actions/names';
import {buildCache,handleSearch} from './searchSaga'
import {loadLicense,purchaseProductStart} from './licenseSaga'
import {getStoryIndex} from '../selectors/index'
import {createAccountDoc, createAppStateDoc } from '../../entities/docTypes';
import Converters  from '../../entities/converter.web'
import { getGlobals, filterStoriesFromActivities }  from '../../common/utils'

const globals = getGlobals()


//Todo:
//All activities that send request to server with token.
//Should first check for refresh token


const getDataFromHttp = res=>res.data;
const convertStoryHttpToDb = flow(Converters.storyHTTPToDoc,Converters.storyDocToDB);
var CURRENT_SERVICE =null;

function* startApp(action){
    const {services, db: DB} = globals
    try {
        //Fetch App start Data
        //If no account found then both account/appState are null 
        const data = yield call(DB.fetchAppStartData);
        //Convert Db's to Doc
        let account = Converters.accountDbToDoc(data.account);
        let appState = Converters.appStateDbToDoc(data.appState);
        //Dispatch FetchAccount
        yield put(actionCreators.fetchAccountFinish(account));
        //Dispatch Settings, send {} if appState is null
        yield put(actionCreators.fetchSettingsFinish(appState===null?{}:appState.settings));
        
        //If Account Found
        if(!!account){
            //Update current service
            CURRENT_SERVICE = services.getService(account.type);

            //fetchSubscriptions
            //Todo:? Do we need formatting here?
            yield (put(actionCreators.fetchSubscriptionsFinish(account.subscriptions)));

            //Load Activities
            let activities = yield call(DB.fetchActivities,account);
            yield put(actionCreators.fetchActivitiesFinish(activities));

            //Fetch stories 
            yield fetchStories(); 
                
            //<START>This code runs once on app start. Does not run on account switch.
            if(!action.payload.isAccountSwitch){
                //start sync, if so
                let {settings}  = yield select((state)=>state);
                if(settings.settings.syncOnStart){
                    yield put(actionCreators.startSync());
                }

                //
                yield fork(cleanDatabase);
                //
                yield fork(startAutoSyncDaemon);

                yield fork(loadLicense)
            }
            //<END>
        }

        //Dispatch FetchAppState
        yield put(actionCreators.fetchAppStateFinish(appState));

        //
        if(account){
            //Fetch accounts
            yield fetchAccounts();
            yield fetchUnreadCounts();
            // yield fork(fetchAllStoriesOfAccount);
            yield fork(buildCache);
        }

    } catch (error) {
        // console.log(error);
        yield (put(actionCreators.genericError(START_APP,error.message)));
    }
}

function* startAutoSyncDaemon(){
    let {settings}  = yield select((state)=>state);
    //convert minutes to milliseconds
    let refreshDelay = (settings.settings.refreshAfter)*(60*1000);
    // let refreshDelay = (1)*(60*1000);
    // console.log('auto sync',settings.settings.refreshAfter,refreshDelay);
    
    yield delay(refreshDelay);
    yield put(actionCreators.startSync());
    yield call(startAutoSyncDaemon);
}

function* fetchUnreadCounts(){
    const {db: DB} = globals
    let {subscriptions,activities,textStrings}  = yield select((state)=>state);
    let readIds =  activities.read.map((a)=>a.payload);
    let subscriptionIds= subscriptions.map((sub)=>{
        return sub.id;
    });
    let s = yield call(DB.fetchUnreadCounts,subscriptionIds,readIds);
    let total = 0;//Total unread count
    let data= reduce(s,(res,row)=>{
         res[row.subscriptionId]= row.count;
         total = total + row.count;
         return res;
    },{});

    data[textStrings.UNREAD] = total;
    data[textStrings.BOOKMARKS] = activities.bookmarks.length;
    data[textStrings.FAVOURITE] = activities.favs.length;

    //
    yield put(actionCreators.fetchUnreadCountFinish(data));
    //Update badge    
    yield fork(updateBadge,total)
}


function* updateBadge(num){
    const { platform} = globals
    let textStrings= yield select((state)=>state.textStrings);
    num = parseInt(num,10);
    let rawNumber= num;
    if(num>0){
        if(num > 99){
            num = "99+"
        }
        platform.updateBadgeNumber(num,textStrings.APP_NAME,rawNumber);
    }else{
        platform.clearBadgeNumber(textStrings.APP_NAME)
    }
}


function* fetchAccounts(){
    const { db: DB} = globals
    let accountsDb = yield call(DB.fetchAccounts);
    let accountsDoc = accountsDb.map((a)=>Converters.accountDbToDoc(a));
    yield put(actionCreators.fetchAccountsFinish(accountsDoc));
}

function* creatFirstAccount(action){
    const {db: DB} = globals
    try {
        let {profile,token,type} = action.payload;
        //We send settings from reducer, 
        //as user  may have changed settings from welcome screen.
        let {settings}  = yield select((state)=>state);
        let accountDoc= createAccountDoc(profile,token,type);
        let appStateDoc= createAppStateDoc(accountDoc,settings._user);
        let accountDb =  Converters.accountDocToDb(accountDoc);
        let appStateDb = Converters.appStateDocToDb(appStateDoc);
        yield call(DB.createAccount,accountDb,appStateDb);
        yield put(actionCreators.startApp());
    } catch (err) {
        console.log(err);
        yield (put(actionCreators.genericError(err.message)));
    } 
}

function* createAccount(action){
    const {db: DB} = globals
    try {
        //Create new account and type and insert into DB
        let {profile,token,type} = action.payload;
        let accountDoc= createAccountDoc(profile,token,type);

        //check does account already exists?, if it does- update it.
        //else create new Acc
        let existingAcc = yield call(DB.fetchAccount,accountDoc.id);
        if(existingAcc.length === 0){
            let accountDb =  Converters.accountDocToDb(accountDoc);
            yield call(DB.createAccount,accountDb,null);
            
            //dispatch action new account created
            yield put(actionCreators.createAccountFinish(accountDoc));
        }else{
            //Update DB
            yield call(DB.updateAccountTokenProfile,accountDoc,token,profile);
            //dispatch profile updated
            yield put(actionCreators.updateTokenProfile(profile,token));
        }
        
        
    } catch (err) {
        yield (put(actionCreators.genericError(err.message)));
    }
}

function* refreshToken(){
    const {services, platform, db: DB} = globals
    try {
        const account = yield select((state)=>state.account);
        //Refresh system for Feedly
        if(services.feedly.getKey() === services.getService(account.type).getKey()){
            const isExpired = services.feedly.isTokenExpired(account.token);
            if(isExpired){
                let options  = services.feedly.refreshToken(account.token)
                let response = yield call(platform.makeWebRequest,options);
                let newToken = getDataFromHttp(response);
                //Collect refresh_token from previous token
                newToken.refresh_token = account.token.refresh_token;
                newToken.created_at =  Date.now();
                //Update DB
                yield call(DB.updateToken,account,newToken)
                //Update Reducers
                yield put(actionCreators.refreshTokenFinish(newToken));
            }
        }
    } catch (error) {
        yield put(actionCreators.updateStatus(null,STATUS_TYPES.DANGER,`${error.message}`,10));
    }
}

let _isSyncing=false;
function* startSync(action){
    const {services, platform, db: DB} = globals
    if(!_isSyncing){
        _isSyncing = true;
        // console.log('SYNC_Start');
        platform.badgeIconSyncing();
        let textStrings= yield select((state)=>state.textStrings);
        let STATUS_ID = 'SYNC_STATUS';//id used for status updates
        try {
            yield put(actionCreators.updateStatus(STATUS_ID,STATUS_TYPES.PRIMARY,`${textStrings.SYNC_TOKEN}...`));
            yield refreshToken();
            /**
             * Steps-Feedly
             * - Refresh token
             * - Load:::::::::
             * - Subscriptions
             * - Stories
             * - Activities (read,starred,bookmarks) TODO: Imepent this.
             */
            const account = yield select((state)=>state.account);
            let mapStories = flow(getDataFromHttp,res=> res.items.map(s=>convertStoryHttpToDb(account.type,s)));
            
            //Sync for feedly
            if(account.type ===  services.feedly.getKey()){
                //Web Request Options
                let storiesRequestOptions = services.feedly.getStoriesForAccount(account.token,account.id,300,account.syncTime.account)
                let subscriptionsRequestOptions = services.feedly.getSubscriptions(account.token)
                
                //Make Webrequests
                //Yield subscription, format and store them   
                yield put(actionCreators.updateStatus(STATUS_ID,STATUS_TYPES.PRIMARY,`${textStrings.SYNC_SUBSCRIPTIONS}...`));
                let subscriptionsHTTP = yield call(platform.makeWebRequest,subscriptionsRequestOptions);
                let mapSubscriptions = flow(getDataFromHttp,res=> res.map(s=>Converters.subscriptionHTTPToDoc(account.type,s)))
                let subscriptionsDocs = mapSubscriptions(subscriptionsHTTP);
                //// let subscriptionsDB = Converters.subscriptionsDocToDBProp(subscriptionsDocs);
                yield fork(DB.updateSubscriptions,account,subscriptionsDocs);

                //Disptach Action
                yield put(actionCreators.fetchSubscriptionsFinish(subscriptionsDocs));

                //Yield stories, format and store them
                yield put(actionCreators.updateStatus(STATUS_ID,STATUS_TYPES.PRIMARY,`${textStrings.SYNC_STORIES}...`));
                let storiesHTTP = yield call(platform.makeWebRequest,storiesRequestOptions);
                let storiesDB = mapStories(storiesHTTP);
                // console.log(storiesDB.length,'New Stories Number');
                yield call(DB.insertStories,storiesDB);

                //Dispatch Action
                yield put(actionCreators.fetchStoriesStart());

                //Update sync time
                yield put(actionCreators.updateSyncTime({account:Date.now()}))
            }
            //Sync for Feedly End

            //Sync for Local Start
            if(account.type ===  services.local.getKey()){
                const subscriptions = yield select((state)=>state.subscriptions);
                if(subscriptions.length>0){
                    for(let i =0;i<subscriptions.length;i++){
                        let sub = subscriptions[i];
                        yield put(actionCreators.updateStatus(STATUS_ID,STATUS_TYPES.PRIMARY,`${textStrings.SYNCING}: ${sub.title}...`));
                        let storiesRequestOptions = services.local.getStories(sub.url,50,account.syncTime[sub.id])
                        let storiesHTTP = yield call(platform.makeWebRequest,storiesRequestOptions);
                        let storiesDB =   mapStories(storiesHTTP);
                        console.log(storiesDB.length,'New Stories Number');
                        //Save stories
                        yield call(DB.insertStories,storiesDB);
                        //Fetch stories
                        yield fork(fetchStories);
        
                        let syncTime = {...account.syncTime,[sub.id]:Date.now()};
                        yield put(actionCreators.updateSyncTime(syncTime))
                    }
                }
            }
            //Sync for Local End

            //Update account to update sync time.
            yield call(_updateAccountState)
            //Update status
            yield put(actionCreators.updateStatus(STATUS_ID,STATUS_TYPES.SUCCESS,`${textStrings.SYNC_FINISHED}`,10));
        } catch (error) {
            yield put(actionCreators.updateStatus(STATUS_ID,STATUS_TYPES.DANGER,`${error.message}`,10));
        }
        finally{
            yield put (actionCreators.finishSync());
            yield fork(cleanDatabase);
            yield fetchUnreadCounts();
            _isSyncing= false;
            // console.log('sync_finish');
            // platform.badgeIconClear();
        }
    }
    // else{
    //     console.log('Ignore sync request');
    // }
}

function* fetchStories(){
    const {db: DB} = globals
    //This method load stories on 
    //a: app load, 
    //b: when folder or subscription is clicked
    //c: On sync
    
    //Why select_story or clear_story is triggered?
    //on App_Load and folder/subscription change, selectedStoryId is changed
    //however there is no way for story reducer to get story
    //so we are doing it here.


    //Fetch stories from DB
    //Format them for reducer
    //dispatch them
    const {account,subscriptions,activities} = yield select((state)=>state);
    const {selectedFolderId, selectedSubscriptionId, selectedStoryId} = account;
    
    //Static folders are, folders on left, common for all account
    const isStaticFolder = includes(ARR_STORIES_LIST_FILTER,selectedFolderId);

    let subscriptionIds= [];

    //static or no folder is selected
    if((!selectedFolderId || isStaticFolder) && !selectedSubscriptionId){
        subscriptionIds= subscriptions.map((sub)=>{
            return sub.id;
        });
    }
    if(selectedSubscriptionId){
        subscriptionIds =[selectedSubscriptionId];
    }

    //folder is selected and its not static
    if(selectedFolderId && !isStaticFolder){
        let filterFn = (sub)=>!!find(sub.categories,{id:selectedFolderId});
        //Selected folder is uncategorised. We pick all subscriptions with zero categories
        if(selectedFolderId === ENUM_NAMES.UNCATEGORISED_FOLDER_ID){
            filterFn  = (sub)=>sub.categories.length ==0;
        }
        subscriptionIds= subscriptions.filter(filterFn).map((sub)=>{
            return sub.id;
        });
    }


    let includeIds=null,excludeIds=null;
    //null value means property is ignored,
    //includeIds => return only these items,
    //excludeIds => ignore only these items,
    const storyFilter = account.storyFilter;
    if(storyFilter === STORIES_LIST_FILTER.BOOKMARKED || selectedFolderId===STORIES_LIST_FILTER.BOOKMARKED) {
        includeIds =  activities.bookmarks.map((a)=>a.payload);
    }
    if(storyFilter === STORIES_LIST_FILTER.FAVS || selectedFolderId===STORIES_LIST_FILTER.FAVS) {
        includeIds =  activities.favs.map((a)=>a.payload);
    }
    if(storyFilter === STORIES_LIST_FILTER.UNREAD || selectedFolderId===STORIES_LIST_FILTER.UNREAD) {
        excludeIds =  activities.read.map((a)=>a.payload);
    }

    let sort={prop:'published',dir:'desc'};
    //If Account.sort exists
    if(account.sort){
        if(account.sort === STORY_LIST_SORT_DIRECTION.NEWEST_FIRST){
            sort={prop:'published',dir:'desc'};
        }
        if(account.sort === STORY_LIST_SORT_DIRECTION.OLDEST_FIRST){
            sort={prop:'published',dir:'asc'};
        }
        if(account.sort === STORY_LIST_SORT_DIRECTION.POPULAR_FIRST){
            sort={prop:'popularity',dir:'desc'};
        }
    }

    let storiesDB = yield call(DB.fetchStories,subscriptionIds,includeIds,excludeIds,sort);    
    //NOTE: We are passing StoriesDB not StoriesDoc.
    yield put(actionCreators.fetchStoriesFinish(storiesDB));

    //This is looping over stories which is making it slow.
    let story = find(storiesDB,{id:selectedStoryId});
    if(!!story){
        yield put(actionCreators.selectStory(story));
    }else{
        yield put(actionCreators.clearStory());
    }
}

function* fetchAllStoriesOfAccount(){
    const { db: DB} = globals
    const {subscriptions} = yield select((state)=>state);
    let allStories = yield call(DB.fetchStories,subscriptions.map((m)=>m.id));
    yield put ({type:'FETCH_STORIES_ALL',payload:allStories})
}

function* _updateAccountState(){
    const {db: DB} = globals
    try {
        let {account} = yield select((state)=>state);
        //This updates account scalars
        yield call(DB.updateAccountState,account);
    } catch (error) {
        console.log(error);
    }
}

function* _updateAccountStateLoadStories(action){
    yield put(actionCreators._updateAccountState());
    yield put(actionCreators.fetchStoriesStart());
}

function* updateSettings(){
    const {db: DB} = globals
    let {settings} = yield select((state)=>state);
    yield call (DB.updateSettings,settings._user);
}

function* switchAccount(action){
    const {db: DB} = globals
    //Update Database
    yield call(DB.updateAppStateActiveAccountId,action.payload.id);
    //StartApp
    yield put(actionCreators.startApp(true));
}


const ACTIVITY_STATUS_ID = 'ACTIVITY_STATUS_ID'
function* createActivity(action){
    const {platform, db: DB} = globals
    const {account} = yield select((state)=>state);
    let activity= action.payload;
    activity.accountId = account.id;

    yield call(DB.insertActivity,activity);
    yield put(actionCreators.createActivityFinish(activity));
    yield fetchUnreadCounts();//Todo: Check this

    let ajaxOptions;
    if(activity.activityName === ACTIVITY_NAMES.READ){
        ajaxOptions = CURRENT_SERVICE.markAsRead(account.token,[activity.payload])
    }
    if(activity.activityName === ACTIVITY_NAMES.BOOKMARK){
        ajaxOptions = CURRENT_SERVICE.markAsSaved(account.token,[activity.payload])
    }

    try {
        yield call(platform.makeWebRequest,ajaxOptions);
    } catch (error) {
        yield put(actionCreators.updateStatus(ACTIVITY_STATUS_ID,STATUS_TYPES.DANGER,`${error.message}`,10));
    }
}

function* removeActivity(action){
    const {platform, db: DB} = globals
    const {account} = yield select((state)=>state);
    let activity = action.payload;
    let ajaxOptions;
    if(activity.activityName === ACTIVITY_NAMES.READ){
        ajaxOptions = CURRENT_SERVICE.markAsUnread(account.token,[activity.payload])
    }
    if(activity.activityName === ACTIVITY_NAMES.BOOKMARK){
        ajaxOptions = CURRENT_SERVICE.markAsUnSaved(account.token,[activity.payload])
    }
    try {
        yield call(platform.makeWebRequest,ajaxOptions);
    } catch (error) {
        yield put(actionCreators.updateStatus(ACTIVITY_STATUS_ID,STATUS_TYPES.DANGER,`${error.message}`,10));
    }
    
    yield call(DB.removeActivity,action.payload.id);
    yield fetchUnreadCounts();
}


function* toggleStoryView(action){
    const {platform, db: DB} = globals
    try {
        let {story} = yield select((state)=>state);
        if(story.view === STORY_VIEW.CLEAN){
            //Get article from DB
            let mercuryArticle = yield call(DB.fetchMercuryArticle,story.story);
            //If article found, use it
            if(mercuryArticle && mercuryArticle.length >0){
                //convert mercuryDb to mercury(reducer type)
                mercuryArticle =   Converters.mercuryDbToDoc(mercuryArticle[0]);
                //Dispatch to action
                yield put(actionCreators.fetchMercuryArticleFinish(mercuryArticle));
            }
            //article not found make webrequest
            else{
                let options = {
                    url: '/clean-read',
                    method:'post',
                    data:{
                        url:story.story.url,
                    },
                    corsAllowed:true
                }
                //collect web options
                let webRequestOptions = options

                //Make web request
                let mercuryArticleHTTP = yield call(platform.makeWebRequest, webRequestOptions);

                if(mercuryArticleHTTP.data.error){
                    throw new Error('Mercury is disabled')
                }
               
                // //Convert MercuryHttp to MercuryDOC and dispatch
                let mArticleDoc= Converters.mercuryArticleHTTPToDoc(story.story,getDataFromHttp(mercuryArticleHTTP))
                yield put(actionCreators.fetchMercuryArticleFinish(mArticleDoc));
                //save MercuryDB to DB
                let mArticleDb = Converters.mercuryArticleDocToDb(mArticleDoc);                
                yield fork(DB.insertMercuryArticle,mArticleDb);
            }
        }        
    } catch (error) {
        yield put(actionCreators.updateStatus(null,STATUS_TYPES.DANGER,`${error.message}`,10));
    }
}


function *searchSubscriptions(action,repeat=true,n=20){
    const {services, platform} = globals
    const account= yield select((state)=>state.account);
    console.log('1')
    try {
        console.log('2')

        //Search For feedly Account
        if(account.type === services.feedly.getKey()){
            let requestOptions = services.feedly.searchTextForSubscriptions(action.payload,n)
            let subscriptions = yield call(platform.makeWebRequest,requestOptions);
            subscriptions = getDataFromHttp(subscriptions);
            yield put(actionCreators.searchSubscriptionsFinsih(subscriptions.results))
        }

        //Search For local Account
        if(account.type === services.local.getKey()){
            console.log('3',services.local.searchTextForSubscriptions(action.payload,n))
            console.log('platform',platform)
            console.log('platform',platform.makeWebRequest)

            let requestOptions = services.local.searchTextForSubscriptions(action.payload,n)
            let subscriptions = getDataFromHttp(yield call(platform.makeWebRequest,requestOptions)); 
            yield put(actionCreators.searchSubscriptionsFinsih(subscriptions.results))
        }
        //Initially we make request with 20 items. After first request
        //We make another request with 200 items.
        if(repeat){
            yield fork(searchSubscriptions,action,false,50)
        }        
    } catch (error) {
        // yield (put(actionCreators.genericError(err.message)));
        yield put(actionCreators.updateStatus(null,STATUS_TYPES.DANGER,`${error.message}`,10));        
    }
}

function *updateOrInsertSubscription(action){
    const {services, platform, db: DB} = globals
    const {account,subscriptions} = yield select((state)=>state);
    try {
        if(account.type === services.feedly.getKey()){
            //Update feedly server
            let requestOptions = services.feedly.updateSubscription(account.token, action.payload)
            yield fork(platform.makeWebRequest,requestOptions);
        }
        //Update Database
        //By this time reducer is updated.
        //we just need to update the Database.
        yield call(DB.updateSubscriptions,account,subscriptions);
    } catch (error) {
        yield put(actionCreators.updateStatus(null,STATUS_TYPES.DANGER,`${error.message}`,10));
    }
}


function *markCurrentListAsRead(){
    const {platform, db: DB} = globals
    const {activities,stories,account} = yield select((state)=>state);
    //collect unread stories
    let storiesToMark = filterStoriesFromActivities(stories,activities.read);
    //create activity docs for each story
    let activityDocs = storiesToMark.map((story)=>{
        return Converters.createActivity(ACTIVITY_NAMES.READ,story.id,account.id);
    });
    //Update reducers
    yield put(actionCreators.markCurrentStoriesAsReadFinish(activityDocs));
    //Update DB
    yield fork(DB.insertBulkActivities,activityDocs);
    //Update server(for services other than feedly api should returns false as options)
    let storyIds= activityDocs.map((s)=>s.payload);
    //Make ajax request only if there are some stories to be updated
    if(storyIds.length >0){
        try {
            let ajaxOptions = CURRENT_SERVICE.markAsRead(account.token,storyIds)
            yield fork(platform.makeWebRequest,ajaxOptions)
        } catch (error) {
            yield put(actionCreators.updateStatus(null,STATUS_TYPES.DANGER,`${error.message}`,10));
        }
    }
    yield fetchUnreadCounts();
}

function* watchAndLog() {
    while (true) {
      const action = yield take('*')
      const state = yield select()
  
      console.log('action', action)
      console.log('state after', state)
    }
}

function* closeSearch(){
    yield call(fetchStories)    
}

//Collection of status tasks that are not yet cleared
var _pendingStatusTasks={};
//This methods waits for some time then dispacthes an action
function* delayedClearStatus(status){
    //wait
    yield delay(status.hideAfter * 1000)
    //disptach action
    yield put(actionCreators.clearStatus(status.id));
    //delete pending task reference
    delete _pendingStatusTasks[status.id];
}
function* handleUpdateStatus(action){
    // let status= action.payload;
    // //Clear any existing timer for this status, and delete from collection
    // let existingTimer = _pendingStatus[status.id];
    // clearTimeout(existingTimer);
    // delete _pendingStatus[status.id];
    // //If status has hideAfter, start a timeout
    // if(status.hideAfter){
    //     // yield delay(status.hideAfter * 1000)
    //     // yield put(actionCreators.clearStatus(status.id));
    //     _pendingStatus[status.id] = setTimeout(function* t(){
    //         //Time finished. clear status and remove timer from collection
    //         yield put(actionCreators.clearStatus(status.id));
    //         delete _pendingStatus[status.id];
    //     },status.hideAfter * 1000);
    // }

    let status= action.payload;
    let existingTask = _pendingStatusTasks[status.id];
    //If any task is pending for current id cancel it. Delete its refernce
    if(existingTask){
        yield cancel(existingTask);
        delete _pendingStatusTasks[status.id];
    }
    //If status has hideAfter, start a task and store it's reference
    if(status.hideAfter){
        _pendingStatusTasks[status.id] = yield fork(delayedClearStatus,status);
    }
}

function* handleRemoveAccount(action){
    try {
        const { db: DB} = globals
        yield call(DB.removeAccount,action.payload);
        yield call(cleanDatabase);
    } catch (error) {
        console.log(error);
    }
}

function* selectStory(action){
    yield fork(_updateAccountState);

    //trigger action mark as read if story is not read
    let story = action.payload;
    const {activities} = yield select((state)=>state);
    let isRead = find(activities.read, {payload: story.id});
    if(!isRead){
        yield put(actionCreators.createReadActivity(story.id));
    }
}

function* cleanDatabase(){
    try {
        const {db: DB} = globals
        //removeOrphanStories
        const {settings:{settings:settings}}  =  yield select((state)=>state);
        yield call(DB.cleanStories,settings.removeItemsOlderThan)
        yield call(DB.removeOrphanActivities)
    } catch (error) {
        console.log(error);
    }
}

//Handles prev or next actions
function* moveToNextOrPrevStory(action){
    const state = yield select((state)=>state)
    var stories= state.stories;
    var selectedStoryId = null;
    if(state.account && state.account.selectedStoryId){
        selectedStoryId = state.account.selectedStoryId;
    }
    let storyIndex = 0;
    if(stories && stories.length>0 && selectedStoryId){
        storyIndex = getStoryIndex(state)
    }
    let story = stories[storyIndex+action.payload];
    if(story){
        yield put(actionCreators.selectStory(story))
    }
}

//
function* rootSaga() {
    yield takeEvery(START_APP, startApp);
    yield takeEvery(CREATE_FIRST_ACCOUNT, creatFirstAccount);
    yield takeEvery(START_SYNC, startSync);
    yield takeEvery(FETCH_STORIES_START, fetchStories);


    // //Account_Related Actions
    yield takeEvery(_UPDATE_ACCOUNT_STATE,_updateAccountState);
    yield takeEvery(TOGGLE_FOLDER,_updateAccountState);
    yield takeEvery(SELECT_STORY,selectStory);  

    yield takeEvery(SELECT_FOLDER,_updateAccountStateLoadStories);
    yield takeEvery(SELECT_SUBSCRIPTION,_updateAccountStateLoadStories);
    yield takeEvery(CHANGE_STORIES_FILTER,_updateAccountStateLoadStories);
    yield takeEvery(CHANGE_SORT,_updateAccountStateLoadStories);
    yield takeEvery(NEXT_STORY,moveToNextOrPrevStory);
    yield takeEvery(PREV_STORY,moveToNextOrPrevStory);
    
    yield takeEvery(CREATE_ACCOUNT, createAccount);
    // //AppState Related
    yield takeEvery(CHANGE_SETTINGS,updateSettings);
    yield takeEvery(SWITCH_ACCOUNT,switchAccount);

    yield takeEvery(CREATE_ACTIVITY,createActivity);
    yield takeEvery(REMOVE_ACTIVITY,removeActivity);
    yield takeEvery(TOGGLE_STORY_VIEW,toggleStoryView);
    yield takeEvery(MARK_CURRENT_LIST_AS_READ,markCurrentListAsRead);
    

    // yield watchAndLog();    
    yield takeEvery(SEARCH_SUBSCRIPTIONS,searchSubscriptions);
    yield takeEvery(UPDATE_OR_INSERT_SUBSCRIPTION,updateOrInsertSubscription);    
    yield takeEvery(SEARCH_STORY,handleSearch); 
    yield takeEvery(CLOSE_SEARCH,closeSearch); 
    yield takeEvery(UPDATE_STATUS,handleUpdateStatus);
    yield takeEvery(REMOVE_ACCOUNT,handleRemoveAccount);
    yield takeEvery(PURCHASE_PRODUCT_START,purchaseProductStart);
}

export default rootSaga;